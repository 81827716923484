"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMatch = exports.IBasketballMatchStatDetailType = exports.ISoccerMatchStatDetailType = exports.isSubstitutedHighlight = exports.isCardHighlight = exports.isGoalHighlight = exports.CardType = exports.GoalType = exports.IMatchHighlightType = exports.MatchStatus = void 0;
var MatchStatus;
(function (MatchStatus) {
    MatchStatus["FIXTURE"] = "Fixture";
    MatchStatus["PLAYING"] = "Playing";
    MatchStatus["PLAYED"] = "Played";
    MatchStatus["CANCELLED"] = "Cancelled";
    MatchStatus["POSTPONED"] = "Postponed";
    MatchStatus["SUSPENDED"] = "Suspended";
    MatchStatus["AWARDED"] = "Awarded";
    MatchStatus["WALKOVER"] = "Walkover";
})(MatchStatus || (exports.MatchStatus = MatchStatus = {}));
var IMatchHighlightType;
(function (IMatchHighlightType) {
    IMatchHighlightType["GOAL"] = "goal";
    IMatchHighlightType["CARD"] = "card";
    IMatchHighlightType["SUBSTITUTE"] = "substitute";
})(IMatchHighlightType || (exports.IMatchHighlightType = IMatchHighlightType = {}));
var GoalType;
(function (GoalType) {
    GoalType["GOAL"] = "G";
    GoalType["PENALTY"] = "PG";
    GoalType["OWN_GOAL"] = "OG";
})(GoalType || (exports.GoalType = GoalType = {}));
var CardType;
(function (CardType) {
    CardType["YELLOW_CARD"] = "YC";
    CardType["RED_CARD"] = "RC";
    CardType["DOUBLE_YELLOW_CARD"] = "Y2C";
})(CardType || (exports.CardType = CardType = {}));
function isGoalHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.GOAL;
}
exports.isGoalHighlight = isGoalHighlight;
function isCardHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.CARD;
}
exports.isCardHighlight = isCardHighlight;
function isSubstitutedHighlight(highlight) {
    return highlight.highlightType === IMatchHighlightType.SUBSTITUTE;
}
exports.isSubstitutedHighlight = isSubstitutedHighlight;
var ISoccerMatchStatDetailType;
(function (ISoccerMatchStatDetailType) {
    ISoccerMatchStatDetailType["SHOTS"] = "SHOTS";
    ISoccerMatchStatDetailType["SHOTS_ON_TARGET"] = "SHOTS_ON_TARGET";
    ISoccerMatchStatDetailType["POSSESSION"] = "POSSESSION";
    ISoccerMatchStatDetailType["PASSES"] = "PASSES";
    ISoccerMatchStatDetailType["SHOT_ACCURACY"] = "SHOT_ACCURACY";
    ISoccerMatchStatDetailType["PASS_ACCURACY"] = "PASS_ACCURACY";
    ISoccerMatchStatDetailType["FOULS"] = "FOULS";
    ISoccerMatchStatDetailType["YELLOW_CARD"] = "YELLOW_CARD";
    ISoccerMatchStatDetailType["RED_CARD"] = "RED_CARD";
    ISoccerMatchStatDetailType["CORNERS"] = "CORNERS";
    ISoccerMatchStatDetailType["OFFSIDES"] = "OFFSIDES";
})(ISoccerMatchStatDetailType || (exports.ISoccerMatchStatDetailType = ISoccerMatchStatDetailType = {}));
var IBasketballMatchStatDetailType;
(function (IBasketballMatchStatDetailType) {
    IBasketballMatchStatDetailType["POINTS"] = "BB_POINTS";
    IBasketballMatchStatDetailType["FIELD_GOAL_PERCENTAGE"] = "BB_FIELD_GOAL_PERCENTAGE";
    IBasketballMatchStatDetailType["THREE_POINTS_PERCENTAGE"] = "BB_THREE_POINTS_PERCENTAGE";
    IBasketballMatchStatDetailType["FREE_THROW_PERCENTAGE"] = "BB_FREE_THROW_PERCENTAGE";
    IBasketballMatchStatDetailType["ASSISTS"] = "BB_ASSISTS";
    IBasketballMatchStatDetailType["STEALS"] = "BB_STEALS";
    IBasketballMatchStatDetailType["OFFENSIVE_REBOUNDS"] = "BB_OFFENSIVE_REBOUNDS";
    IBasketballMatchStatDetailType["DEFENSIVE_REBOUNDS"] = "BB_DEFENSIVE_REBOUNDS";
    IBasketballMatchStatDetailType["TURNOVERS"] = "BB_TURNOVERS";
    IBasketballMatchStatDetailType["FOULS"] = "BB_FOULS";
})(IBasketballMatchStatDetailType || (exports.IBasketballMatchStatDetailType = IBasketballMatchStatDetailType = {}));
function isMatch(match) {
    var _a;
    return ((_a = match.athletes) === null || _a === void 0 ? void 0 : _a.length) !== undefined;
}
exports.isMatch = isMatch;
