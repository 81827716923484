"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFullScoreComputation = exports.KeyEventType = exports.isBasketballPosition = exports.isSoccerPosition = exports.isAthletePosition = exports.MAP_SPORT_TO_POSITIONS = exports.ALL_POSITIONS = exports.BASKETBALL_POSITIONS = exports.SOCCER_POSITIONS = exports.BasketballPosition = exports.SoccerPosition = void 0;
var soccer_data_1 = require("../../soccer-data");
var technical_1 = require("../../technical");
var SoccerPosition;
(function (SoccerPosition) {
    SoccerPosition["GOAL"] = "GOAL";
    SoccerPosition["DEFENDER"] = "DEFENDER";
    SoccerPosition["MIDDLE"] = "MIDDLE";
    SoccerPosition["STRIKER"] = "STRIKER";
})(SoccerPosition || (exports.SoccerPosition = SoccerPosition = {}));
var BasketballPosition;
(function (BasketballPosition) {
    BasketballPosition["FORWARD"] = "FORWARD";
    BasketballPosition["GUARD"] = "GUARD";
    BasketballPosition["CENTER"] = "CENTER";
})(BasketballPosition || (exports.BasketballPosition = BasketballPosition = {}));
exports.SOCCER_POSITIONS = (0, technical_1.enumToArray)(SoccerPosition);
exports.BASKETBALL_POSITIONS = (0, technical_1.enumToArray)(BasketballPosition);
exports.ALL_POSITIONS = __spreadArray(__spreadArray([], exports.SOCCER_POSITIONS, true), exports.BASKETBALL_POSITIONS, true);
exports.MAP_SPORT_TO_POSITIONS = (_a = {},
    _a[soccer_data_1.Sport.SOCCER] = exports.SOCCER_POSITIONS,
    _a[soccer_data_1.Sport.BASKETBALL] = exports.BASKETBALL_POSITIONS,
    _a);
function isAthletePosition(position) {
    return !!position && exports.ALL_POSITIONS.includes(position);
}
exports.isAthletePosition = isAthletePosition;
function isSoccerPosition(position) {
    return !!position && exports.SOCCER_POSITIONS.includes(position);
}
exports.isSoccerPosition = isSoccerPosition;
function isBasketballPosition(position) {
    return !!position && exports.BASKETBALL_POSITIONS.includes(position);
}
exports.isBasketballPosition = isBasketballPosition;
var KeyEventType;
(function (KeyEventType) {
    KeyEventType["ASSIST"] = "ASSIST";
    KeyEventType["GOAL"] = "GOAL";
    KeyEventType["GOAL_SAVED"] = "GOAL_SAVED";
    KeyEventType["INJURY"] = "INJURY";
    KeyEventType["KEEPER_SWEEPER"] = "KEEPER_SWEEPER";
    KeyEventType["RED_CARD"] = "RED_CARD";
    KeyEventType["YELLOW_CARD"] = "YELLOW_CARD";
    KeyEventType["YELLOW_RED_CARD"] = "YELLOW_RED_CARD";
    KeyEventType["BB_DOUBLEDOUBLE"] = "BB_DOUBLEDOUBLE";
    KeyEventType["BB_TRIPLEDOUBLE"] = "BB_TRIPLEDOUBLE";
    KeyEventType["BB_ROBBINHOOD"] = "BB_ROBBINHOOD";
    KeyEventType["BB_COLOSSUS"] = "BB_COLOSSUS";
    KeyEventType["BB_MEDIC"] = "BB_MEDIC";
    KeyEventType["BB_DEFIANT"] = "BB_DEFIANT";
    KeyEventType["BB_BULLY"] = "BB_BULLY";
})(KeyEventType || (exports.KeyEventType = KeyEventType = {}));
function isFullScoreComputation(score) {
    return !!score.categoryScore;
}
exports.isFullScoreComputation = isFullScoreComputation;
