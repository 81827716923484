"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Labels = exports.Token = void 0;
var brand_1 = require("../../brand");
var Token;
(function (Token) {
    Token["UNA"] = "UNA";
    Token["ULC"] = "ULC";
})(Token || (exports.Token = Token = {}));
exports.Labels = (0, brand_1.switchBrand)({
    UC: (_a = {},
        _a[Token.ULC] = 'MGC',
        _a[Token.UNA] = 'UNA',
        _a),
    LFP: (_b = {},
        _b[Token.ULC] = 'L1C',
        _b[Token.UNA] = 'COA',
        _b),
});
