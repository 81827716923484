import * as React from 'react';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Blockchain, Payment } from 'ultimate-league-common';
import { useInterval } from 'usehooks-ts';

import { fetchAPIUnaChange } from '#common/blockchain';
import { useAuthorization } from '#technical/network/authorization';

const REFRESH_INTERVAL = 30000;

const tokenChangeContext = createContext<null | Payment.ITokensChange>(null);

export const useTokenChange = () => useContext(tokenChangeContext);

export const TokenChangeProvider = ({ children }: PropsWithChildren<{}>) => {
  const auth = useAuthorization();
  const [tokenChange, setTokenChange] = useState<Payment.ITokensChange | null>(
    null
  );
  const fetching = useRef<boolean>(false);

  const refreshChange = useCallback(async () => {
    if (fetching.current === true || !auth.isSigned()) {
      return;
    }
    try {
      fetching.current = true;
      setTokenChange({
        una: await fetchAPIUnaChange({ safeRange: true }),
        ulc: await Blockchain.Token.fetchUlcChange({ safeRange: true }),
      });
      // eslint-disable-next-line no-empty
    } catch {}
    fetching.current = false;
  }, [auth]);

  useInterval(refreshChange, REFRESH_INTERVAL);

  useEffect(() => {
    refreshChange();
  }, [refreshChange]);

  return (
    <tokenChangeContext.Provider value={tokenChange}>
      {children}
    </tokenChangeContext.Provider>
  );
};
