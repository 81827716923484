/* eslint-disable camelcase */
import { Blockchain, Pack, Payment, User } from 'ultimate-league-common';

import { CookiePolicy } from '#common/misc';
import { PaymentProvider } from '#common/payment';

type StandardFBQEvent =
  | 'Lead'
  | 'CompleteRegistration'
  | 'StartTrial'
  | 'Subscribe'
  | 'Purchase';

// e.g. '0.00'
type SFloat = string;

interface IParams {
  value: SFloat;
  currency: 'USD' | 'EUR';
  predicted_ltv?: SFloat;
}

interface IOptions {
  eventID: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    fbq?(
      track: 'track',
      event: StandardFBQEvent,
      params?: IParams,
      options?: IOptions
    ): void;
  }
}

const fbq: Window['fbq'] = (track, event, params, options) => {
  if (
    process.env.NODE_ENV === 'production' &&
    CookiePolicy.getCookieConsents().adPerformances
  ) {
    global.window?.fbq?.(track, event, params, options);
  }
};

export function completeRegistration(event: {
  userId: User.IMinimalUser['id'];
}) {
  return fbq?.('track', 'CompleteRegistration', undefined, {
    eventID: `REGISTER_USER_${event.userId}`,
  });
}

export function completeFTUE(event: { userId: User.IMinimalUser['id'] }) {
  return fbq?.(
    'track',
    'StartTrial',
    { value: '0.00', currency: Payment.ActiveCurrency, predicted_ltv: '0.00' },
    { eventID: `FTUE_${event.userId}` }
  );
}

export function registerTeam(event: { userId: User.IMinimalUser['id'] }) {
  return fbq?.('track', 'Lead', undefined, {
    eventID: `REGISTER_TEAM_${event.userId}`,
  });
}

export function claimReward(event: {
  userId: User.IMinimalUser['id'];
  packId: Pack.IPack['id'];
}) {
  return fbq?.(
    'track',
    'Subscribe',
    { value: '0.00', currency: Payment.ActiveCurrency, predicted_ltv: '0.00' },
    { eventID: `CLAIM_REWARD_${event.userId}_${event.packId}` }
  );
}

export function purchasePack(
  params: {
    price: number;
    quantity: number;
    provider: PaymentProvider;
    token?: Blockchain.Token.Token;
  },
  event: { userId: User.IMinimalUser['id']; packId: Pack.IPack['id'] }
) {
  const divide = (() => {
    if (params.provider === PaymentProvider.TOKEN) {
      return params.token === Blockchain.Token.Token.UNA ? 4 : 100;
    }

    return 1;
  })();

  return fbq?.(
    'track',
    'Purchase',
    {
      value: ((params.price / divide) * params.quantity).toFixed(2),
      currency: Payment.ActiveCurrency,
    },
    { eventID: `PURCHASE_PACK_${event.userId}_${event.packId}` }
  );
}

export function purchaseMarketplace(
  params: {
    price: number;
    provider: PaymentProvider;
    percentFees: number;
  },
  event: { userId: User.IMinimalUser['id']; nftId: Blockchain.NFTCard.NftId }
) {
  const fees = (params.price * params.percentFees) / 100;
  const spent = params.provider !== PaymentProvider.TOKEN ? params.price : 0;
  const value = spent + fees / 4;

  return fbq?.(
    'track',
    'Purchase',
    { value: value.toFixed(2), currency: Payment.ActiveCurrency },
    { eventID: `PURCHASE_NFT_${params.price}_${event.userId}_${event.nftId}` }
  );
}

export function purchaseChamp(
  params: {
    quantity: number;
    fiatPrice: number;
    saleId: string;
  },
  event: { userId: User.IMinimalUser['id'] }
) {
  return fbq?.(
    'track',
    'Purchase',
    { value: params.fiatPrice.toFixed(2), currency: Payment.ActiveCurrency },
    {
      eventID: `PURCHASE_CHAMP_${params.quantity}_${params.saleId}_${event.userId}`,
    }
  );
}
