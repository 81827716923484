/* eslint-disable import/no-mutable-exports */

/* eslint-disable global-require */
import { Blockchain } from 'ultimate-league-common';

import Gold from './Gold.png';

let images: {
  token1: string;
  token2: string;
};

if (process.env.BRAND === 'UC') {
  images = require('./UC');
}

if (process.env.BRAND === 'LFP') {
  images = require('./LFP');
}

export const { token1, token2 } = images!;

export const ASSETS = {
  Gold,
  [Blockchain.Token.Token.UNA]: token1,
  [Blockchain.Token.Token.ULC]: token2,
};
