"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecaptchaError = exports.AUTHORIZATION_RECAPTCHA_HEADER = exports.AUTHORIZATION_RECAPTCHA_ERROR_MESSAGE = exports.RecaptchaAction = void 0;
var RecaptchaAction;
(function (RecaptchaAction) {
    RecaptchaAction["SIGN_IN"] = "SIGN_IN";
    RecaptchaAction["REGISTER_DRAFT"] = "REGISTER_DRAFT";
    RecaptchaAction["REGISTER_TEAM"] = "REGISTER_TEAM";
    RecaptchaAction["BUY_PACK"] = "BUY_PACK";
    RecaptchaAction["BUY_CHAMP"] = "BUY_CHAMP";
    RecaptchaAction["CLAIM_PACK"] = "CLAIM_PACK";
    RecaptchaAction["CLAIM_PRIZE"] = "CLAIM_PRIZE";
    RecaptchaAction["CONTACT"] = "CONTACT";
})(RecaptchaAction || (exports.RecaptchaAction = RecaptchaAction = {}));
exports.AUTHORIZATION_RECAPTCHA_ERROR_MESSAGE = 'RECAPTCHA_ERROR';
exports.AUTHORIZATION_RECAPTCHA_HEADER = 'recaptcha';
var RecaptchaError = /** @class */ (function (_super) {
    __extends(RecaptchaError, _super);
    function RecaptchaError() {
        var _this = _super.call(this) || this;
        Object.setPrototypeOf(_this, RecaptchaError.prototype);
        return _this;
    }
    return RecaptchaError;
}(Error));
exports.RecaptchaError = RecaptchaError;
