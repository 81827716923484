"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNftIngredients = exports.computeAthleteAge = void 0;
var date_fns_1 = require("date-fns");
var computeAthleteAge = function (refDate, athleteBirth) {
    return (0, date_fns_1.differenceInYears)(refDate, athleteBirth);
};
exports.computeAthleteAge = computeAthleteAge;
function generateNftIngredients(_a) {
    var supply = _a.supply, rarityLevel = _a.rarityLevel, athleteId = _a.athleteId, athleteName = _a.athleteName, athleteFirstName = _a.athleteFirstName, athleteShortFirstName = _a.athleteShortFirstName, athleteLastName = _a.athleteLastName, athleteShortLastName = _a.athleteShortLastName, athleteBirth = _a.athleteBirth, athletePosition = _a.athletePosition, athleteAsset = _a.athleteAsset, seasonStartDate = _a.seasonStartDate, seasonTitle = _a.seasonTitle, clubAsset = _a.clubAsset, clubCode = _a.clubCode, clubName = _a.clubName, clubShortName = _a.clubShortName, nationalTeamName = _a.nationalTeamName, nationalTeamCode = _a.nationalTeamCode, countryCode = _a.countryCode, competitionName = _a.competitionName, competitionIdOriginal = _a.competitionIdOriginal, competitionAsset = _a.competitionAsset, cardTemplate = _a.cardTemplate, templateValues = _a.templateValues, assets = _a.assets, fonts = _a.fonts, specialEditionTitle = _a.specialEditionTitle, sport = _a.sport;
    var errors = [];
    function checkString(value, name) {
        if (typeof value !== 'string') {
            errors.push("".concat(name, " should be a string but got a ").concat(typeof value, ": ").concat(value));
        }
        else if (value.trim().length === 0) {
            errors.push("".concat(name, " should not be an empty string"));
        }
    }
    if (typeof supply !== 'number') {
        errors.push("supply should be a number but got a ".concat(typeof supply, ": ").concat(supply));
    }
    if (typeof rarityLevel !== 'number') {
        errors.push("rarityLevel should be a number but got a ".concat(typeof rarityLevel, ": ").concat(rarityLevel));
    }
    checkString(athleteId, 'athleteId');
    checkString(athleteName, 'athleteName');
    checkString(athleteFirstName, 'athleteFirstName');
    checkString(athleteShortFirstName, 'athleteShortFirstName');
    checkString(athleteLastName, 'athleteLastName');
    checkString(athleteShortLastName, 'athleteShortLastName');
    checkString(athleteBirth, 'athleteBirth');
    checkString(athletePosition, 'athletePosition');
    checkString(athleteAsset, 'athleteAsset');
    checkString(seasonStartDate, 'seasonStartDate');
    checkString(seasonTitle, 'seasonTitle');
    checkString(clubAsset, 'clubAssetUrl');
    checkString(clubCode, 'clubCode');
    checkString(clubName, 'clubName');
    checkString(nationalTeamName, 'nationalTeamName');
    checkString(nationalTeamCode, 'nationalTeamCode');
    checkString(countryCode, 'countryCode');
    checkString(competitionName, 'competitionName');
    checkString(competitionIdOriginal, 'competitionIdOriginal');
    checkString(competitionAsset, 'competitionAsset');
    checkString(cardTemplate, 'cardTemplate');
    if (errors.length) {
        throw new Error(errors.join(', '));
    }
    return {
        supply: supply,
        season: seasonTitle,
        rarity: rarityLevel,
        athleteId: athleteId,
        athlete: athleteName,
        athleteFirstName: athleteFirstName,
        athleteShortFirstName: athleteShortFirstName,
        athleteLastName: athleteLastName,
        athleteShortLastName: athleteShortLastName,
        athleteAge: (0, exports.computeAthleteAge)(new Date(seasonStartDate), new Date(athleteBirth)),
        athletePosition: athletePosition,
        athleteAsset: athleteAsset,
        clubAsset: clubAsset,
        clubCode: clubCode,
        clubName: clubName,
        clubShortName: clubShortName || clubName,
        nationalCode: nationalTeamCode,
        nationalName: nationalTeamName,
        countryCode: countryCode,
        competitionName: competitionName,
        competitionIdOriginal: competitionIdOriginal,
        competitionAsset: competitionAsset,
        template: cardTemplate,
        templateValues: templateValues,
        assets: assets,
        fonts: fonts,
        specialEdition: specialEditionTitle,
        sport: sport,
    };
}
exports.generateNftIngredients = generateNftIngredients;
