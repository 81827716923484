import { Score } from 'ultimate-league-common';

import { createStore } from './createStore';

export const [
  modifierMatricesContext,
  ModifierMatricesProvider,
  useModifierMatrices,
] = createStore<Score.IModifierMatrixData>(
  ({ id }) => id,
  (itemA, itemB) => itemA.id !== itemB.id
);
