import * as httpStatus from 'http-status';
import { Authorization, User, ValidationError } from 'ultimate-league-common';

import { getUTMParams } from '#common/referral';
import * as Logger from '#technical/logger';

import { IAuthorization } from './authorization';

export { stringify as buildQuery } from 'qs';

const { SERVER } = process.env;
const { API } = process.env;

if (!SERVER || !API) {
  throw new Error('Missing env SERVER and API');
}

export { SERVER };
export const API_URL = SERVER + API;
export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';
export const ABORT_ERROR_NAME = 'AbortError';

export class AuthorizationError extends Error {
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, AuthorizationError.prototype);
  }
}

export class NotFoundError extends Error {
  constructor() {
    super();

    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class TooManyRequestsError extends Error {
  constructor() {
    super();

    Object.setPrototypeOf(this, TooManyRequestsError.prototype);
  }
}

// eslint-disable-next-line no-undef
type IRequestInit = RequestInit;

export function prepareSubmitRequestInit(
  data: unknown,
  methodOrRequestInit: IRequestInit['method'] | IRequestInit = 'POST'
): IRequestInit {
  const defaultRequestInit = {
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (typeof methodOrRequestInit === 'string') {
    return {
      ...defaultRequestInit,
      method: methodOrRequestInit,
    };
  }

  return {
    method: 'POST',
    ...defaultRequestInit,
    ...methodOrRequestInit,
    headers: {
      ...defaultRequestInit.headers,
      ...methodOrRequestInit?.headers,
    },
  };
}

let refreshing: Promise<string> | undefined;
export async function refreshToken(
  authorization: IAuthorization
): Promise<string> {
  if (refreshing) {
    return refreshing;
  }

  refreshing = (async () => {
    try {
      const refresh = await fetch(
        `${API_URL}/auth/refresh`,
        prepareSubmitRequestInit({
          id: authorization.credentials!.id,
          refreshToken: authorization.credentials!.refreshToken,
          ...getUTMParams(),
        })
      );

      if (refresh.status === httpStatus.UNAUTHORIZED) {
        await authorization.setCredentials(undefined);
        return undefined;
      }

      const { jwt } = await refresh.json();
      await authorization.setJWT(jwt);
      return jwt;
    } finally {
      refreshing = undefined;
    }
  })();

  return refreshing;
}

/**
 * Generic function to fetch API.
 *
 * This function will fetch API in 3 phases:
 * 1. Fetch the endpoint
 * 2. Handle recoverable status code
 * 3. Handle status code and throw matching error
 */
export async function fetchApi(
  endpoint: string,
  init: IRequestInit = { method: 'GET' },
  authorization?: IAuthorization
) {
  //----------------------
  // 1. FETCH THE ENDPOINT
  //----------------------
  let { headers } = init;
  const credentials = authorization && authorization.credentials;
  if (credentials) {
    headers = {
      ...headers,
      Authorization: `bearer ${credentials.jwt}`,
    };
  }
  const endpointURL = API_URL + endpoint;
  let response = await fetch(endpointURL, { ...init, headers });

  //----------------------------------
  // 2. HANDLE RECOVERABLE STATUS CODE
  //----------------------------------

  // UNAUTHORIZED and credential error. We can refresh JWT and retry request
  if (response.status === httpStatus.UNAUTHORIZED && authorization) {
    const body = await response.clone().json();

    if (body.error === httpStatus[httpStatus.UNAUTHORIZED] && credentials) {
      headers = {
        ...headers,
        Authorization: `bearer ${await refreshToken(authorization)}`,
      };

      response = await fetch(endpointURL, { ...init, headers });
    }
  }

  // UNAUTHORIZED and 2FA error. We can set 2FA JWT and retry request
  if (response.status === httpStatus.UNAUTHORIZED && authorization) {
    const body = await response.clone().json();

    if (body.error === Authorization.AUTHORIZATION_TWO_FA_ERROR_MESSAGE) {
      const twoFaJWT = authorization.getTwoFa(body.access);

      if (twoFaJWT) {
        headers = {
          ...headers,
          [Authorization.AUTHORIZATION_TWO_FA_FIELD]: twoFaJWT,
        };
        response = await fetch(endpointURL, { ...init, headers });
      }
    }
  }

  //-----------------------------------------------
  // 3. HANDLE STATUS CODE AND THROW MATCHING ERROR
  //-----------------------------------------------
  if (response.status === httpStatus.UNPROCESSABLE_ENTITY) {
    throw new ValidationError(await response.json());
  }

  if (response.status === httpStatus.UNAUTHORIZED) {
    const body = await response.clone().json();
    if (body.error === Authorization.AUTHORIZATION_TWO_FA_ERROR_MESSAGE) {
      throw new Authorization.TwoFaError(body.access);
    } else if (
      body.error === Authorization.AUTHORIZATION_RECAPTCHA_ERROR_MESSAGE
    ) {
      throw new Authorization.RecaptchaError();
    } else {
      throw new AuthorizationError(body.error);
    }
  }

  if (response.status === httpStatus.UNAVAILABLE_FOR_LEGAL_REASONS) {
    throw new User.KYC.IdentityCheckError();
  }

  if (response.status === httpStatus.NOT_FOUND) {
    throw new NotFoundError();
  }

  if (response.status === httpStatus.TOO_MANY_REQUESTS) {
    throw new TooManyRequestsError();
  }

  if (response.status >= 300) {
    const jsonResponse = await response.json();
    throw new Error(jsonResponse.error || DEFAULT_ERROR_MESSAGE);
  }

  if (response.headers.get('Deprecated')) {
    const deprecatedURL = new URL(response.url);
    Logger.error(
      new Error(
        `${deprecatedURL.origin}${deprecatedURL.pathname} is deprecated.`
      )
    );
  }

  return response;
}

/**
 * Submit data to server and handle response status.
 */
export async function submitData(
  endpoint: string,
  data: unknown,
  authorization?: IAuthorization,
  methodOrRequestInit?: IRequestInit['method'] | IRequestInit
) {
  const init = prepareSubmitRequestInit(data, methodOrRequestInit);
  const response = await fetchApi(endpoint, init, authorization);
  if (response.status !== httpStatus.NO_CONTENT) {
    return response.json();
  }
  return null;
}
