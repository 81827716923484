import * as React from 'react';
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { exists } from 'ultimate-league-common';

type ID = string | number;

interface IGetID<I> {
  (item: I): ID;
}

interface IShouldUpdateState<I> {
  (itemA: I, itemB: I): boolean;
}

export type Pool<I> = Record<ID, I | undefined>;

export interface IStore<I> {
  pool: Pool<I>;
  items: I[];
  remove: (id: ID) => void;
  upsert: (item: I) => void;
  upserts: (items: I[]) => void;
}

function willThrow() {
  throw new Error('Store is not initialized');
}

function alwaysUpdateState() {
  return true;
}

export function useStore<I>(
  getId: IGetID<I>,
  shouldUpdateState: IShouldUpdateState<I> = alwaysUpdateState
) {
  const [pool, setPool] = useState<Pool<I>>({});
  const items = useMemo<IStore<I>['items']>(
    () =>
      Object.keys(pool)
        .map((id) => pool[id])
        .filter(exists),
    [pool]
  );

  const remove = useCallback<IStore<I>['remove']>(
    (id) => {
      setPool((oldItems) => ({
        ...oldItems,
        [id]: undefined,
      }));
    },
    [setPool]
  );

  const upsert = useCallback<IStore<I>['upsert']>(
    (item) => {
      setPool((oldItems) => {
        const oldItem = oldItems[getId(item)];
        if (oldItem && !shouldUpdateState(oldItem, item)) {
          return oldItems;
        }

        return {
          ...oldItems,
          [getId(item)]: {
            ...oldItems[getId(item)],
            ...item,
          },
        };
      });
    },
    [setPool, getId, shouldUpdateState]
  );

  const upserts = useCallback<IStore<I>['upserts']>(
    (newItems) => {
      setPool((oldItems) =>
        newItems.reduce((acc, item) => {
          const oldItem = oldItems[getId(item)];
          if (oldItem && !shouldUpdateState(oldItem, item)) {
            return acc;
          }

          return {
            ...acc,
            [getId(item)]: {
              ...acc[getId(item)],
              ...item,
            },
          };
        }, oldItems)
      );
    },
    [setPool, getId, shouldUpdateState]
  );

  return {
    pool,
    items,
    remove,
    upsert,
    upserts,
  };
}

export function createStore<I>(
  getId: IGetID<I>,
  shouldUpdateState?: IShouldUpdateState<I>
): [Context<IStore<I>>, FC, () => IStore<I>] {
  const context = createContext<IStore<I>>({
    pool: {},
    items: [],
    remove: willThrow,
    upsert: willThrow,
    upserts: willThrow,
  });

  const Provider: FC = ({ children }: PropsWithChildren<{}>) => {
    const store = useStore<I>(getId, shouldUpdateState);

    return <context.Provider value={store}>{children}</context.Provider>;
  };

  const useStoreContext = () => useContext(context);

  return [context, Provider, useStoreContext];
}
