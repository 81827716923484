"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandConfig = void 0;
var brand_1 = require("../brand");
exports.BrandConfig = (0, brand_1.switchBrand)({
    UC: {
        enableSocialSSO: true,
    },
    LFP: {
        enableSocialSSO: false,
    },
});
