import { css } from 'styled-components';

import { IColorPalettes, TColorValue } from '../../theme';

export interface ITypographyProps {
  /**
   * You will lose the type definition of `palettes` if you use the `as` property.
   * This can be fixed this way: $textColor={({ ... }: IColorPalettes) => ...}
   */
  $textColor?: (palettes: IColorPalettes) => TColorValue | undefined;
  textAlign?: 'left' | 'center' | 'right';
  ellipsis?: boolean;
  noWrap?: boolean;
}

export const typographyStyles = ({
  $textColor,
  textAlign,
  ellipsis,
  noWrap,
}: ITypographyProps) => css`
  color: ${({ theme }) => $textColor && $textColor(theme.color)};
  text-align: ${textAlign};
  ${ellipsis &&
  css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `}
  ${noWrap &&
  css`
    white-space: nowrap;
  `}
`;
