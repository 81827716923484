import MuiTabs, { tabsClasses } from '@mui/material/Tabs';
import React from 'react';
import styled, { css } from 'styled-components';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { IconButton } from '../../inputs';
import { Tab } from './Tab';

const StyledTabs = styled(MuiTabs)<{
  size: Required<ITabsProps<unknown>>['size'];
}>`
  ${({ theme, size }) => css`
    & .${tabsClasses.indicator} {
      background-color: ${theme.color.primary['0']};
    }

    & .${tabsClasses.flexContainer} {
      padding-inline: ${theme.spacing(8)};
      padding-bottom: 6px;

      ${() => {
        switch (size) {
          case 'L':
            return css`
              gap: ${theme.spacing(16)};
            `;
          case 'M':
            return css`
              gap: ${theme.spacing(8)};
            `;
          case 'S':
            return css`
              gap: 0;
            `;
          default:
            throw catchMissingSwitchCase(size);
        }
      }}
  `}
`;

export interface ITabOption<GValue> {
  adornment?: JSX.Element;
  disabled?: boolean;
  label: React.ReactNode;
  value: GValue;
}

export interface ITabsProps<GValue> {
  options: Array<ITabOption<GValue>>;
  size?: 'S' | 'M' | 'L';
  value: GValue;
  variant?: 'standard' | 'fullWidth' | 'scrollable';
  className?: string;
  onChange(value: GValue): void;
}

export function Tabs<GValue>({
  options,
  size = 'M',
  value,
  variant = 'standard',
  className,
  onChange,
}: ITabsProps<GValue>) {
  const handleTabChange = (_: React.SyntheticEvent, newValue: GValue) => {
    onChange(newValue);
  };

  return (
    <StyledTabs
      onChange={handleTabChange}
      size={size}
      value={value}
      variant={variant}
      className={className}
      ScrollButtonComponent={(scrollProps) => (
        <IconButton
          size={size}
          disabled={scrollProps.disabled}
          onClick={scrollProps.onClick}
          icon={
            scrollProps.direction === 'left'
              ? 'chevron_left__filled'
              : 'chevron_right__filled'
          }
          variant="text"
        />
      )}
    >
      {options.map((option, index) => (
        <Tab
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          adornment={option.adornment}
          disabled={option.disabled}
          label={option.label}
          size={size}
          value={option.value}
        />
      ))}
    </StyledTabs>
  );
}
