import { GameWeek, SoccerData, User } from 'ultimate-league-common';

import { buildQuery, fetchApi } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';

import { TOrdinalOrAbstractGameWeekPosition } from './types';

export async function fetchGameWeekMatches(
  { gameWeek }: GameWeek.IGetMatches['params'],
  { sport }: GameWeek.IGetMatches['query'],
  auth: IAuthorization
): Promise<GameWeek.IGetMatches['response']> {
  const response = await fetchApi(
    `/gameWeek/${gameWeek}/matches?sport=${sport}`,
    undefined,
    auth
  );
  return response.json();
}

export async function fetchGameWeekOptions(
  { sport }: GameWeek.IGetGameWeekOptions['query'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameWeek.IGetGameWeekOptions['response']> {
  const response = await fetchApi(
    `/gameweek/select-options?sport=${sport}`,
    { signal },
    auth
  );
  return response.json();
}

export async function fetchLiveGameWeek(
  sport: SoccerData.Sport,
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameWeek.IGameWeek> {
  const response = await fetchApi(
    `/gameweek/live?${buildQuery({ sport })}`,
    { signal },
    auth
  );
  return response.json();
}
export async function fetchNextGameWeek(
  sport: SoccerData.Sport,
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameWeek.IGameWeek> {
  const response = await fetchApi(
    `/gameweek/next?${buildQuery({ sport })}`,
    { signal },
    auth
  );
  return response.json();
}

export async function fetchGameWeek(
  position: TOrdinalOrAbstractGameWeekPosition,
  sport: SoccerData.Sport,
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameWeek.IGameWeek> {
  const response = await fetchApi(
    `/gameweek/${position}?sport=${sport}`,
    { signal },
    auth
  );
  return response.json();
}

export async function fetchGameWeekRanking(
  { division, gameWeek, user }: User.IGetGameWeekRanking['params'],
  { sport }: User.IGetGameWeekRanking['query'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<User.IGetGameWeekRanking['response']> {
  const res = await fetchApi(
    `/user/${user}/ranking/gameweek/${gameWeek}/division/${division}?${buildQuery(
      {
        sport,
      }
    )}`,
    { signal },
    auth
  );

  return res.json();
}

export async function fetchGameWeekParticipants(
  { gameWeek }: GameWeek.IGetParticipants['params'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameWeek.IGetParticipants['response']> {
  const response = await fetchApi(
    `/gameweek/${gameWeek}/participants`,
    {
      signal,
    },
    auth
  );

  return response.json();
}
