import { SoccerData } from 'ultimate-league-common';

import { createStore } from './createStore';

export const [
  minimalAthletesContext,
  MinimalAthletesProvider,
  useMinimalAthletes,
] = createStore<SoccerData.Athlete.IMinimalAthlete>(
  ({ id }) => id,
  (itemA, itemB) => itemA.id !== itemB.id
);
