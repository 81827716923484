import * as React from 'react';
import { isValidElement, ReactNode } from 'react';
import { Link as NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { IColorPalettes, TColorValue } from '../../../theme';
import { IParagraphProps, Paragraph } from '../../typography';

const TextLink = styled(Paragraph)<{
  activeClassName?: string;
}>`
  transition: all 0ms;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    opacity: 0.8;
  }

  &.${({ activeClassName = 'active' }) => activeClassName} {
    border-radius: 4px;
    outline-offset: 2px;
    outline: 1px solid ${({ theme }) => theme.color.primary['0']};
  }
`;

interface ILinkProps {
  href: string;
  target?: NavLinkProps['target'];
  children: ReactNode;
  className?: string;
  textSize?: IParagraphProps['variant'];
  textColor?: (palettes: IColorPalettes) => TColorValue | undefined;
}

export const Link = ({
  href,
  target,
  textSize = 'M',
  className,
  textColor,
  children,
}: ILinkProps) => {
  const isExternalLink = href.startsWith('http');

  return (
    <NavLink
      to={isExternalLink ? { pathname: href } : href}
      target={target || (isExternalLink ? '_blank' : undefined)}
      onClick={(event) => event.stopPropagation()}
      className={isValidElement(children) ? className : undefined}
    >
      {isValidElement(children) ? (
        children
      ) : (
        <TextLink
          as="span"
          variant={textSize}
          className={className}
          $textColor={textColor}
          activeClassName="active"
          ellipsis
        >
          {children}
        </TextLink>
      )}
    </NavLink>
  );
};

export const ButtonLink = styled(TextLink).attrs({
  as: 'button',
  type: 'button',
})`
  background-color: transparent;
  appearance: none;
  border: none;
  cursor: pointer;
  color: inherit;
  padding: 0;
`;
