/* eslint-disable prefer-destructuring */
import { Blockchain, Brand, SoccerData } from 'ultimate-league-common';

export const PLATFORM_NAME = Brand.switchBrand({
  UC: 'Ultimate Champions',
  LFP: 'Coach Ligue 1',
});

export const COPYRIGHT = Brand.switchBrand({
  UC: `© 2022 ${PLATFORM_NAME}`,
  LFP: `© 2024 ${PLATFORM_NAME}`,
});

export const SCAN_URL: Record<Blockchain.Blockchain, string | undefined> = {
  polygon: process.env.POLYGON_SCAN_URL,
  bsc: process.env.BSC_SCAN_URL,
  chiliz: process.env.CHILIZ_SCAN_URL,
  base: process.env.BASE_SCAN_URL,
};

export const { GOOGLE_PUBLIC_BUCKET_NAME } = process.env;
if (!GOOGLE_PUBLIC_BUCKET_NAME) {
  throw new Error('Missing env GOOGLE_PUBLIC_BUCKET_NAME');
}

export const GOOGLE_PUBLIC_BUCKET_URL = `https://storage.googleapis.com/${GOOGLE_PUBLIC_BUCKET_NAME}`;

export const VIDEO_URL =
  'https://storage.googleapis.com/temp.public.ultimate-champions.com/promo.mp4';

export const DISCORD_LINK = Brand.switchBrand({
  UC: 'https://discord.com/invite/gby67t',
  LFP: 'https://discord.com/invite/cTsgpztwVQ',
});

export const TWITCH_LINK = Brand.switchBrand({
  UC: '',
  LFP: 'https://www.twitch.tv/ligue1ubereats',
});

export const TIKTOK_LINK = Brand.switchBrand({
  UC: '',
  LFP: 'https://www.tiktok.com/@ligue1ubereats',
});

export const WHATSAPP_LINK = Brand.switchBrand({
  UC: '',
  LFP: 'https://www.whatsapp.com/channel/0029Va5v70CAInPjaUvzbW2A',
});

export const WHITEPAPER_LINK = Brand.switchBrand({
  UC: 'https://whitepaper.ultimate-champions.com',
  LFP: 'https://lfpmedia.gitbook.io/coach-ligue-1',
});

// TODO LFP link will be provided by Etienne later (or maybe removed at all)
export const ZENDESK_LINK = Brand.switchBrand({
  UC: 'https://unagihelp.zendesk.com/hc/en-001',
  LFP: '',
});

export const FOOTBALL_TWITTER_LINK = Brand.switchBrand({
  UC: 'https://twitter.com/UltiChamps',
  LFP: 'https://x.com/fantasyligue1',
});
export const FOOTBALL_FACEBOOK_LINK = Brand.switchBrand({
  UC: 'https://www.facebook.com/TheUltiChamps',
  LFP: 'https://www.facebook.com/FantasyLigue1/',
});
export const FOOTBALL_INSTAGRAM_LINK = Brand.switchBrand({
  UC: 'https://www.instagram.com/ultichamps/',
  LFP: 'https://www.instagram.com/fantasy_ligue1/',
});

export const BASKETBALL_TWITTER_LINK = 'https://twitter.com/UltiChampsBball';
export const BASKETBALL_INSTAGRAM_LINK =
  'https://www.instagram.com/ultichampsbball/';

const GLOBAL_LEAGUE_IDS = Brand.switchBrand({
  UC: {
    [SoccerData.Sport.SOCCER]: '63efc9fd88713519bfc02984',
    [SoccerData.Sport.BASKETBALL]: '63efc9fd88713519bfc02985',
  },
  LFP: {
    [SoccerData.Sport.SOCCER]: '65d7a534898a94f1ccbb7bbb',
    [SoccerData.Sport.BASKETBALL]: '',
  },
});

export function getGlobalLeagueId(sport: SoccerData.Sport) {
  return GLOBAL_LEAGUE_IDS[sport];
}
