import React from 'react';
import styled from 'styled-components';
import { Blockchain, Pack } from 'ultimate-league-common';

import {
  CurrencyIcon,
  IParagraphProps,
  Paragraph,
  RarityIcon,
} from '#ui/components';

/* Styled Components */

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(8)};
  align-items: center;
`;

/* Main Component */

export interface IPackContentPreviewProps extends IParagraphProps {
  loot: Pack.LootTable.ILoot;
}

export function PackContentPreview({
  loot,
  ...paragraphProps
}: IPackContentPreviewProps) {
  const drop = loot.drops[0];

  if (drop === undefined) return null;
  return (
    <Container>
      {drop.dropType === Pack.LootTable.DropType.UNA_TOKEN && (
        <CurrencyIcon coin={Blockchain.Token.Token.UNA} size="L" />
      )}
      {drop.dropType === Pack.LootTable.DropType.ULC_TOKEN && (
        <CurrencyIcon coin={Blockchain.Token.Token.ULC} size="L" />
      )}
      {drop.dropType === Pack.LootTable.DropType.NFT_CARD && (
        <RarityIcon rarity={drop.filter.level} size="L" />
      )}
      <Paragraph {...paragraphProps}>{drop.quantity}</Paragraph>
    </Container>
  );
}
