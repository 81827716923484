import React, { lazy, ReactNode } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { FTUE as FTUECommon } from 'ultimate-league-common';

import { buildRoutePath, getRoutePath } from '#common/routing';
import { useCurrentSport } from '#common/sport';
import { useUserAccount } from '#common/store';
import { derivate } from '#technical/hooks';

const FTUE = lazy(() =>
  import('./FTUERoute').then((module) => ({ default: module.FTUERoute }))
);

export function FTUEGuard({
  enforceDraft,
  children,
}: {
  enforceDraft?: boolean;
  children: ReactNode;
}) {
  const { search } = useLocation();
  const { account } = useUserAccount();
  const { sport } = useCurrentSport();

  const ftueData = account?.data[sport].ftue;

  const needsToBeRedirected = derivate(() => {
    if (ftueData?.state === FTUECommon.State.WELCOME) return true;

    if (!enforceDraft) return false;

    if (ftueData?.state === FTUECommon.State.SELECT_CLUB) return true;
    if (ftueData?.state === FTUECommon.State.DRAFT) return true;

    return false;
  });

  if (ftueData?.state === undefined) return null;

  if (
    ![
      FTUECommon.State.WELCOME,
      FTUECommon.State.SELECT_CLUB,
      FTUECommon.State.DRAFT,
    ].includes(ftueData.state)
  ) {
    return <>{children}</>;
  }

  return (
    <Switch>
      <Route exact path={getRoutePath('FTUE')} component={FTUE} />
      {needsToBeRedirected && (
        <Redirect
          key="default"
          to={{
            pathname: buildRoutePath('FTUE', { sport }),
            search,
          }}
        />
      )}
      {children}
    </Switch>
  );
}
