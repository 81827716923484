"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directTokenAuctionBid = exports.createIntentChampBuy = exports.directTokenPackBuy = exports.createIntentPackBuy = exports.directTokenMarketBuy = exports.createIntentMarketBuy = exports.MINIMUM_FIAT_FOR_STRIPE_PAYMENTS = void 0;
var NFT_card_1 = require("../blockchain/NFT-card");
var token_1 = require("../blockchain/token");
var technical_1 = require("../technical");
var type_1 = require("./type");
exports.MINIMUM_FIAT_FOR_STRIPE_PAYMENTS = 1;
exports.createIntentMarketBuy = {
    nft: NFT_card_1.NFT,
    allowedWeiAmount: {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    },
    method: {
        inclusion: (0, technical_1.enumToArray)(type_1.Method),
        presence: {
            allowEmpty: false,
        },
    },
    payment: {
        type: 'string',
    },
};
exports.directTokenMarketBuy = {
    nft: NFT_card_1.NFT,
    allowedWeiAmount: {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    },
};
var quantityValidation = {
    presence: {
        allowEmpty: false,
    },
    numericality: {
        onlyInteger: true,
        greaterThan: 0,
    },
};
exports.createIntentPackBuy = {
    packConfigId: technical_1.id,
    quantity: quantityValidation,
    method: {
        inclusion: (0, technical_1.enumToArray)(type_1.Method),
        presence: {
            allowEmpty: false,
        },
    },
    payment: {
        type: 'string',
    },
};
exports.directTokenPackBuy = {
    packConfigId: technical_1.id,
    quantity: quantityValidation,
    token: {
        inclusion: (0, technical_1.enumToArray)(token_1.Token),
        presence: {
            allowEmpty: false,
        },
    },
    allowedWeiAmount: {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    },
};
exports.createIntentChampBuy = {
    quantity: quantityValidation,
    payment: {
        type: 'string',
    },
};
exports.directTokenAuctionBid = {
    auctionId: technical_1.id,
    weiAmount: {
        type: 'string',
        presence: { allowEmpty: false },
    },
};
