import { useFlag as useFlagUnleash } from '@unleash/proxy-client-react';
import { FeatureFlags, Brand, User } from 'ultimate-league-common';

export function getFeatureFlagContext(user?: User.IUser) {
  return {
    userId: user?.id,
    userRole: user?.role,
  };
}

export function useFlag(
  feature: Exclude<FeatureFlags.Features, FeatureFlags.Features.AUTO_REGISTER>
) {
  return useFlagUnleash(`${Brand.BRAND}_${feature}`);
}
