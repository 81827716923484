import {
  FlagProvider as UnleashFlagProvider,
  useUnleashContext,
} from '@unleash/proxy-client-react';
import React, { FC, VFC } from 'react';
import { FeatureFlags, User } from 'ultimate-league-common';
import { useEffectOnce } from 'usehooks-ts';

import { useUserAccount } from '#common/store';
import { getFeatureFlagContext } from '#technical/feature-flags/index';

const unleashApiUrl = process.env.UNLEASH_API_URL;
const unleashToken = process.env.UNLEASH_TOKEN;

if (!unleashApiUrl || !unleashToken) {
  throw new Error('Missing env UNLEASH_*');
}

const InitUnleashContext: VFC<{ account: User.IUserAccount }> = ({
  account,
}) => {
  const setUnleashContext = useUnleashContext();

  useEffectOnce(() => {
    setUnleashContext(getFeatureFlagContext(account));
  });

  return null;
};

export const FlagProvider: FC = ({ children }) => {
  const { account } = useUserAccount();

  return (
    <UnleashFlagProvider
      config={{
        url: `${unleashApiUrl}/frontend`,
        appName: FeatureFlags.getAppName(),
        environment: process.env.NODE_ENV,
        clientKey: unleashToken,
        context: getFeatureFlagContext(account),
      }}
    >
      {account && <InitUnleashContext account={account} />}
      {children}
    </UnleashFlagProvider>
  );
};
