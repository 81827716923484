import { ComponentClass, SVGProps } from 'react';

import { ICustomIconProps } from '../CustomIcon';
import BasketballJersey from './BasketballJersey.svg';
import Bid from './Bid.svg';
import CHZ from './CHZ.svg';
import Cards from './Cards.svg';
import CardsMono from './CardsMono.svg';
import FootballJersey from './FootballJersey.svg';
import Gift from './Gift.svg';
import Infinity from './Infinity.svg';
import Persona from './Persona.svg';
import Season from './Season.svg';
import Trophy from './Trophy.svg';

export const ASSETS: Record<
  ICustomIconProps['name'],
  ComponentClass<SVGProps<SVGSVGElement>>
> = {
  BasketballJersey,
  Bid,
  Cards,
  CardsMono,
  CHZ,
  FootballJersey,
  Gift,
  Infinity,
  Persona,
  Season,
  Trophy,
};
