import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef } from 'react';

export const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);
