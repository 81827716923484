import { lazy } from 'react';

export * from './components';
export * from './hooks';
export * from './service';
export * from './types';
export * from './utils';

export const PackOpeningPopup = lazy(() =>
  import('./PackOpening/PackOpeningPopup').then((module) => ({
    default: module.PackOpeningPopup,
  }))
);
export { usePackOpeningPopup } from './PackOpening';
