"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isIClubsGameStats = exports.CustomStatKey = exports.EuroleagueStatKey = void 0;
var EuroleagueStatKey;
(function (EuroleagueStatKey) {
    EuroleagueStatKey["ACCURACYATTEMPTED"] = "accuracyAttempted";
    EuroleagueStatKey["ACCURACYMADE"] = "accuracyMade";
    EuroleagueStatKey["ASSISTANCES"] = "assistances";
    EuroleagueStatKey["BLOCKSAGAINST"] = "blocksAgainst";
    EuroleagueStatKey["BLOCKSFAVOUR"] = "blocksFavour";
    EuroleagueStatKey["DEFENSIVEREBOUNDS"] = "defensiveRebounds";
    EuroleagueStatKey["DORSAL"] = "dorsal";
    EuroleagueStatKey["FIELDGOALSATTEMPTED2"] = "fieldGoalsAttempted2";
    EuroleagueStatKey["FIELDGOALSATTEMPTED3"] = "fieldGoalsAttempted3";
    EuroleagueStatKey["FIELDGOALSATTEMPTEDTOTAL"] = "fieldGoalsAttemptedTotal";
    EuroleagueStatKey["FIELDGOALSMADE2"] = "fieldGoalsMade2";
    EuroleagueStatKey["FIELDGOALSMADE3"] = "fieldGoalsMade3";
    EuroleagueStatKey["FIELDGOALSMADETOTAL"] = "fieldGoalsMadeTotal";
    EuroleagueStatKey["FOULSCOMMITED"] = "foulsCommited";
    EuroleagueStatKey["FOULSRECEIVED"] = "foulsReceived";
    EuroleagueStatKey["FREETHROWSATTEMPTED"] = "freeThrowsAttempted";
    EuroleagueStatKey["FREETHROWSMADE"] = "freeThrowsMade";
    EuroleagueStatKey["OFFENSIVEREBOUNDS"] = "offensiveRebounds";
    EuroleagueStatKey["PLUSMINUS"] = "plusMinus";
    EuroleagueStatKey["POINTS"] = "points";
    EuroleagueStatKey["STARTFIVE"] = "startFive";
    EuroleagueStatKey["STARTFIVE2"] = "startFive2";
    EuroleagueStatKey["STEALS"] = "steals";
    EuroleagueStatKey["TIMEPLAYED"] = "timePlayed";
    EuroleagueStatKey["TOTALREBOUNDS"] = "totalRebounds";
    EuroleagueStatKey["TURNOVERS"] = "turnovers";
    EuroleagueStatKey["VALUATION"] = "valuation";
})(EuroleagueStatKey || (exports.EuroleagueStatKey = EuroleagueStatKey = {}));
var CustomStatKey;
(function (CustomStatKey) {
    CustomStatKey["UL_STARTING"] = "ul_starting";
    CustomStatKey["UL_FREETHROWMISSED"] = "ul_freeThrowMissed";
    CustomStatKey["UL_2PTSHOTMISSED"] = "ul_2ptShotMissed";
    CustomStatKey["UL_3PTSHOTMISSED"] = "ul_3ptShotMissed";
    CustomStatKey["UL_POINTSDIFFOC"] = "ul_pointsDiffOC";
    CustomStatKey["UL_TEAMWIN"] = "ul_teamWin";
    CustomStatKey["UL_POINTSTAKENOC"] = "ul_pointsTakenOC";
    CustomStatKey["UL_POINTSSCOREDOC"] = "ul_pointsScoredOC";
    CustomStatKey["UL_DOUBLEDOUBLE"] = "ul_doubleDouble";
    CustomStatKey["UL_TRIPLEDOUBLE"] = "ul_tripleDouble";
    CustomStatKey["UL_ROBBINHOOD"] = "ul_robbinHood";
    CustomStatKey["UL_COLOSSUS"] = "ul_colossus";
    CustomStatKey["UL_MEDIC"] = "ul_medic";
    CustomStatKey["UL_DEFIANT"] = "ul_defiant";
    CustomStatKey["UL_BULLY"] = "ul_bully";
    // Player
    CustomStatKey["PLAYER_PERSON_NAME"] = "player_person_name";
    CustomStatKey["PLAYER_CODE"] = "player_person_code";
    CustomStatKey["PLAYER_POSITIONNAME"] = "player_positionName";
    // Club
    CustomStatKey["PLAYER_CLUB_NAME"] = "player_club_name";
    CustomStatKey["PLAYER_CLUB_CODE"] = "player_club_code";
    // Game
    CustomStatKey["GAME_GAMECODE"] = "game_gameCode";
})(CustomStatKey || (exports.CustomStatKey = CustomStatKey = {}));
var isIClubsGameStats = function (value) {
    return value.local && value.road;
};
exports.isIClubsGameStats = isIClubsGameStats;
