import { Brand, FeatureFlags, SoccerData, t } from 'ultimate-league-common';

import { buildRoutePath } from '#common/routing';
import {
  BASKETBALL_INSTAGRAM_LINK,
  BASKETBALL_TWITTER_LINK,
  COPYRIGHT,
  FOOTBALL_FACEBOOK_LINK,
  FOOTBALL_INSTAGRAM_LINK,
  FOOTBALL_TWITTER_LINK,
  PLATFORM_NAME,
  WHITEPAPER_LINK,
  ZENDESK_LINK,
} from '#constant';
import { useFlag } from '#technical/feature-flags';

import { IFooterConfig, IGameSocialLinks, ILink, ILinksColumn } from '../types';

export const useFooterConfig = (sport: SoccerData.Sport): IFooterConfig => {
  const auctionsEnabled = useFlag(FeatureFlags.Features.AUCTIONS);

  const platformLinks: ILink[] = [
    {
      label: t(`PLAYHUB_FOOTER_LINK_WHITEPAPER`),
      url: WHITEPAPER_LINK,
      isExternal: true,
    },
    {
      label: t(`PLAYHUB_FOOTER_LINK_REFERRAL_PROGRAM`),
      url: buildRoutePath('REFERRAL_PROGRAM', { sport }),
    },
  ];

  if (SoccerData.isActive(SoccerData.Sport.SOCCER)) {
    platformLinks.push({
      label: t(`PLAYHUB_FOOTER_LINK_FOOTBALL_MARKETPLACE`),
      url: buildRoutePath('MARKETPLACE', {
        sport: SoccerData.Sport.SOCCER,
      }),
    });

    if (auctionsEnabled) {
      platformLinks.push({
        label: t('PLAYHUB_FOOTER_LINK_FOOTBALL_AUCTIONS'),
        url: buildRoutePath('AUCTIONS', {
          sport: SoccerData.Sport.SOCCER,
        }),
      });
    }
  }

  if (SoccerData.isActive(SoccerData.Sport.BASKETBALL)) {
    platformLinks.push({
      label: t(`PLAYHUB_FOOTER_LINK_BASKETBALL_MARKETPLACE`),
      url: buildRoutePath('MARKETPLACE', {
        sport: SoccerData.Sport.BASKETBALL,
      }),
    });

    if (auctionsEnabled) {
      platformLinks.push({
        label: t('PLAYHUB_FOOTER_LINK_BASKETBALL_AUCTIONS'),
        url: buildRoutePath('AUCTIONS', {
          sport: SoccerData.Sport.BASKETBALL,
        }),
      });
    }
  }

  const gameSocialLinks: IGameSocialLinks[] = [];
  if (SoccerData.isActive(SoccerData.Sport.SOCCER)) {
    gameSocialLinks.push({
      label: t('SPORT_SOCCER'),
      links: [
        {
          key: 'Twitter',
          label: t(`PLAYHUB_FOOTER_LINK_TWITTER`),
          url: FOOTBALL_TWITTER_LINK,
          isExternal: true,
        },
        {
          key: 'Facebook',
          label: t(`PLAYHUB_FOOTER_LINK_Facebook`),
          url: FOOTBALL_FACEBOOK_LINK,
          isExternal: true,
        },
        {
          key: 'Instagram',
          label: t(`PLAYHUB_FOOTER_LINK_INSTAGRAM`),
          url: FOOTBALL_INSTAGRAM_LINK,
          isExternal: true,
        },
      ],
    });
  }

  if (SoccerData.isActive(SoccerData.Sport.BASKETBALL)) {
    gameSocialLinks.push({
      label: t('SPORT_BASKETBALL'),
      links: [
        {
          key: 'Twitter',
          label: t(`PLAYHUB_FOOTER_LINK_TWITTER`),
          url: BASKETBALL_TWITTER_LINK,
          isExternal: true,
        },
        {
          key: 'Instagram',
          label: t(`PLAYHUB_FOOTER_LINK_INSTAGRAM`),
          url: BASKETBALL_INSTAGRAM_LINK,
          isExternal: true,
        },
      ],
    });
  }

  const linkColumns: ILinksColumn[] = [
    {
      label: PLATFORM_NAME,
      links: platformLinks,
    },
    {
      label: t(`PLAYHUB_FOOTER_LINK_SUPPORT`),
      links: [
        {
          label: t(`PLAYHUB_FOOTER_LINK_CONTACT_US`),
          url: buildRoutePath('CONTACT', {}),
        },
        {
          label: t(`PLAYHUB_FOOTER_LINK_KNOWLEDGE_BASE`),
          url: Brand.switchBrand({ UC: ZENDESK_LINK, LFP: WHITEPAPER_LINK }),
          isExternal: true,
        },
      ],
    },
  ];

  if (Brand.BRAND === 'UC') {
    linkColumns.splice(1, 0, {
      label: t(`PLAYHUB_FOOTER_LINK_TOKEN`),
      links: [
        {
          label: t(`PLAYHUB_FOOTER_LINK_BUY_CHAMP`),
          url: buildRoutePath('BUY_CHAMP', {}),
        },
        {
          label: t(`PLAYHUB_FOOTER_LINK_LEARN_MORE`),
          url: `${WHITEPAPER_LINK}/Economy/$CHAMP`,
          isExternal: true,
        },
      ],
    });
  }

  return {
    linkColumns,
    socialLinksColumn: {
      label: t(`PLAYHUB_FOOTER_LINK_JOIN_US`),
      games: gameSocialLinks,
    },
    extraLinks: [
      Brand.switchBrand({
        UC: {
          label: t(`PLAYHUB_FOOTER_LINK_PRIVACY_POLICY`),
          url: buildRoutePath('PRIVACY', {}),
        },
        LFP: {
          label: t(`PLAYHUB_FOOTER_LINK_DATA_PROTECTION_POLICY`),
          url: t('LFP_FOOTER_LINK_DATA_PROTECTION_POLICY_URL'),
          isExternal: true,
        },
      }),
      {
        label: t(`PLAYHUB_FOOTER_LINK_TERMS_AND_CONDITIONS`),
        url: buildRoutePath('TERMS', {}),
      },
    ],
    copyrightLabel: COPYRIGHT,
  };
};
