import MuiSkeleton, {
  skeletonClasses,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material/Skeleton';
import React from 'react';
import styled, { css } from 'styled-components';

import { KBreakpoint } from '../../../theme';

/* Styled Components */

const StyledSkeleton = styled(MuiSkeleton)<ISkeletonProps>`
  &.${skeletonClasses.root} {
    margin-inline: ${({ alignment }) => alignment === 'center' && 'auto'};
    margin-left: ${({ alignment }) => alignment === 'right' && 'auto'};
    margin-right: ${({ alignment }) => alignment === 'left' && 'auto'};

    ${({ $responsiveHeight, theme }) =>
      $responsiveHeight &&
      Object.entries($responsiveHeight).map(
        ([breakpoint, height]) => css`
          ${theme.breakpoints.up(breakpoint as KBreakpoint)} {
            height: ${typeof height === 'number'
              ? `${height}px`
              : height}!important;
          }
        `
      )}
  }

  &.${skeletonClasses.rectangular} {
    border-radius: 4px;
  }
`;

/* Main Component */

export interface ISkeletonProps {
  variant: Exclude<MuiSkeletonProps['variant'], undefined>;
  height?: `${number}%` | 'auto' | number;
  $responsiveHeight?: {
    [P in KBreakpoint]?: number;
  };
  width?: `${number}%` | 'auto' | number;
  alignment?: 'left' | 'center' | 'right';
  disableAnimation?: boolean;
}

export function Skeleton({
  variant,
  height = '100%',
  width = '100%',
  alignment,
  $responsiveHeight,
  disableAnimation,
}: ISkeletonProps) {
  return (
    <StyledSkeleton
      variant={variant}
      height={height}
      $responsiveHeight={$responsiveHeight}
      width={width}
      alignment={alignment}
      animation={disableAnimation ? false : 'wave'}
    />
  );
}
