import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

import { getRoutePath } from '#common/routing';
import { useAuthorization } from '#technical/network/authorization';

export interface IAuthGuardProps {
  children: ReactNode;
}
export function AuthGuard({ children }: IAuthGuardProps) {
  const { isSigned } = useAuthorization();
  if (!isSigned()) return <Redirect to={getRoutePath('LANDING')} />;

  return <>{children}</>;
}
