import * as React from 'react';
import { createContext, FC, useCallback, useState } from 'react';
import { GameView, GameWeek } from 'ultimate-league-common';

import { fetchGameWeekParticipants } from '#common/game-week';
import { useMemoizedFetch } from '#technical/network/useMemoizedFetch';

function willThrow(): any {
  throw new Error('TotalParticipants context is not initialized');
}

type TotalParticipantsData = Record<
  GameView.IDivision['id'],
  number | undefined
>;

interface ITotalParticipants {
  totalParticipants: TotalParticipantsData;
  fetchTotalParticipants: (
    gameWeekPosition: GameWeek.IGameWeek['position']
  ) => () => void;
}

export const TotalParticipantsContext = createContext<ITotalParticipants>({
  totalParticipants: {},
  fetchTotalParticipants: willThrow,
});

export const TotalParticipantsContainer: FC = ({ children }) => {
  const [totalParticipants, _setTotalParticipants] =
    useState<TotalParticipantsData>({});
  const setTotalParticipants = useCallback(
    (data: TotalParticipantsData) =>
      _setTotalParticipants((oldData) => ({
        ...oldData,
        ...data,
      })),
    [_setTotalParticipants]
  );

  const [, , mFetchTotalParticipants] = useMemoizedFetch<
    GameWeek.IGetParticipants['params']['gameWeek'],
    GameWeek.IGetParticipants['response']
  >(
    (gameWeekPosition, { auth, signal }) =>
      fetchGameWeekParticipants({ gameWeek: gameWeekPosition }, auth, signal),
    {
      cacheKey: (gameWeekPosition) => `gw-participants-${gameWeekPosition}`,
      cacheMS: 300_000,
      handleData: ({ participants }) => setTotalParticipants(participants),
    }
  );

  return (
    <TotalParticipantsContext.Provider
      value={{
        totalParticipants,
        fetchTotalParticipants: mFetchTotalParticipants,
      }}
    >
      {children}
    </TotalParticipantsContext.Provider>
  );
};
