import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { Blockchain, FeatureFlags, t } from 'ultimate-league-common';

import { useFlag } from '#technical/feature-flags';
import { Button, CurrencyIcon, Paragraph, Spacer } from '#ui/components';

import { buildRoutePath } from '../../../../routing';
import { IPaymentDetailsProps } from '../PaymentDetails';

const TokenPaymentContainer = styled.div``;

const TokenOptionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const TokenOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
  padding: ${({ theme }) => theme.spacing(16)};
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.info['10%'] : theme.color.primary['Light 5%']};
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.info['50'] : 'transparent'};
  transition: all 200ms;

  &:hover::before {
    border: ${({ theme, isSelected }) =>
      isSelected
        ? `2px solid ${theme.color.info['50']}`
        : `1px solid ${theme.color.primary['Light 20%']}`};
  }
`;

const TokenOptionDetails = styled.div`
  display: grid;
  gap: 4px;
`;

const MarketplaceFeesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(16)};
`;

const FeeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Fee = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const SummaryContainer = styled.div<{ topBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme, topBorder }) => (topBorder ? theme.spacing(16) : 0)};
  border-top: ${({ theme, topBorder }) =>
    topBorder ? `1px solid ${theme.color.primary['Light 10%']}` : 'none'};
  padding-bottom: ${({ theme, topBorder }) =>
    topBorder ? 0 : theme.spacing(32)};
  border-bottom: ${({ theme, topBorder }) =>
    topBorder ? 'none' : `1px solid ${theme.color.primary['Light 10%']}`};
`;

const TotalPrice = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
  text-align: end;
`;

const TopUpContainer = styled.div``;

const SubmitOrder = styled.div`
  display: grid;
`;

const BalanceInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

export interface ITokenPaymentProps
  extends Pick<
    Required<IPaymentDetailsProps>,
    'paymentDetails' | 'inProgress'
  > {
  insufficientFundsMessage?: string;
  selectedToken?: Blockchain.Token.Token;
  onTokenChange?: (token: Blockchain.Token.Token) => void;
  onPayment?: (selectedToken: Blockchain.Token.Token) => Promise<void>;
}

export const TokenPayment = ({
  inProgress,
  paymentDetails,
  insufficientFundsMessage,
  selectedToken,
  onTokenChange,
  onPayment,
}: ITokenPaymentProps) => {
  const isUnaIOEnabled = useFlag(FeatureFlags.Features.UNA_IO);
  const isChampOptionAvailable = paymentDetails.availableTokens.includes(
    Blockchain.Token.Token.UNA
  );
  const isMgcOptionAvailable = paymentDetails.availableTokens.includes(
    Blockchain.Token.Token.ULC
  );

  const insufficientFunds =
    selectedToken === Blockchain.Token.Token.UNA
      ? paymentDetails.userBalance.champ < paymentDetails.champPrice
      : paymentDetails.userBalance.mgc < paymentDetails.mgcPrice;

  const tokenPrice =
    selectedToken === Blockchain.Token.Token.UNA
      ? paymentDetails.champPrice
      : paymentDetails.mgcPrice;

  const tokenLabel =
    selectedToken === Blockchain.Token.Token.UNA
      ? t('TOKEN_UNA')
      : t('TOKEN_ULC');

  const displayTopUpContent =
    insufficientFunds && selectedToken === Blockchain.Token.Token.UNA;

  const balanceAfterPayment =
    selectedToken === Blockchain.Token.Token.UNA
      ? paymentDetails.userBalance.champ - paymentDetails.champPrice
      : paymentDetails.userBalance.mgc - paymentDetails.mgcPrice;

  const handleTokenPayment = () => {
    if (onPayment && selectedToken) {
      onPayment(selectedToken);
    }
  };

  return (
    <TokenPaymentContainer>
      <Spacer size={32} />

      <TokenOptionsContainer>
        {isChampOptionAvailable && (
          <TokenOption
            isSelected={selectedToken === Blockchain.Token.Token.UNA}
            onClick={() => onTokenChange?.(Blockchain.Token.Token.UNA)}
          >
            <CurrencyIcon coin={Blockchain.Token.Token.UNA} size="M" />
            <TokenOptionDetails>
              <Paragraph variant="M" bold>
                {t('TOKEN_UNA')}
              </Paragraph>
              <Paragraph
                variant="XS"
                $textColor={({ primary }) => primary['0']}
              >
                {paymentDetails.champPrice.toFixed(2)}
              </Paragraph>
            </TokenOptionDetails>
          </TokenOption>
        )}
        {isMgcOptionAvailable && (
          <TokenOption
            isSelected={selectedToken === Blockchain.Token.Token.ULC}
            onClick={() => onTokenChange?.(Blockchain.Token.Token.ULC)}
          >
            <CurrencyIcon coin={Blockchain.Token.Token.ULC} size="M" />
            <TokenOptionDetails>
              <Paragraph variant="M" bold>
                {t('TOKEN_ULC')}
              </Paragraph>
              <Paragraph
                variant="XS"
                $textColor={({ primary }) => primary['0']}
              >
                {paymentDetails.mgcPrice.toFixed(2)}
              </Paragraph>
            </TokenOptionDetails>
          </TokenOption>
        )}
      </TokenOptionsContainer>

      <Spacer size={32} />

      {paymentDetails.fees && (
        <>
          <MarketplaceFeesContainer>
            {paymentDetails.fees.map((fee) => (
              <FeeContainer key={fee.label}>
                <Paragraph
                  variant="M"
                  $textColor={({ primary }) => primary['40']}
                >
                  {fee.label}
                </Paragraph>

                <Fee>
                  <Paragraph
                    variant="M"
                    $textColor={({ primary }) => primary['40']}
                  >
                    {fee.value}
                  </Paragraph>

                  <Paragraph variant="M" bold>
                    {fee.fee}%
                  </Paragraph>
                </Fee>
              </FeeContainer>
            ))}
          </MarketplaceFeesContainer>
          <Spacer size={16} />
        </>
      )}

      <SummaryContainer topBorder={!!paymentDetails.fees}>
        <Paragraph variant="M" $textColor={({ primary }) => primary['40']}>
          {t('TOKEN-PAYMENT_YOU-WILL-PAY')}
        </Paragraph>
        <TotalPrice>
          <Paragraph
            variant="M"
            bold
            $textColor={({ primary, error }) =>
              insufficientFunds ? error['50'] : primary['0']
            }
          >
            {`${tokenPrice.toFixed(2)} ${tokenLabel}`}
          </Paragraph>
          <Paragraph
            variant="S"
            $textColor={({ primary, error }) =>
              insufficientFunds ? error['50'] : primary['20']
            }
          >
            {`${paymentDetails.currency}${paymentDetails.fiatPrice.toFixed(2)}`}
          </Paragraph>
        </TotalPrice>
      </SummaryContainer>

      {displayTopUpContent && (
        <TopUpContainer>
          <Spacer size={32} />
          <Paragraph variant="L" textAlign="center">
            {t('TOKEN-PAYMENT_INSUFFICIENT-FUNDS')}
          </Paragraph>
          <Spacer size={16} />
          <Paragraph
            variant="XS"
            $textColor={({ primary }) => primary['20']}
            textAlign="center"
            multiline
          >
            {insufficientFundsMessage}
          </Paragraph>
          {isUnaIOEnabled && (
            <>
              <Spacer size={32} />
              <Button
                leadingAdornment="open_in_new__filled"
                href={t('WALLET_FUNDING_UNISWAP_LINK-VALUE')}
                label={t('PAYMENT_BALANCE_NOT_ENOUGH_CHAMP_BUY')}
                variant="filled"
                color="success"
                size="M"
                as={RouterLink}
                to={buildRoutePath('BUY_CHAMP', {})}
              />
            </>
          )}
        </TopUpContainer>
      )}

      {!displayTopUpContent && (
        <SubmitOrder>
          <Spacer size={16} />
          <BalanceInfo>
            <Paragraph variant="S" $textColor={({ primary }) => primary['20']}>
              {t('TOKEN-PAYMENT_BALANCE-AFTER')}
            </Paragraph>
            <Paragraph
              variant="S"
              $textColor={({ primary, error }) =>
                balanceAfterPayment < 0 ? error['50'] : primary['20']
              }
            >
              {`${balanceAfterPayment.toFixed(2)} ${tokenLabel}`}
            </Paragraph>
          </BalanceInfo>
          <Spacer size={40} />
          <Button
            label={t('TOKEN-PAYMENT_SUBMIT')}
            variant="filled"
            size="M"
            onClick={handleTokenPayment}
            $loading={inProgress}
            disabled={!onPayment || insufficientFunds}
          />
        </SubmitOrder>
      )}
    </TokenPaymentContainer>
  );
};
