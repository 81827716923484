import { stringify } from 'qs';
import { ExtractRouteParams } from 'react-router';
import { generatePath, matchPath } from 'react-router-dom';
import { SoccerData } from 'ultimate-league-common';

import { ROUTES, SportParams } from '#common/routing';

import { KRoute, TRouteParams } from './types';

export function parseSportParam(value: string | undefined | null) {
  if (typeof value !== 'string') return undefined;

  return Object.entries(SportParams).reduce<SoccerData.Sport | undefined>(
    (match, [sport, sportParam]) => {
      if (match) return match;
      if (value === sportParam) return sport as SoccerData.Sport;
      return undefined;
    },
    undefined
  );
}

export function getRoutePath(key: KRoute) {
  return ROUTES[key];
}

export function buildRoutePath<GKey extends KRoute>(
  key: GKey,
  params: TRouteParams<GKey>,
  query?: object
) {
  const parsedParams =
    'sport' in params
      ? {
          ...params,
          sport:
            SportParams[params.sport] ||
            SportParams[SoccerData.BrandConfig.defaultSport],
        }
      : params;

  return [
    generatePath(ROUTES[key], parsedParams),
    query && stringify(query, { skipNulls: true }),
  ]
    .filter(Boolean)
    .join('?');
}

export function replaceSportParam(pathname: string, sport: SoccerData.Sport) {
  const match = matchPath(pathname, {
    path: Object.values(ROUTES),
    exact: true,
  });

  if (match === null) return undefined;

  if (!('sport' in match.params)) return undefined;

  if ('gameViewId' in match.params && 'divisionId' in match.params) {
    return buildRoutePath('TOURNAMENTS', { sport });
  }

  return generatePath(match.path, {
    ...match.params,
    sport: SportParams[sport],
  });
}

export type IRouteParams<GRoute extends KRoute> = {
  [P in keyof ExtractRouteParams<typeof ROUTES[GRoute]>]: Exclude<
    ExtractRouteParams<typeof ROUTES[GRoute]>[P],
    number | boolean
  >;
};
