"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionBonus = exports.rarityI18NKeys = void 0;
var ConditionBonus = __importStar(require("./conditionBonus"));
exports.ConditionBonus = ConditionBonus;
var type_1 = require("./type");
__exportStar(require("./type"), exports);
__exportStar(require("./endpoint"), exports);
__exportStar(require("./validation"), exports);
__exportStar(require("./events"), exports);
exports.rarityI18NKeys = (_a = {},
    _a[type_1.RarityLevel.FUNGIBLE] = 'RARITY_LEVEL_COMMON',
    _a[type_1.RarityLevel.RARITY_4] = 'RARITY_LEVEL_RARE',
    _a[type_1.RarityLevel.RARITY_3] = 'RARITY_LEVEL_EPIC',
    _a[type_1.RarityLevel.RARITY_2] = 'RARITY_LEVEL_MYTHIC',
    _a[type_1.RarityLevel.RARITY_1] = 'RARITY_LEVEL_UNIQUE',
    _a);
