import { useCallback, useState } from 'react';
import { Pack, Storage } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';

import { getPublicURL } from '#technical/storage';

import { IPackMeta } from '../types';
import { IPackOpeningPopupProps } from './PackOpeningPopup';

export interface IPackOpeningPopupHook<Params extends Record<string, unknown>> {
  showPackOpeningPopup(meta: IPackMeta, requestParams: Params): void;
  props: IPackOpeningPopupProps;
}

export function usePackOpeningPopup<Params extends Record<string, unknown>>(
  openHandler: (params: Params) => Promise<Pack.PackItem[] | null>
): IPackOpeningPopupHook<Params> {
  const {
    value: isVisible,
    setTrue: showPopup,
    setFalse: hideHide,
  } = useBoolean(false);
  const [meta, setMeta] = useState<IPackMeta>();
  const [params, setParams] = useState<Params>();
  const [items, setItems] = useState<IPackOpeningPopupProps['items']>(null);

  const showPackOpeningPopup = useCallback<
    IPackOpeningPopupHook<Params>['showPackOpeningPopup']
  >(
    ({ id, visuals, displayName }, requestParams) => {
      setMeta({
        id,
        displayName,
        visuals: visuals.map((_, index) =>
          getPublicURL({
            index,
            id,
            storageType: Storage.StorageType.PUBLIC_PACK_VISUAL,
          })
        ),
      });
      setParams(requestParams);
      showPopup();
    },
    [showPopup]
  );

  const handleOpenPack = useCallback(async () => {
    if (params === undefined) {
      throw new Error(
        'Invalid params: `showPackOpeningPopup` should have been called first'
      );
    }
    const result = await openHandler(params);
    setItems(result);
    const opened = !!result;
    return opened;
  }, [openHandler, params]);

  const handleExit = useCallback(() => {
    setMeta(undefined);
    setItems(null);
    hideHide();
  }, [hideHide]);

  return {
    showPackOpeningPopup,
    props: {
      isVisible,
      meta,
      items,
      onOpenPack: handleOpenPack,
      onExit: handleExit,
    },
  };
}
