import { ComponentClass, SVGProps } from 'react';

import { ISoccerIconProps } from '../SoccerIcon';
import Assist from './Assist.svg';
import GoalTaken from './Goal Taken.svg';
import RedCard from './Red Card.svg';
import SecondYellowCard from './Second Yellow Card.svg';
import ShotSaved from './Shot Saved.svg';
import Soccer from './Soccer.svg';
import SweeperKeeper from './Sweeper Keeper.svg';
import YellowCard from './Yellow Card.svg';

export const ASSETS: Record<
  ISoccerIconProps['name'],
  ComponentClass<SVGProps<SVGSVGElement>>
> = {
  Assist,
  GoalTaken,
  RedCard,
  SecondYellowCard,
  Soccer,
  SweeperKeeper,
  ShotSaved,
  YellowCard,
};
