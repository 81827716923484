import { useEffect, useMemo, useState } from 'react';
import { FTUE, Pack, SoccerData, User } from 'ultimate-league-common';

import { useMemoizedFetch } from '#technical/network/useMemoizedFetch';

import { getObjectiveRewards } from '../service';
import { IObjectivesProps } from './Objectives';

export interface IObjectivesHook extends Pick<IObjectivesProps, 'objectives'> {
  allObjectivesClaimed: boolean;
}

export const useObjectives = (
  data: FTUE.IFTUE | undefined,
  sport: SoccerData.Sport
): IObjectivesHook => {
  const [objectiveRewards, setObjectiveRewards] =
    useState<Record<FTUE.Objectives, Pack.IPackConfig>>();
  const [, , fetchObjectiveRewards] = useMemoizedFetch<
    User.IGetObjectiveRewards['query'],
    User.IGetObjectiveRewards['response']
  >((params, options) => getObjectiveRewards(params, options.auth), {
    cacheKey: (params) => `fetch-objective-rewards-${params.sport}`,
    cacheMS: 120_000,
    handleData: setObjectiveRewards,
  });

  const objectives = useMemo((): IObjectivesHook['objectives'] => {
    if (data === undefined) return undefined;
    if (objectiveRewards === undefined) return undefined;

    return data.objectives.map((entry) => ({
      ...entry,
      reward: objectiveRewards[entry.objective],
    }));
  }, [data, objectiveRewards]);

  useEffect(() => {
    fetchObjectiveRewards({ sport });
  }, [fetchObjectiveRewards, sport]);

  const allObjectivesClaimed = objectives
    ? objectives.every(({ progress }) => progress === FTUE.Progress.COMPLETED)
    : true;

  return {
    allObjectivesClaimed,
    objectives,
  };
};
