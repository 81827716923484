import { Authorization, Payment } from 'ultimate-league-common';

import { fetchApi, submitData } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';
import { getRecaptchaHeader } from '#technical/recaptcha';

export async function fetchAvailablePayments(auth: IAuthorization): Promise<{
  payments: Payment.AvailablePayment[];
}> {
  const response = await fetchApi('/payment/', {}, auth);
  return response.json();
}

export function patchPaymentIntent(
  { intentId }: Payment.IPatchPaymentIntent['params'],
  body: Payment.IPatchPaymentIntent['body'],
  auth: IAuthorization
): Promise<Payment.IPatchPaymentIntent['response']> {
  return submitData(
    `/payment/fiat/payment-intent/${intentId}`,
    body,
    auth,
    'PATCH'
  );
}

export async function directTokenPackBuy(
  params: Payment.IDirectTokenPackBuy['body'],
  auth: IAuthorization
): Promise<void> {
  return submitData('/payment/direct-token/pack-buy', params, auth, {
    headers: await getRecaptchaHeader(Authorization.RecaptchaAction.BUY_PACK),
  });
}

export const directTokenCardBuy = (
  params: Payment.IDirectTokenMarketBuy['body'],
  auth: IAuthorization
): Promise<Payment.IDirectTokenMarketBuy['response']> =>
  submitData(`/payment/direct-token/market-buy`, params, auth);

export async function setSaleOngoing(
  intentId: Payment.ISetSaleOngoing['body']['intentId'],
  auth: IAuthorization
) {
  await submitData('/payment/set-sale-ongoing', { intentId }, auth);
}

export function createIntentCardBuy(
  data: Payment.IRequestCreateIntentMarketBuy['body'],
  auth: IAuthorization
): Promise<Payment.IRequestCreateIntentMarketBuy['response']> {
  return submitData('/payment/create-intent/market-buy', data, auth);
}

export async function cancelIntentCardBuy(
  { intentId, method }: Payment.ICancelIntentMarketBuy['params'],
  auth: IAuthorization
) {
  await submitData(
    `/payment/cancel-intent/market-buy/${method}/${intentId}`,
    undefined,
    auth
  );
}

export async function createIntentPackBuy(
  data: Payment.IRequestCreateIntentPackBuy['body'],
  auth: IAuthorization
): Promise<Payment.IRequestCreateIntentPackBuy['response']> {
  return submitData('/payment/create-intent/pack-buy', data, auth, {
    headers: await getRecaptchaHeader(Authorization.RecaptchaAction.BUY_PACK),
  });
}

export async function cancelIntentPackBuy(
  { method, intentId }: Payment.ICancelIntentPackBuy['params'],
  auth: IAuthorization
) {
  await submitData(
    `/payment/cancel-intent/pack-buy/${method}/${intentId}`,
    undefined,
    auth
  );
}

export function deletePaymentMethod(
  paymentMethodId: string,
  auth: IAuthorization
) {
  return fetchApi(
    `/payment/fiat/payment-method/${paymentMethodId}`,
    { method: 'DELETE' },
    auth
  );
}

export async function createIntentChampBuy(
  data: Payment.IRequestCreateIntentChampBuy['body'],
  auth: IAuthorization
): Promise<Payment.IRequestCreateIntentChampBuy['response']> {
  return submitData('/payment/create-intent/champ-buy', data, auth, {
    headers: await getRecaptchaHeader(Authorization.RecaptchaAction.BUY_CHAMP),
  });
}

export async function cancelIntentChampBuy(
  { method, intentId }: Payment.ICancelIntentChampBuy['params'],
  auth: IAuthorization
) {
  await submitData(
    `/payment/cancel-intent/champ-buy/${method}/${intentId}`,
    undefined,
    auth
  );
}

export async function fetchOngoingChampSales(
  auth: IAuthorization
): Promise<Payment.IGetOngoingChampSales['response']> {
  const response = await fetchApi(
    `/payment/ongoing/champ-buy`,
    undefined,
    auth
  );
  return response.json();
}
