"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.address = void 0;
exports.address = {
    format: {
        pattern: /^(0x)?[a-f\d]{40}$/i,
    },
    presence: {
        allowEmpty: false,
    },
};
