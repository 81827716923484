import React from 'react';

import { BIconProps, getIconAttrs } from '../Icon';
import { ASSETS } from './assets';

/* Main Component */

export interface ISoccerIconProps extends BIconProps {
  name:
    | 'Assist'
    | 'GoalTaken'
    | 'RedCard'
    | 'SecondYellowCard'
    | 'Soccer'
    | 'SweeperKeeper'
    | 'ShotSaved'
    | 'YellowCard';
}

export function SoccerIcon({ name, ...iconProps }: ISoccerIconProps) {
  const SVG = ASSETS[name];
  return <SVG {...getIconAttrs(iconProps)} />;
}
