import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';

import { BrandIcon, IconButton, IIconButtonProps } from '#ui/components';

import { ISocialLink } from '../types';

const IconLink = styled.a``;

export interface ISocialLinkProps {
  link: ISocialLink;
  size: IIconButtonProps['size'];
  color?: IIconButtonProps['color'];
  onClick?(): void;
}

export const SocialLink: FC<ISocialLinkProps> = ({
  link,
  size,
  color,
  onClick,
}: ISocialLinkProps) => (
  <IconLink
    href={link.url}
    title={link.label}
    target="_blank"
    onClick={onClick}
  >
    <IconButton
      icon={<BrandIcon brand={link.key} variant="mono" />}
      variant="text"
      color={color}
      size={size}
    />
  </IconLink>
);
