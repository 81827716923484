"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBracket = exports.getBracketIndex = exports.isSubstituteAt = exports.getRequiredCountOnField = exports.getTotalCountInSubstitutes = exports.getRequiredCountInSubstitutes = exports.getRequiredCountInTeam = exports.findSlot = exports.formationToSlots = void 0;
var type_1 = require("./type");
function formationToSlots(formation) {
    var partialSlot = [];
    formation.team.forEach(function (_a, requirementIndex) {
        var position = _a.position, count = _a.count;
        return Array.from({ length: count }).forEach(function (_, index) {
            return partialSlot.push({
                requirementIndex: requirementIndex,
                index: index,
                position: position,
                substitute: false,
                required: true,
            });
        });
    });
    formation.substitutes.forEach(function (_a, requirementIndex) {
        var position = _a.position, count = _a.count, required = _a.required;
        return Array.from({ length: count }).forEach(function (_, index) {
            return partialSlot.push({
                requirementIndex: requirementIndex,
                index: index,
                position: position,
                substitute: true,
                required: !!required,
            });
        });
    });
    return partialSlot.map(function (slot, slotPositionInFormation) { return (__assign(__assign({}, slot), { slotPositionInFormation: slotPositionInFormation })); });
}
exports.formationToSlots = formationToSlots;
function findSlot(slots, substitute, requirementIndex, index) {
    return slots.find(function (s) {
        return s.substitute === substitute &&
            s.requirementIndex === requirementIndex &&
            s.index === index;
    });
}
exports.findSlot = findSlot;
function getRequiredCountInTeam(formation) {
    return formation.team.reduce(function (total, _a) {
        var count = _a.count;
        return total + count;
    }, 0);
}
exports.getRequiredCountInTeam = getRequiredCountInTeam;
function getRequiredCountInSubstitutes(formation) {
    return formation.substitutes
        .filter(function (_a) {
        var required = _a.required;
        return !!required;
    })
        .reduce(function (total, _a) {
        var count = _a.count;
        return total + count;
    }, 0);
}
exports.getRequiredCountInSubstitutes = getRequiredCountInSubstitutes;
function getTotalCountInSubstitutes(formation) {
    return formation.substitutes.reduce(function (total, _a) {
        var count = _a.count;
        return total + count;
    }, 0);
}
exports.getTotalCountInSubstitutes = getTotalCountInSubstitutes;
function getRequiredCountOnField(formation) {
    return (getRequiredCountInTeam(formation) + getRequiredCountInSubstitutes(formation));
}
exports.getRequiredCountOnField = getRequiredCountOnField;
function isSubstituteAt(formation, slotPositionInFormation) {
    var totalInTeam = getRequiredCountInTeam(formation);
    return slotPositionInFormation >= totalInTeam;
}
exports.isSubstituteAt = isSubstituteAt;
function getBracketIndex(brackets, value, total) {
    if (brackets === void 0) { brackets = []; }
    brackets.sort(function (a, b) {
        if (a.type === b.type) {
            return a.from - b.from;
        }
        return a.type === type_1.BracketType.ABSOLUTE ? -1 : 1;
    });
    var absolute = Math.round(value);
    var percentile = Math.round((100 * value) / total);
    return brackets.findIndex(function (_a) {
        var type = _a.type, from = _a.from, to = _a.to;
        switch (type) {
            case type_1.BracketType.ABSOLUTE:
                return from <= absolute && to >= absolute;
            case type_1.BracketType.PERCENTAGE:
                return from <= percentile && to >= percentile;
            default:
                return false;
        }
    });
}
exports.getBracketIndex = getBracketIndex;
function getBracket(brackets, value, total) {
    if (brackets === void 0) { brackets = []; }
    return brackets[getBracketIndex(brackets, value, total)];
}
exports.getBracket = getBracket;
