import MuiCircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import MuiLinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import React from 'react';
import styled from 'styled-components';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';
import { TColorValue, useTheme } from '#ui/theme';

/* Styled Components */

export const progressClasses = {
  linearRoot: linearProgressClasses.root,
  linearBar: linearProgressClasses.bar,
  circularRoot: circularProgressClasses.root,
};

const Linear = styled(MuiLinearProgress)<{
  size: IProgressProps['size'];
  $barColor: string;
  $trackColor: string;
  opaque: boolean;
}>`
  &.${progressClasses.linearRoot} {
    border-radius: 100px;
    background-color: ${({ $trackColor, opaque }) =>
      `${$trackColor}${opaque ? 33 : ''}`};

    height: ${({ size }) => {
      switch (size) {
        case 'S':
          return '2px';
        case 'M':
          return '4px';
        case 'L':
          return '8px';
        case 'XL':
          return '16px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};
  }

  .${progressClasses.linearBar} {
    border-radius: ${({ variant }) => variant === 'indeterminate' && '100px'};
    background-color: ${({ $barColor }) => $barColor};
  }
`;

const CircularWrapper = styled.div`
  display: flex;
  position: relative;
`;

const BackgroundCircular = styled(MuiCircularProgress)<{
  $trackColor: string;
  opacity: number;
}>`
  &.${progressClasses.circularRoot} {
    color: ${({ $trackColor }) => $trackColor};
    opacity: ${({ opacity }) => opacity};
  }
`;

const Circular = styled(MuiCircularProgress)<{
  $barColor: string;
}>`
  &.${progressClasses.circularRoot} {
    position: absolute;
    left: 0;
    color: ${({ $barColor }) => $barColor};
  }
`;

/* Main Component */

export interface IProgressProps {
  variant: 'linear' | 'circular';
  size: 'S' | 'M' | 'L' | 'XL';
  /**
   * Progress in percent.
   * If set to undefined the progress is rendered as indeterminate.
   */
  value: number | undefined;
  color?:
    | 'default'
    | 'accent'
    | 'dark'
    | 'success'
    | 'warning'
    | 'error'
    | 'info'
    | 'virtual';
  barColor?: TColorValue;
  className?: string;
}

export function Progress({
  size,
  value,
  color = 'default',
  barColor,
  variant,
  className,
}: IProgressProps) {
  const theme = useTheme();
  const colors = ((): { bar: string; track: string } => {
    if (barColor)
      return {
        bar: barColor,
        track: barColor,
      };

    switch (color) {
      case 'default':
        return {
          bar: theme.color.primary['0'],
          track: theme.color.primary['Light 20%'],
        };
      case 'dark':
        return {
          bar: theme.color.primary['100'],
          track: theme.color.primary['Dark 20%'],
        };
      case 'accent':
        return {
          bar: theme.color.accent['50'],
          track: theme.color.accent['20%'],
        };
      case 'success':
        return {
          bar: theme.color.success['50'],
          track: theme.color.success['20%'],
        };
      case 'warning':
        return {
          bar: theme.color.warning['50'],
          track: theme.color.warning['20%'],
        };
      case 'error':
        return {
          bar: theme.color.error['50'],
          track: theme.color.error['20%'],
        };
      case 'info':
        return {
          bar: theme.color.info['50'],
          track: theme.color.info['20%'],
        };
      case 'virtual':
        return {
          bar: theme.color.virtual['50'],
          track: theme.color.virtual['20%'],
        };
      default:
        throw catchMissingSwitchCase(color);
    }
  })();
  const circular = ((): { size: number; thickness: number } => {
    switch (size) {
      case 'S':
        return { size: 16, thickness: 2 };
      case 'M':
        return { size: 20, thickness: 2.2 };
      case 'L':
        return { size: 24, thickness: 2.6 };
      case 'XL':
        return { size: 32, thickness: 2.8 };
      default:
        throw catchMissingSwitchCase(size);
    }
  })();

  const trackOpacity = barColor ? 0.2 : 1;

  return (
    <>
      {variant === 'linear' && (
        <Linear
          variant={value === undefined ? 'indeterminate' : 'determinate'}
          value={value}
          size={size}
          $barColor={colors.bar}
          $trackColor={colors.track}
          opaque={trackOpacity < 1}
          className={className}
        />
      )}
      {variant === 'circular' && (
        <CircularWrapper className={className}>
          <BackgroundCircular
            variant="determinate"
            value={100}
            size={circular.size}
            thickness={circular.thickness}
            $trackColor={colors.track}
            opacity={trackOpacity}
          />
          <Circular
            variant={value === undefined ? 'indeterminate' : 'determinate'}
            value={value}
            size={circular.size}
            thickness={circular.thickness}
            $barColor={colors.bar}
          />
        </CircularWrapper>
      )}
    </>
  );
}
