import React from 'react';
import styled, { css } from 'styled-components';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { Progress } from '../../feedback';
import { IconSlot, TIconProp } from '../../icons';
import { computeColors, getLoadingColor } from './IconButton.config';

/* Main Component */
const DEFAULT_SIZE = 'M';

export interface IIconButtonProps {
  icon: TIconProp;
  variant: 'filled' | 'text' | 'tonal';
  color?:
    | 'default'
    | 'accent'
    | 'dark'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'subtle'
    | 'virtual';
  size?: 'XS' | 'S' | 'M' | 'L';
  $loading?: boolean;
  disabled?: boolean;
}

/**
 * Use `Button` to allow users to take actions, and make choices, with a single tap.
 */
export const IconButton = styled.button.attrs<IIconButtonProps>(
  ({
    icon,
    size = DEFAULT_SIZE,
    variant,
    color = 'default',
    $loading = false,
  }) => {
    const iconSize = (() => {
      switch (size) {
        case 'L':
          return 'XL';
        case 'M':
          return 'L';
        case 'S':
          return 'M';
        case 'XS':
          return 'M';
        default:
          throw catchMissingSwitchCase(size);
      }
    })();

    return {
      children: $loading ? (
        <Progress
          variant="circular"
          color={getLoadingColor({ variant, color })}
          size={iconSize}
          value={undefined}
        />
      ) : (
        <IconSlot source={icon} size={iconSize} />
      ),
    };
  }
)<IIconButtonProps>`
  ${({
    variant,
    color = 'default',
    size = DEFAULT_SIZE,
    disabled,
    $loading,
    theme,
  }) => css`
    // Layout
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0;

    border: none;
    border-radius: 50%;
    appearance: none;

    // User interaction
    cursor: pointer;
    pointer-events: ${disabled || $loading ? 'none' : undefined};
    user-select: none;
    &:active {
      transform: scale(0.95);
    }

    transition: all 300ms;

    // Sizing
    box-sizing: border-box;
    aspect-ratio: 1 / 1;
    height: ${() => {
      switch (size) {
        case 'L':
          return '56px';
        case 'M':
          return '48px';
        case 'S':
          return '40px';
        case 'XS':
          return '32px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};

    // Colors
    ${() => {
      const colorConfig = computeColors(theme.color)[color][variant];

      if ($loading)
        return css`
           {
            color: ${colorConfig.loading.text};
            background-color: ${colorConfig.loading.background};
          }
        `;

      return css`
        color: ${colorConfig.default.text};
        background-color: ${colorConfig.default.background || 'transparent'};

        &.hover,
        &:hover {
          transition: none;
          color: ${colorConfig.hover.text};
          background-color: ${colorConfig.hover.background};
        }

        &.focus,
        &:focus {
          transition: none;
          outline: none;
          color: ${colorConfig.focused.text};
          background-color: ${colorConfig.focused.background};
        }

        &.active,
        &:active {
          color: ${colorConfig.pressed.text};
          background-color: ${colorConfig.pressed.background};
        }

        &:disabled {
          color: ${colorConfig.disabled.text};
          background-color: ${colorConfig.disabled.background};
        }
      `;
    }};
  `}
`;
