"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptaMatchStatus = void 0;
var OptaMatchStatus;
(function (OptaMatchStatus) {
    OptaMatchStatus["FIXTURE"] = "Fixture";
    OptaMatchStatus["PLAYING"] = "Playing";
    OptaMatchStatus["PLAYED"] = "Played";
    OptaMatchStatus["CANCELLED"] = "Cancelled";
    OptaMatchStatus["POSTPONED"] = "Postponed";
    OptaMatchStatus["SUSPENDED"] = "Suspended";
    OptaMatchStatus["AWARDED"] = "Awarded";
})(OptaMatchStatus || (exports.OptaMatchStatus = OptaMatchStatus = {}));
