"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.idOriginal = exports.id = void 0;
exports.id = {
    format: {
        pattern: /^[a-f\d]{24}$/i,
    },
    presence: {
        allowEmpty: false,
    },
};
exports.idOriginal = {
    format: {
        pattern: /^[a-zA-Z0-9]{1,64}$/,
    },
    presence: {
        allowEmpty: false,
    },
};
