"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestUpload = void 0;
var NFT_card_1 = require("../blockchain/NFT-card");
var technical_1 = require("../technical");
var type_1 = require("./type");
exports.requestUpload = {
    type: {
        inclusion: (0, technical_1.enumToArray)(type_1.StorageType),
        presence: true,
    },
    fileName: {
        type: 'string',
        presence: false,
    },
    fileExtension: {
        format: /^\w{0,10}$/,
        presence: false,
    },
    teamId: {
        format: technical_1.id.format,
        presence: false,
    },
    athleteId: {
        format: technical_1.id.format,
        presence: false,
    },
    packConfigId: {
        format: technical_1.id.format,
        presence: false,
    },
    index: {
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
        },
        presence: false,
    },
    format: {
        inclusion: (0, technical_1.enumToArray)(type_1.AssetFormat),
        presence: false,
    },
    rarities: {
        array: (_a = {},
            _a[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _a.inclusion = (0, technical_1.enumToArray)(NFT_card_1.RarityLevel, false),
            _a),
    },
};
