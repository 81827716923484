import {
  cs,
  de,
  enUS,
  es,
  fr,
  it,
  pl,
  pt,
  ro,
  ru,
  sk,
  sr,
  tr,
  vi,
  zhCN,
} from 'date-fns/locale';
import { useCallback, useState } from 'react';
import {
  defaultLang,
  enumToArray,
  getLang,
  LANG,
  setLang,
} from 'ultimate-league-common';
import { useLocalStorage } from 'usehooks-ts';

import { ISelectOption } from '#ui/components';

import { catchMissingSwitchCase } from './catchMissingSwitchCase';
import { PersistKey } from './persist/persist';

const langArray: LANG[] = enumToArray(LANG);

export function getLanguageFromBrowser(browserLanguage: string) {
  const browserLanguageShorthand = browserLanguage.toLowerCase().split('-')[0];
  const match = langArray.find(
    (language) => language.toLowerCase() === browserLanguageShorthand
  );
  if (!match) {
    return defaultLang;
  }
  return match;
}

function getDateFnsLocale(lang: LANG) {
  switch (lang) {
    case LANG.CS:
      return cs;
    case LANG.DE:
      return de;
    case LANG.EN:
      return enUS;
    case LANG.ES:
      return es;
    case LANG.FR:
      return fr;
    case LANG.IT:
      return it;
    case LANG.PL:
      return pl;
    case LANG.PT:
      return pt;
    case LANG.RO:
      return ro;
    case LANG.RU:
      return ru;
    case LANG.SK:
      return sk;
    case LANG.SR:
      return sr;
    case LANG.TR:
      return tr;
    case LANG.VI:
      return vi;
    case LANG.ZH:
      return zhCN;
    case null:
      return enUS;
    default:
      throw catchMissingSwitchCase(lang);
  }
}

const LANGUAGE_OPTIONS = [
  { label: 'Chinese Simplified (简体中文)', value: LANG.ZH },
  { label: 'Czech (Čeština)', value: LANG.CS },
  { label: 'English (UK)', value: LANG.EN },
  { label: 'French (Français)', value: LANG.FR },
  { label: 'German (Deutsch)', value: LANG.DE },
  { label: 'Italian (Italiano)', value: LANG.IT },
  { label: 'Polish (Polski)', value: LANG.PL },
  { label: 'Portuguese (Português)', value: LANG.PT },
  { label: 'Romanian (Românește)', value: LANG.RO },
  { label: 'Russian (Русский)', value: LANG.RU },
  { label: 'Serbian (Srpski)', value: LANG.SR },
  { label: 'Slovakian (Slovenčina)', value: LANG.SK },
  { label: 'Spanish (Español)', value: LANG.ES },
  { label: 'Turkish (Türkçe)', value: LANG.TR },
  { label: 'Vietnamese (Tiếng Việt)', value: LANG.VI },
];

export interface ILanguageHook {
  ready: boolean;
  currentLang: LANG;
  changeLang(lang: LANG): void;
  initializeLang(): void;
  languageOptions: Array<ISelectOption<LANG>>;
  dateLocale: Locale;
}

export function useLanguage(): ILanguageHook {
  const [ready, setReady] = useState(false);
  const [storedLang, setStoredLang] = useLocalStorage<{ current: LANG } | null>(
    PersistKey.LANG,
    () => {
      const storedValue = localStorage.getItem(PersistKey.LANG);
      return storedValue ? JSON.parse(storedValue) : null;
    }
  );

  const changeLang = useCallback<ILanguageHook['changeLang']>(
    (lang) => {
      setStoredLang({ current: lang });
      window.location.reload();
    },
    [setStoredLang]
  );
  const initializeLang = useCallback<ILanguageHook['initializeLang']>(() => {
    const lang =
      storedLang?.current || getLanguageFromBrowser(global.navigator.language);
    setStoredLang({ current: lang });
    setLang(lang).then(() => setReady(true));
  }, [setStoredLang, storedLang]);

  const currentLang = storedLang?.current || getLang();

  return {
    ready,
    currentLang,
    changeLang,
    languageOptions: LANGUAGE_OPTIONS,
    dateLocale: getDateFnsLocale(currentLang),
    initializeLang,
  };
}
