import amplitude, { AmplitudeClient } from 'amplitude-js';
import { parseActionCodeURL } from 'firebase/auth';
import { SoccerData, User } from 'ultimate-league-common';

import { SERVER } from '#technical/network/api';

const AMPLITUDE_ENVIRONMENT = (() => {
  // Releases
  if (process.env.CI_COMMIT_REF_SLUG?.includes('release')) {
    return 'master';
  }

  // develop, staging & feature branches
  if (process.env.NODE_ENV === 'production') {
    return 'develop';
  }

  // dev
  return `${process.env.NODE_ENV}-dev`;
})();

interface ITrackerContext {
  user: {
    id: string;
    username?: string;
  };
}

function init(instance: AmplitudeClient) {
  const { AMPLITUDE_API_KEY } = process.env;

  if (!AMPLITUDE_API_KEY) {
    throw new Error('Missing env AMPLITUDE_API_KEY');
  }

  instance.init(AMPLITUDE_API_KEY, undefined, {
    apiEndpoint: SERVER?.startsWith('https://')
      ? `${SERVER!.replace('https://', '')}/amplitude`
      : undefined,
    includeReferrer: true,
    includeUtm: false,
    includeFbclid: true,
    includeGclid: true,
  });

  if (process.env.COMMIT) {
    instance.setVersionName(process.env.COMMIT);
  }

  const { continueUrl } = parseActionCodeURL(window.location.href) ?? {};
  if (continueUrl) {
    const continueUrlSearchParams = new URL(continueUrl).searchParams;
    const deviceId = continueUrlSearchParams.get('device_id');
    if (deviceId) instance.setDeviceId(deviceId);
  }
}

const instance =
  amplitude && (amplitude.getInstance() as AmplitudeClient | undefined);
if (instance) {
  init(instance);
}

export function getDeviceId() {
  return instance?.options.deviceId ?? '';
}

const logEvent: AmplitudeClient['logEvent'] | undefined = (event, data) =>
  instance?.logEvent(event, {
    environment: AMPLITUDE_ENVIRONMENT,
    ...data,
  });

export const analytics = {
  configure: (context: Partial<ITrackerContext>) => {
    const { user } = context || {};

    if (user) {
      instance?.setUserId(user.id);
      instance?.setUserProperties({
        environment: AMPLITUDE_ENVIRONMENT,
      });
      if (user.username) {
        instance?.setUserProperties({
          username: user.username,
        });
      }
    }
  },
  destroy: () => {
    instance?.setUserId(null);
  },

  logLand(
    params: Partial<User.IUTM> & { sport: SoccerData.Sport } = {
      sport: SoccerData.Sport.SOCCER,
    }
  ) {
    return logEvent('Event.Manager.Land', params);
  },
  logPageView(params: { path: string; sport: SoccerData.Sport }) {
    return logEvent('Event.Manager.PageView', params);
  },
  logFTUECard(params: { tutorial: string; step: number }) {
    return logEvent('Event.Manager.TutorialCard', params);
  },
  logUATracking(params: {
    command: string;
    event: string;
    sendTo: string;
    sendToRaw: string;
    value?: string | number;
    currency?: string;
    transactionId?: string;
  }) {
    return logEvent('Event.Manager.UA', params);
  },
};
