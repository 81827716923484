import MuiDialog, { dialogClasses } from '@mui/material/Dialog';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Authorization, Blockchain, t } from 'ultimate-league-common';

import { PaymentDetails, PopupHeader, Transition } from '#common/transaction';
import {
  Chip,
  CurrencyIcon,
  IconButton,
  Paragraph,
  showSnackbar,
} from '#ui/components';

import { IBuyChampPopup } from '../../types';

const Container = styled(MuiDialog)`
  overflow: auto;

  .${dialogClasses.paper} {
    overflow: visible;
    margin: unset;
    width: 378px;
    border-radius: 16px;
    box-sizing: border-box;
    box-shadow: 0px 8px 36px rgba(0, 8, 8, 0.24),
      0px 2px 6px rgba(8, 8, 8, 0.16);
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(8)};
  right: ${({ theme }) => theme.spacing(8)};
`;

export interface IBuyChampPopupProps
  extends Pick<
    IBuyChampPopup,
    | 'champPrice'
    | 'paymentDetails'
    | 'close'
    | 'onCancelPaymentIntent'
    | 'onCreatePaymentIntent'
    | 'purchaseConfirmation'
    | 'onCardPayment'
  > {}

export const BuyChampPopup = ({
  champPrice,
  paymentDetails,
  onCreatePaymentIntent,
  onCancelPaymentIntent,
  onCardPayment,
  close,
}: IBuyChampPopupProps) => {
  const handleCreatePaymentIntent = useCallback(
    async (payment) => {
      try {
        return await onCreatePaymentIntent?.(payment);
      } catch (e) {
        if (e instanceof Authorization.RecaptchaError) {
          showSnackbar(t('RECAPTCHA_ERROR_MESSAGE'), { severity: 'error' });
        } else {
          showSnackbar((e as Error).message, { severity: 'error' });
        }
        throw e;
      }
    },
    [onCreatePaymentIntent]
  );

  return (
    <Container
      onClose={close}
      TransitionComponent={Transition}
      open={!!champPrice}
    >
      <CloseButton
        icon="close__filled"
        variant="text"
        color="subtle"
        onClick={close}
        size="S"
      />
      <PopupHeader
        isLoading={champPrice === undefined}
        title={t('TRANSACTION-POPUP_HEADER-TITLE-BUY')}
        name={
          <Title>
            <Paragraph variant="XXL">{champPrice?.toFixed(2)}</Paragraph>
            <Chip
              size="M"
              variant="tonal"
              label={t('TOKEN_UNA')}
              leadingIcon={<CurrencyIcon coin={Blockchain.Token.Token.UNA} />}
            />
          </Title>
        }
      />
      <PaymentDetails
        paymentDetails={paymentDetails}
        onCancelPaymentIntent={onCancelPaymentIntent}
        onCreatePaymentIntent={handleCreatePaymentIntent}
        onCardPayment={onCardPayment}
      />
    </Container>
  );
};
