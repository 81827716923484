"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pagination = void 0;
exports.pagination = {
    offset: {
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
        },
    },
    limit: {
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
        },
    },
};
