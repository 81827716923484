"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.brandConfig = exports.KYCStatus = void 0;
var brand_1 = require("../../brand");
var KYCStatus;
(function (KYCStatus) {
    KYCStatus["RECOMMENDED"] = "RECOMMENDED";
    KYCStatus["INITIATED"] = "INITIATED";
    KYCStatus["PROCESSING"] = "PROCESSING";
    KYCStatus["SUCCESS"] = "SUCCESS";
    KYCStatus["UNSUCCESSFUL"] = "UNSUCCESSFUL";
})(KYCStatus || (exports.KYCStatus = KYCStatus = {}));
exports.brandConfig = (0, brand_1.switchBrand)({
    UC: {
        enabled: true,
    },
    LFP: {
        enabled: false,
    },
});
