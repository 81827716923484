import React, { ComponentClass } from 'react';
import { Blockchain, Brand } from 'ultimate-league-common';

import { BIconProps, SVGIcon } from '../Icon';
import { ASSETS_LFP } from './assets/LFP';
import { ASSETS_UC } from './assets/UC';

export type IRaritySvg = Record<
  IRarityIconProps['rarity'],
  ComponentClass<React.SVGProps<SVGSVGElement>> | null
>;

const ASSETS = Brand.switchBrand<IRaritySvg>({
  UC: ASSETS_UC,
  LFP: ASSETS_LFP,
});

export interface IRarityIconProps extends BIconProps {
  rarity: Blockchain.NFTCard.RarityLevel;
}

export const RarityIcon = ({ rarity, ...baseProps }: IRarityIconProps) => {
  const source = ASSETS[rarity];

  if (!source) return <svg />;

  return <SVGIcon source={source} {...baseProps} />;
};
