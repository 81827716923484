"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG = void 0;
var LANG;
(function (LANG) {
    // Czech
    LANG["CS"] = "cs";
    // German
    LANG["DE"] = "de";
    // English
    LANG["EN"] = "en";
    // Spanish
    LANG["ES"] = "es";
    // French
    LANG["FR"] = "fr";
    // Italian
    LANG["IT"] = "it";
    // Polish
    LANG["PL"] = "pl";
    // Portuguese
    LANG["PT"] = "pt";
    // Romanian
    LANG["RO"] = "ro";
    // Russian
    LANG["RU"] = "ru";
    // Slovakian
    LANG["SK"] = "sk";
    // Serbian
    LANG["SR"] = "sr";
    // Turkish
    LANG["TR"] = "tr";
    // Vietnamese
    LANG["VI"] = "vi";
    // Chinese
    LANG["ZH"] = "zh";
})(LANG || (exports.LANG = LANG = {}));
