import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Payment } from 'ultimate-league-common';

import { IconButton, Paragraph } from '#ui/components';

import { CardBrandIcon } from '../CardBrandIcon';

const CardInputContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: ${({ theme }) => theme.spacing(8)};
  padding-left: ${({ theme }) => theme.spacing(16)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  cursor: pointer;
  border-radius: 8px;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.color.info['10%'] : theme.color.primary['Light 5%']};
  border: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.color.info['50'] : 'transparent'};
  transition: all 200ms;

  &:hover::before {
    outline: ${({ theme, isSelected }) =>
      isSelected
        ? `2px solid ${theme.color.info['50']}`
        : `1px solid ${theme.color.primary['Light 20%']}`};
  }
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
`;

export interface ICardInputProps {
  brand: Payment.CardBrand;
  last4: string;
  isSelected: boolean;
  onDelete?: () => void;
  onSelect: () => void;
}

export const CardInput = ({
  brand,
  last4,
  isSelected,
  onDelete,
  onSelect,
}: ICardInputProps) => {
  const handleRemoveCardClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onDelete?.();
  };

  return (
    <CardInputContainer isSelected={isSelected} onClick={onSelect}>
      <CardInfo>
        <CardBrandIcon brand={brand} />
        <Paragraph
          variant="M"
          $textColor={({ primary }) =>
            isSelected ? primary['0'] : primary['40']
          }
        >
          •••• •••• •••• {last4}
        </Paragraph>
      </CardInfo>
      <IconButton
        icon="delete__filled"
        variant="text"
        size="XS"
        onClick={handleRemoveCardClick}
      />
    </CardInputContainer>
  );
};
