"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PerformanceLevel = exports.BasketballCategory = exports.SoccerCategory = void 0;
var SoccerCategory;
(function (SoccerCategory) {
    SoccerCategory["STARTING"] = "STARTING";
    SoccerCategory["CLUB"] = "CLUB";
    SoccerCategory["ERRORS"] = "ERRORS";
    SoccerCategory["POSSESSIVE"] = "POSSESSIVE";
    SoccerCategory["OFFENSIVE"] = "OFFENSIVE";
    SoccerCategory["DEFENSIVE"] = "DEFENSIVE";
    SoccerCategory["GOALKEEPING"] = "GOALKEEPING";
    SoccerCategory["HIDDEN"] = "HIDDEN";
})(SoccerCategory || (exports.SoccerCategory = SoccerCategory = {}));
var BasketballCategory;
(function (BasketballCategory) {
    BasketballCategory["MOST_VALUABLE_ACTIONS"] = "MOST_VALUABLE_ACTIONS";
    BasketballCategory["SCORER"] = "SCORER";
    BasketballCategory["CONTROL"] = "CONTROL";
    BasketballCategory["HIDDEN"] = "HIDDEN";
})(BasketballCategory || (exports.BasketballCategory = BasketballCategory = {}));
var PerformanceLevel;
(function (PerformanceLevel) {
    PerformanceLevel["LOW"] = "Low";
    PerformanceLevel["MEDIUM"] = "Medium";
    PerformanceLevel["HIGH"] = "High";
    PerformanceLevel["NA"] = "NotAvailable";
})(PerformanceLevel || (exports.PerformanceLevel = PerformanceLevel = {}));
