import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Pack } from 'ultimate-league-common';
import { useInterval } from 'usehooks-ts';

import { fetchOngoingPackSales } from '#common/pack';
import { useCurrentSport } from '#common/sport';
import { useAuthorization } from '#technical/network/authorization';

import { CONTEXT_REFRESH_INTERVAL } from '../config';
import { IPacksByPackConfig } from '../types';

interface IOngoingPackSalesContext {
  refresh: () => Promise<void>;
  getOngoingPackSales: (packConfigId: Pack.IPackConfig['id']) => Pack.PackId[];
  getPackConfig: (
    packConfigId: Pack.IPackConfig['id']
  ) => Pack.IPackConfig | undefined;
  getPackConfigIds: () => Pack.IPackConfig['id'][];
}

function willThrow(): any {
  throw new Error('Ongoing pack sales context not initialized');
}

const OngoingPackSalesContext = createContext<IOngoingPackSalesContext>({
  refresh: willThrow,
  getOngoingPackSales: willThrow,
  getPackConfig: willThrow,
  getPackConfigIds: willThrow,
});

export const useOngoingPackSalesContext = () =>
  useContext(OngoingPackSalesContext);

export const OngoingPackSalesProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const { sport } = useCurrentSport();
  const auth = useAuthorization();
  const [ongoingPackSales, setOngoingPackSales] = useState<
    IPacksByPackConfig | undefined
  >();

  const refresh = useCallback(async () => {
    setOngoingPackSales(await fetchOngoingPackSales({ sport }, auth));
  }, [auth, setOngoingPackSales, sport]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useInterval(() => refresh(), CONTEXT_REFRESH_INTERVAL);

  const getOngoingPackSales = useCallback(
    (packConfigId: Pack.IPackConfig['id']) =>
      ongoingPackSales?.[packConfigId]?.packs || [],
    [ongoingPackSales]
  );
  const getPackConfig = useCallback(
    (packConfigId: Pack.IPackConfig['id']) =>
      ongoingPackSales?.[packConfigId]?.packConfig,
    [ongoingPackSales]
  );
  const getPackConfigIds = useCallback(
    () => (ongoingPackSales ? Object.keys(ongoingPackSales) : []),
    [ongoingPackSales]
  );

  return (
    <OngoingPackSalesContext.Provider
      value={{ getOngoingPackSales, getPackConfig, getPackConfigIds, refresh }}
    >
      {children}
    </OngoingPackSalesContext.Provider>
  );
};
