"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.timeoutLoop = void 0;
var TimeoutError = /** @class */ (function (_super) {
    __extends(TimeoutError, _super);
    function TimeoutError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, TimeoutError.prototype);
        return _this;
    }
    return TimeoutError;
}(Error));
var TimeoutJob = /** @class */ (function () {
    function TimeoutJob(job, timeout) {
        this.job = job;
        if (timeout <= 0) {
            throw new Error('Timeout should be a strict positive value.');
        }
        this.timeout = timeout;
    }
    TimeoutJob.prototype.run = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var hasTimeout = false;
            var t = setTimeout(function () {
                hasTimeout = true;
                reject(new TimeoutError());
            }, _this.timeout);
            _this.job()
                .then(function (r) {
                if (!hasTimeout) {
                    clearTimeout(t);
                    resolve(r);
                }
            })
                .catch(reject);
        });
    };
    return TimeoutJob;
}());
function sleep(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
/**
 * Allow to call a function every ms. The loop will wait for f to be executed.
 * You can simply stop the timeoutLoop by calling the returned stop function:
 *
 * @example
 * ```
 * const job = () => Promise.resolve(resolve => {
 *   console.log('Job start');
 *   setTimeout(() => {
 *     console.log('Job done');
 *     resolve();
 *   }, 1000);
 * })
 *
 * //
 * const stop = timeoutLoop({
 *   'MyJob',
 *   job,
 *   500,
 *   Logger: {}
 * });
 *
 *
 * ```
 *
 * @param jobTitle Job title
 * @param f function to run every ms
 * @param intervalMs interval in milliseconds
 * @param timeoutMs Optional allowed timeout for f in milliseconds
 * @param onStop Optional callback called when stop function is called
 * @param Logger
 *
 * @return stop Returns a function that allow to stop the loop.
 */
function timeoutLoop(_a) {
    var jobTitle = _a.jobTitle, job = _a.job, intervalMs = _a.intervalMs, timeoutMs = _a.timeoutMs, onStop = _a.onStop, Logger = _a.Logger;
    var running = true;
    function stop() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!running) return [3 /*break*/, 2];
                        running = false;
                        return [4 /*yield*/, (onStop === null || onStop === void 0 ? void 0 : onStop())];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function runJob() {
        return __awaiter(this, void 0, void 0, function () {
            var beginTimeMs, tJob, e_1, executionTimeMs, nextSleepMs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // Start job at the end of current thread
                    return [4 /*yield*/, sleep(0)];
                    case 1:
                        // Start job at the end of current thread
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!running) return [3 /*break*/, 11];
                        beginTimeMs = Date.now();
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 9]);
                        tJob = timeoutMs
                            ? function () { return new TimeoutJob(job, timeoutMs).run(); }
                            : job;
                        return [4 /*yield*/, tJob()];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 9];
                    case 5:
                        e_1 = _a.sent();
                        if (!(e_1 instanceof TimeoutError)) return [3 /*break*/, 6];
                        Logger.warn("[timeoutLoop] ".concat(jobTitle, " timeout."));
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, stop()];
                    case 7:
                        _a.sent();
                        throw e_1;
                    case 8: return [3 /*break*/, 9];
                    case 9:
                        executionTimeMs = Date.now() - beginTimeMs;
                        nextSleepMs = intervalMs - executionTimeMs;
                        return [4 /*yield*/, sleep(nextSleepMs)];
                    case 10:
                        _a.sent();
                        return [3 /*break*/, 2];
                    case 11: return [2 /*return*/];
                }
            });
        });
    }
    runJob().catch(function (e) {
        Logger.critical("[timeoutLoop] job has throw an error. Stopping timeoutLoop.", e);
        stop().catch(function (stopError) {
            Logger.critical("[timeoutLoop] Could not gracefully stop timeoutLoop", stopError);
        });
    });
    return stop;
}
exports.timeoutLoop = timeoutLoop;
