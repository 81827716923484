import React from 'react';
import { useLocation } from 'react-router';
import {
  Brand,
  exists,
  FeatureFlags,
  LANG,
  SoccerData,
  t,
} from 'ultimate-league-common';

import { GAME_WEEK_SEARCH_PARAM } from '#common/game-week';
import { buildRoutePath } from '#common/routing';
import {
  BASKETBALL_INSTAGRAM_LINK,
  BASKETBALL_TWITTER_LINK,
  FOOTBALL_FACEBOOK_LINK,
  FOOTBALL_INSTAGRAM_LINK,
  FOOTBALL_TWITTER_LINK,
  WHITEPAPER_LINK,
  ZENDESK_LINK,
} from '#constant';
import { useFlag } from '#technical/feature-flags';
import { useLanguage } from '#technical/localization';
import {
  BasketballIcon,
  CustomIcon,
  Icon,
  SoccerIcon,
  SVGIcon,
} from '#ui/components';

import { Tournaments } from '../../../app/navigation/Header/assets';
import { IHeaderConfig } from '../../../app/navigation/Header/types';

export const useHeaderConfig = (
  sport: SoccerData.Sport
): Omit<IHeaderConfig, 'rewards'> => {
  const tournamentsEnabled = useFlag(FeatureFlags.Features.TOURNAMENTS);
  const auctionsEnabled = useFlag(FeatureFlags.Features.AUCTIONS);
  const { search } = useLocation();
  const { currentLang } = useLanguage();
  const gameweek = new URLSearchParams(search).get(GAME_WEEK_SEARCH_PARAM);

  const sports: IHeaderConfig['sports'] = [
    {
      name: t('SPORT_SOCCER'),
      type: SoccerData.Sport.SOCCER,
      leagueLink: {
        icon: SoccerData.BrandConfig.seasonal ? (
          <CustomIcon name="Season" />
        ) : (
          <SoccerIcon name="Soccer" />
        ),
        label: t('GAME_SELECTION_LEAGUES'),
        url: buildRoutePath(
          'LEAGUES',
          {
            sport: SoccerData.Sport.SOCCER,
          },
          {
            gameweek,
          }
        ),
      },
      tournamentLink: tournamentsEnabled
        ? {
            icon: <SVGIcon source={Tournaments} size="M" />,
            label: t('GAME_SELECTION_TOURNAMENTS'),
            url: buildRoutePath(
              'TOURNAMENTS',
              {
                sport: SoccerData.Sport.SOCCER,
              },
              {
                gameweek,
              }
            ),
          }
        : undefined,
      links: [
        {
          label: t('HEADER_MARKETPLACE'),
          url: buildRoutePath('MARKETPLACE', {
            sport,
          }),
        },
        auctionsEnabled
          ? {
              label: t('HEADER_AUCTIONS'),
              url: buildRoutePath('AUCTIONS', {
                sport,
              }),
            }
          : null,
        {
          label: t('HEADER_PACKS'),
          url: buildRoutePath('PACK_STORE', {
            sport: SoccerData.Sport.SOCCER,
          }),
        },
        {
          label: t('HEADER_COLLECTION'),
          url: buildRoutePath('COLLECTION', {
            sport: SoccerData.Sport.SOCCER,
          }),
        },
        {
          label: t('REFERRAL_PROGRAM_TOP_NAVIGATION_LINK'),
          url: buildRoutePath('REFERRAL_PROGRAM', {
            sport: SoccerData.Sport.SOCCER,
          }),
        },
        {
          label: t('HEADER_HOW_TO_PLAY'),
          url: Brand.switchBrand({
            UC: `${WHITEPAPER_LINK}/the-game/getting-started`,
            LFP:
              currentLang === LANG.FR
                ? `${WHITEPAPER_LINK}/football/commencer-a-jouer`
                : `${WHITEPAPER_LINK}/v/coach-ligue-1-english/football/getting-started`,
          }),
          isExternal: true,
        },
      ].filter(exists),
      socialLinks: [
        {
          key: 'Twitter',
          label: 'Twitter Link',
          url: FOOTBALL_TWITTER_LINK,
          isExternal: true,
        },
        {
          key: 'Facebook',
          label: 'Facebook Link',
          url: FOOTBALL_FACEBOOK_LINK,
          isExternal: true,
        },
        {
          key: 'Instagram',
          label: 'Instagram Link',
          url: FOOTBALL_INSTAGRAM_LINK,
          isExternal: true,
        },
      ],
    },
    {
      name: t('SPORT_BASKETBALL'),
      type: SoccerData.Sport.BASKETBALL,
      leagueLink: {
        icon: SoccerData.BrandConfig.seasonal ? (
          <CustomIcon name="Season" />
        ) : (
          <BasketballIcon name="MatchPlayed" />
        ),
        label: t('GAME_SELECTION_LEAGUES'),
        url: buildRoutePath('LEAGUES', {
          sport: SoccerData.Sport.BASKETBALL,
        }),
      },
      tournamentLink: tournamentsEnabled
        ? {
            icon: <SVGIcon source={Tournaments} size="M" />,
            label: t('GAME_SELECTION_TOURNAMENTS'),
            url: buildRoutePath('TOURNAMENTS', {
              sport: SoccerData.Sport.BASKETBALL,
            }),
          }
        : undefined,
      links: [
        {
          label: t('HEADER_MARKETPLACE'),
          url: buildRoutePath('MARKETPLACE', {
            sport,
          }),
        },
        auctionsEnabled
          ? {
              label: t('HEADER_AUCTIONS'),
              url: buildRoutePath('AUCTIONS', {
                sport,
              }),
            }
          : null,
        {
          label: t('HEADER_PACKS'),
          url: buildRoutePath('PACK_STORE', {
            sport: SoccerData.Sport.BASKETBALL,
          }),
        },
        {
          label: t('HEADER_COLLECTION'),
          url: buildRoutePath('COLLECTION', {
            sport: SoccerData.Sport.BASKETBALL,
          }),
        },
        {
          label: t('REFERRAL_PROGRAM_TOP_NAVIGATION_LINK'),
          url: buildRoutePath('REFERRAL_PROGRAM', {
            sport: SoccerData.Sport.BASKETBALL,
          }),
        },
        {
          label: t('HEADER_HOW_TO_PLAY'),
          url: Brand.switchBrand({
            UC: `${WHITEPAPER_LINK}/basketball/getting-started`,
            LFP: WHITEPAPER_LINK,
          }),
          isExternal: true,
        },
      ].filter(exists),
      socialLinks: [
        {
          key: 'Twitter',
          label: 'Twitter Link',
          url: BASKETBALL_TWITTER_LINK,
          isExternal: true,
        },
        {
          key: 'Instagram',
          label: 'Instagram Link',
          url: BASKETBALL_INSTAGRAM_LINK,
          isExternal: true,
        },
      ],
    },
  ];

  return {
    sports: sports.filter((sportConfig) =>
      SoccerData.isActive(sportConfig.type)
    ),
    rewardsPageUrl: buildRoutePath('REWARDS', {}),
    profileMenuLinks: [
      [
        {
          label: t('HEADER_DROPDOWN_ACCOUNT'),
          url: buildRoutePath('ACCOUNT', {}),
          icon: <Icon name="settings__outlined" size="L" />,
        },
        {
          label: t('HEADER_DROPDOWN_REWARDS'),
          url: buildRoutePath('REWARDS', {}),
          icon: <Icon name="redeem__filled" size="L" />,
        },
        {
          label: t('HEADER_DROPDOWN_WALLET'),
          url: buildRoutePath('WALLET', {}),
          icon: <Icon name="wallet__filled" size="L" />,
        },
      ],
      [
        {
          label: t('HEADER_DROPDOWN_KNOWLEDGE_BASE'),
          url: ZENDESK_LINK,
          icon: <Icon name="help__outlined" size="L" />,
          isExternal: true,
        },
        {
          label: t('HEADER_DROPDOWN_CONTACT'),
          url: buildRoutePath('CONTACT', {}),
          icon: <Icon name="support__filled" size="L" />,
        },
      ],
      [
        {
          label: t('HEADER_DROPDOWN_INVITE_FRIENDS'),
          url: buildRoutePath('REFERRAL_PROGRAM', { sport }),
          icon: <Icon name="supervised_user_circle__outlined" size="L" />,
        },
      ],
    ],
  };
};
