import { omit } from 'lodash-es';
import * as React from 'react';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { Wallet } from 'ultimate-league-common';
import { useLocalStorage } from 'usehooks-ts';

import { useUserAccount } from '#common/store';
import { PersistKey } from '#technical/persist/persist';
import { t } from '#technical/translate';
import { useLazyRef } from '#technical/useLazyRef';
import { showSnackbar } from '#ui/components';

import { WalletTransactionsContext } from './hooks';
import { IWalletTransactions } from './types';

function handleCreateSale(transaction: Wallet.IMarketplaceWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    return showSnackbar(
      t('MARKETPLACE_CREATE_SALE_SUCCESS').replace(
        '{{nft}}',
        String(transaction.nft)
      ),
      { severity: 'success' }
    );
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t(
      transaction.errorCode
        ? `MARKETPLACE_CREATE_SALE_FAILED_${transaction.errorCode}`
        : 'MARKETPLACE_CREATE_SALE_FAILED'
    )
      .replace('{{nft}}', String(transaction.nft))
      .replace('{{error}}', transaction.error || '');

    return showSnackbar(toastContent, {
      severity: 'error',
    });
  }

  return null;
}

function handleUpdateSale(transaction: Wallet.IMarketplaceWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    return showSnackbar(
      t('MARKETPLACE_UPDATE_SALE_SUCCESS').replace(
        '{{nft}}',
        String(transaction.nft)
      ),
      { severity: 'success' }
    );
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t(
      transaction.errorCode
        ? `MARKETPLACE_UPDATE_SALE_FAILED_${transaction.errorCode}`
        : 'MARKETPLACE_UPDATE_SALE_FAILED'
    )
      .replace('{{nft}}', String(transaction.nft))
      .replace('{{error}}', transaction.error || '');

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleDestroySale(transaction: Wallet.IMarketplaceWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    return showSnackbar(
      t('MARKETPLACE_DESTROY_SALE_SUCCESS').replace(
        '{{nft}}',
        String(transaction.nft)
      ),
      { severity: 'success' }
    );
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t(
      transaction.errorCode
        ? `MARKETPLACE_DESTROY_SALE_FAILED_${transaction.errorCode}`
        : 'MARKETPLACE_DESTROY_SALE_FAILED'
    )
      .replace('{{nft}}', String(transaction.nft))
      .replace('{{error}}', transaction.error || '');

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleAcceptSale(transaction: Wallet.IMarketplaceWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    return showSnackbar(
      t('MARKETPLACE_ACCEPT_SALE_SUCCESS').replace(
        '{{nft}}',
        String(transaction.nft)
      ),
      { severity: 'success' }
    );
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t(
      transaction.errorCode
        ? `MARKETPLACE_ACCEPT_SALE_FAILED_${transaction.errorCode}`
        : 'MARKETPLACE_ACCEPT_SALE_FAILED'
    )
      .replace('{{nft}}', String(transaction.nft))
      .replace('{{error}}', transaction.error || '');

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleWithdraw(transaction: Wallet.IWithdrawWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    showSnackbar(t('WALLET_WITHDRAW_SUCCESS'), {
      severity: 'success',
    });
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t('WALLET_WITHDRAW_FAILED').replace(
      '{{error}}',
      transaction.error || ''
    );

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleDistribute(transaction: Wallet.IDistributeWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    showSnackbar(t('WALLET_DISTRIBUTE_SUCCESS'), {
      severity: 'success',
    });
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t('WALLET_DISTRIBUTE_FAILED').replace(
      '{{error}}',
      transaction.error || ''
    );

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleTransfer(transaction: Wallet.INftTransferWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    showSnackbar(t('WALLET_NFT-TRANSFER_SUCCESS'), {
      severity: 'success',
    });
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t('WALLET_NFT-TRANSFER_FAILED').replace(
      '{{error}}',
      transaction.error || ''
    );

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

function handleAuctionBid(transaction: Wallet.IAuctionBidWalletTransaction) {
  if (transaction.status === Wallet.TransactionStatus.SUCCESS) {
    showSnackbar(t('AUCTIONS_BID_SUCCESS'), {
      severity: 'success',
    });
  }

  if (transaction.status === Wallet.TransactionStatus.FAILED) {
    const toastContent = t('AUCTIONS_BID_FAILED').replace(
      '{{error}}',
      transaction.error || ''
    );

    return showSnackbar(toastContent, { severity: 'error' });
  }

  return null;
}

export function handleTransactionChange(transaction: Wallet.WalletTransaction) {
  if (Wallet.isMarketplaceWalletTransaction(transaction)) {
    switch (transaction.transactionType) {
      case Wallet.TransactionType.CREATE_SALE:
        return handleCreateSale(transaction);
      case Wallet.TransactionType.ACCEPT_SALE:
        return handleAcceptSale(transaction);
      case Wallet.TransactionType.UPDATE_SALE:
        return handleUpdateSale(transaction);
      case Wallet.TransactionType.DESTROY_SALE:
        return handleDestroySale(transaction);
      default:
        throw new Error('Invalid walletTransaction type');
    }
  }

  if (Wallet.isWithdrawWalletTransaction(transaction)) {
    return handleWithdraw(transaction);
  }

  if (Wallet.isDistributeWalletTransaction(transaction)) {
    return handleDistribute(transaction);
  }

  if (Wallet.isNftTransferWalletTransaction(transaction)) {
    return handleTransfer(transaction);
  }

  if (Wallet.isAuctionBidWalletTransaction(transaction)) {
    return handleAuctionBid(transaction);
  }

  throw new Error(`Invalid walletTransaction: ${JSON.stringify(transaction)}`);
}

export const WalletTransactionsContainer = ({
  children,
}: PropsWithChildren<{}>) => {
  const userAccount = useUserAccount();
  const walletTransactions = useMemo(
    () => userAccount.account?.wallet?.transactions || [],
    [userAccount.account?.wallet?.transactions]
  );
  const [transactions, setTransactions] = useLocalStorage<
    Record<Wallet.WalletTransaction['id'], Wallet.WalletTransaction | undefined>
  >(PersistKey.WALLET_TRANSACTIONS, {});
  const lazyRefTransactions = useLazyRef(transactions);

  const addTransaction = useCallback<IWalletTransactions['addTransaction']>(
    (transaction) => {
      setTransactions((oldTransactions) => ({
        ...oldTransactions,
        [transaction.id]: transaction,
      }));
    },
    [setTransactions]
  );

  useEffect(() => {
    const updatedTransactions = walletTransactions.filter(({ id, status }) => {
      const localTransaction = lazyRefTransactions.current[id];
      return (
        localTransaction &&
        status !== Wallet.TransactionStatus.CREATED &&
        localTransaction.status !== status
      );
    });

    if (updatedTransactions.length === 0) {
      return;
    }

    updatedTransactions.forEach(handleTransactionChange);

    setTransactions((oldTransactions) =>
      updatedTransactions.reduce(
        (acc, transaction) => omit(acc, transaction.id),
        oldTransactions
      )
    );
  }, [lazyRefTransactions, setTransactions, walletTransactions]);

  return (
    <WalletTransactionsContext.Provider
      value={{
        addTransaction,
        transactions: walletTransactions,
      }}
    >
      {children}
    </WalletTransactionsContext.Provider>
  );
};
