import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { SoccerData } from 'ultimate-league-common';
import { useLocalStorage } from 'usehooks-ts';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';
import { PersistKey } from '#technical/persist/persist';

interface ISportContext {
  sport: SoccerData.Sport;
  switchSport(current: SoccerData.Sport): void;
  positions: Array<SoccerData.Athlete.AthletePosition>;
  isPosition(
    position?: string | null
  ): position is SoccerData.Athlete.AthletePosition;
}

const SportContext = createContext<ISportContext>({
  sport: SoccerData.Sport.SOCCER,
  switchSport() {
    throw new Error('No Sport provider available');
  },
  positions: SoccerData.Athlete.SOCCER_POSITIONS,
  isPosition(position): position is SoccerData.Athlete.AthletePosition {
    throw new Error('No Sport provider available');
  },
});

export interface ISportProviderProps {}
export function SportProvider({
  children,
}: PropsWithChildren<ISportProviderProps>) {
  const [{ current }, setLocalSport] = useLocalStorage<{
    current: SoccerData.Sport;
  }>(PersistKey.SPORT, {
    current: SoccerData.Sport.SOCCER,
  });

  const switchSport = useCallback<ISportContext['switchSport']>(
    (sport) => setLocalSport({ current: sport }),
    [setLocalSport]
  );

  const positions = useMemo(() => {
    switch (current) {
      case SoccerData.Sport.SOCCER:
        return SoccerData.Athlete.SOCCER_POSITIONS;
      case SoccerData.Sport.BASKETBALL:
        return SoccerData.Athlete.BASKETBALL_POSITIONS;
      default:
        throw catchMissingSwitchCase(current);
    }
  }, [current]);

  const isPosition = useMemo(() => {
    switch (current) {
      case SoccerData.Sport.SOCCER:
        return SoccerData.Athlete.isSoccerPosition;
      case SoccerData.Sport.BASKETBALL:
        return SoccerData.Athlete.isBasketballPosition;
      default:
        throw catchMissingSwitchCase(current);
    }
  }, [current]);

  return (
    <SportContext.Provider
      value={{
        sport: current,
        switchSport,
        positions,
        isPosition,
      }}
    >
      {children}
    </SportContext.Provider>
  );
}

export const useCurrentSport = () => useContext(SportContext);

export const useSportVariable = <T extends any>(
  values: Record<SoccerData.Sport, T>
) => {
  const { sport } = useCurrentSport();
  return useMemo(() => values[sport], [sport]);
};
