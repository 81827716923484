"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bsc = void 0;
var buildBlockchain_1 = require("./buildBlockchain");
var isActive_1 = require("./isActive");
exports.Bsc = (0, isActive_1.isActive)('bsc')
    ? (0, buildBlockchain_1.buildBlockchain)({
        primary: process.env.BSC_PRIMARY_PROVIDER_URL,
        secondary: process.env.BSC_SECONDARY_PROVIDER_URL,
    }, {
        ULTIMATE_LEAGUE_NFT_ADDRESS: process.env.BSC_ULTIMATE_LEAGUE_NFT_ADDRESS,
        ULTIMATE_LEAGUE_MARKETPLACE_ADDRESS: process.env.BSC_ULTIMATE_LEAGUE_MARKETPLACE_ADDRESS,
        UNAGI_TOKEN_ADDRESS: process.env.BSC_UNAGI_TOKEN_ADDRESS,
        DISTRIBUTION_MANAGER_ADDRESS: process.env.BSC_DISTRIBUTION_MANAGER_ADDRESS,
        TOKEN_TRANSFER_RELAY_ADDRESS: process.env.BSC_TOKEN_TRANSFER_RELAY_ADDRESS,
        LAST_SEEN_ADDRESS: process.env.BSC_LAST_SEEN_ADDRESS,
    })
    : undefined;
