import * as React from 'react';
import 'simplebar-react/dist/simplebar.min.css';
import { createGlobalStyle } from 'styled-components';
import 'tippy.js/dist/tippy.css';

import { CookiePolicy } from '#common/misc';
import { RootProvider } from '#common/store';
import { WalletTransactionsContainer } from '#common/transaction/WalletTransactionsContainer';
import { UserAccountContainer } from '#common/user';
import { FlagProvider } from '#technical/feature-flags/FlagProvider';
import { AuthorizationContainer } from '#technical/network/authorization';

const RootStyles = createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
  }

  .grecaptcha-badge {
    display: none !important;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Root: React.FC = (
  { children } // eslint-disable-line react/prop-types
) => (
  <React.StrictMode>
    <RootStyles />
    <AuthorizationContainer>
      <UserAccountContainer>
        <WalletTransactionsContainer>
          <FlagProvider>
            <RootProvider>
              <CookiePolicy.CookieProvider>
                {children}
              </CookiePolicy.CookieProvider>
            </RootProvider>
          </FlagProvider>
        </WalletTransactionsContainer>
      </UserAccountContainer>
    </AuthorizationContainer>
  </React.StrictMode>
);
