import { useCallback, useState } from 'react';

import { useLazyRef } from '#technical/useLazyRef';

import { ABORT_ERROR_NAME } from './api';
import { IAuthorization, useAuthorization } from './authorization';

interface IFetchRecord<D> {
  data: Promise<D>;
  finished?: boolean;
  abortController: AbortController;
  time: number;
}

const fetchRecords: Record<string, IFetchRecord<unknown>> = {};

interface IFetchEndpoint<P, D> {
  (
    params: P,
    options: { auth: IAuthorization; signal: AbortSignal }
  ): Promise<D>;
}

interface IOptions<P, D> {
  cacheKey: string | ((params: P) => string);
  cacheMS?: number;
  handleData?: (data: D, params: P) => void;
  initialLoading?: boolean;
}

/**
 * useMemoizedFetch allow to wrap a fetch method and memoize data from the server.
 *
 * ## Usage
 * ```typescript
 *
 * const [data, loading, fetchData] = useMemoizedFetch(
 *   async (params) => {
 *     const res = await fetchApi(`/my/endpoint/${params.id}?${params.query}`, params.body);
 *     return res.json();
 *   },
 *   {
 *     // cache key used for the memoization. Using JSON.stringify(params) is nice to keep data memoized for different params
 *     cacheKey: (params) => `my-endpoint-${JSON.stringify(params)}`,
 *
 *     // cache is valid for cacheMS milliseconds
 *     // Here, we want to keep the same result for 20 seconds (So it will memoize for 20 seconds max)
 *     cacheMS: 20_000
 *
 *     // Useful to sync with an external state. Prefer use of the data returned by the hook.
 *     handleData: mySetState
 *   }
 * );
 *
 * // You can call fetchData anywhere, it returns a cancel function
 * fetchData(myParams);
 *
 * // From a useEffect for example
 * useEffect(() => fetchData(myParams), [myParams]);
 * ```
 */
export function useMemoizedFetch<P, D>(
  fetchEndpoint: IFetchEndpoint<P, D>,
  { cacheKey, cacheMS = 2, handleData, initialLoading = false }: IOptions<P, D>
): [D | undefined, boolean, (params: P) => () => void] {
  const auth = useAuthorization();
  const [data, setData] = useState<D>();
  const refs = useLazyRef({
    fetch: fetchEndpoint,
    auth,
    handleData,
    cacheMS,
    cacheKey,
  });
  const [loading, setLoading] = useState(initialLoading);

  const fetchData = useCallback(
    (params: P) => {
      const computedCacheKey =
        typeof refs.current.cacheKey === 'function'
          ? refs.current.cacheKey(params)
          : refs.current.cacheKey;

      setLoading(true);
      const previousFetch = fetchRecords[computedCacheKey] as
        | IFetchRecord<D>
        | undefined;

      let currentFetchRecord: IFetchRecord<D> | undefined;

      const fetchJob = (() => {
        if (
          previousFetch &&
          !previousFetch.abortController.signal.aborted &&
          previousFetch.time + refs.current.cacheMS > Date.now()
        ) {
          return previousFetch.data;
        }

        previousFetch?.abortController.abort();
        const abortController = new AbortController();
        const sData = refs.current.fetch(params, {
          auth: refs.current.auth,
          signal: abortController.signal,
        });
        currentFetchRecord = {
          abortController,
          data: sData,
          time: Date.now(),
        };
        fetchRecords[computedCacheKey] = currentFetchRecord;
        return sData;
      })();

      (async () => {
        try {
          const sData = await fetchJob;
          if (currentFetchRecord) {
            currentFetchRecord.finished = true;
            currentFetchRecord.time = Date.now();
          }
          setData(sData);
          refs.current.handleData?.(sData, params);
          setLoading(false);
        } catch (e) {
          if ((e as Error).name !== ABORT_ERROR_NAME) {
            setLoading(false);
          }
        }
      })();

      return () => {
        if (currentFetchRecord && !currentFetchRecord.finished) {
          currentFetchRecord.abortController.abort();
        }
      };
    },
    [refs, setData]
  );

  return [data, loading, fetchData];
}
