import React from 'react';
import styled from 'styled-components';
import { Blockchain } from 'ultimate-league-common';

import { BIconProps, ICON_SIZES } from '../Icon';
import { ASSETS } from './assets';

const ImageIcon = styled.img<BIconProps>`
  display: inline-block;
  aspect-ratio: 1 / 1;
  width: ${({ size = 'M' }) => ICON_SIZES[size]}px;
`;

/* Main Component */

export interface ICurrencyIconProps extends BIconProps {
  coin: 'Gold' | Blockchain.Token.Token;
}

export function CurrencyIcon({ coin, ...baseProps }: ICurrencyIconProps) {
  return <ImageIcon src={ASSETS[coin]} {...baseProps} />;
}
