import MuiCheckbox, { checkboxClasses } from '@mui/material/Checkbox';
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';
import { mapKey } from '#technical/mapKey';

import { Icon } from '../../icons';

const getBackgroundColor = (
  theme: DefaultTheme,
  color: ICheckboxProps['color'] = 'default',
  disabled?: boolean
) => {
  if (disabled) return theme.color.primary['Light 20%'];

  switch (color) {
    case 'accent':
      return Brand.switchBrand({
        UC: theme.color.accent['50'],
        LFP: theme.color.primary['0'],
      });
    case 'error':
      return theme.color.error['50'];
    case 'default':
      return theme.color.primary['0'];
    default:
      throw catchMissingSwitchCase(color);
  }
};

/* Styled Components */

const StyledCheckbox = styled(MuiCheckbox)`
  &.${checkboxClasses.root} {
    padding: ${({ size = 'medium' }) =>
      mapKey(size, {
        small: 6,
        medium: 8,
      })}px;
  }
`;

const UncheckedIcon = styled(Icon).attrs({
  name: 'check_box_outline_blank__outlined',
})<{ color: ICheckboxProps['color']; disabled?: boolean }>`
  color: ${({ disabled, theme, color }) => {
    if (disabled) return theme.color.primary['Light 20%'];

    if (color === 'error') {
      return theme.color.error['80%'];
    }

    return theme.color.primary['Light 40%'];
  }};
  transition: color 300ms;
`;

const CheckedIcon = styled(Icon).attrs({
  name: 'check_box__filled',
})<{ color: ICheckboxProps['color']; disabled?: boolean }>`
  color: ${({ theme, color = 'default', disabled }) =>
    getBackgroundColor(theme, color, disabled)};
  transition: color 300ms;
`;

const IndeterminateIcon = styled(Icon).attrs({
  name: 'indeterminate_check_box__filled',
})<{ color: ICheckboxProps['color']; disabled?: boolean }>`
  color: ${({ theme, color = 'default', disabled }) =>
    getBackgroundColor(theme, color, disabled)};
  transition: color 300ms;
`;

/* Main Component */

export interface ICheckboxProps {
  checked?: boolean;
  onChange(checked: boolean): void;
  size?: 'M' | 'L';
  disabled?: boolean;
  indeterminate?: boolean;
  color?: 'default' | 'accent' | 'error';
  className?: string;
}

/**
 * Use `Checkbox` to allow the user to select one or more items from a set.
 */
export function Checkbox({
  checked,
  color = 'default',
  size = 'L',
  onChange,
  disabled,
  indeterminate,
  className,
}: ICheckboxProps) {
  return (
    <StyledCheckbox
      size={mapKey(size, {
        M: 'small',
        L: 'medium',
      })}
      checked={checked}
      disabled={disabled}
      indeterminate={indeterminate}
      icon={<UncheckedIcon color={color} size={size} disabled={disabled} />}
      checkedIcon={
        <CheckedIcon size={size} color={color} disabled={disabled} />
      }
      indeterminateIcon={
        <IndeterminateIcon color={color} size={size} disabled={disabled} />
      }
      onChange={(_event, nextValue) => onChange(nextValue)}
      className={className}
    />
  );
}
