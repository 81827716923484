"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REFERRAL_INVITEES_LIMIT = exports.ReferralInviteesFilter = exports.ReferralInviteesSort = void 0;
var ReferralInviteesSort;
(function (ReferralInviteesSort) {
    ReferralInviteesSort["JOINED_AT_ASC"] = "JOINED_AT_ASC";
    ReferralInviteesSort["JOINED_AT_DESC"] = "JOINED_AT_DESC";
    ReferralInviteesSort["KYC_ASC"] = "KYC_ASC";
    ReferralInviteesSort["KYC_DESC"] = "KYC_DESC";
    ReferralInviteesSort["PURCHASE_ASC"] = "PURCHASE_ASC";
    ReferralInviteesSort["PURCHASE_DESC"] = "PURCHASE_DESC";
})(ReferralInviteesSort || (exports.ReferralInviteesSort = ReferralInviteesSort = {}));
var ReferralInviteesFilter;
(function (ReferralInviteesFilter) {
    ReferralInviteesFilter["ALL"] = "ALL";
    ReferralInviteesFilter["REGISTERED_ONLY"] = "REGISTERED_ONLY";
    ReferralInviteesFilter["KYC"] = "KYC";
    ReferralInviteesFilter["PURCHASE"] = "PURCHASE";
})(ReferralInviteesFilter || (exports.ReferralInviteesFilter = ReferralInviteesFilter = {}));
exports.REFERRAL_INVITEES_LIMIT = 15;
