import { Blockchain } from 'ultimate-league-common';

import { createStore } from './createStore';

export const [
  nftMarketplaceSalesContext,
  NftMarketplaceSalesProvider,
  useNftMarketplaceSales,
] = createStore<Blockchain.Marketplace.ISale | null>((item) =>
  String(item?.nft)
);
