"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BidStatus = void 0;
var BidStatus;
(function (BidStatus) {
    BidStatus["PENDING"] = "PENDING";
    BidStatus["SUBMITTING"] = "SUBMITTING";
    BidStatus["RESERVED"] = "RESERVED";
    BidStatus["ERROR"] = "ERROR";
    BidStatus["CANCELLED"] = "CANCELLED";
})(BidStatus || (exports.BidStatus = BidStatus = {}));
