import { lazy } from 'react';

export type { IHeaderProps } from './Header';
export { useHeaderConfig } from '../../common/navigation/hooks/useHeaderConfig';

export const Header = lazy(() =>
  import('./Header').then((module) => ({ default: module.Header }))
);
export const Footer = lazy(() =>
  import('./Footer').then((module) => ({ default: module.Footer }))
);
