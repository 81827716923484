import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Pack } from 'ultimate-league-common';
import { useInterval } from 'usehooks-ts';

import { fetchOwnedPacks } from '#common/pack';
import { useCurrentSport } from '#common/sport';
import { useAuthorization } from '#technical/network/authorization';

import { CONTEXT_REFRESH_INTERVAL } from '../config';
import { IPacksCountByPackConfig } from '../types';

interface IOwnedPacksContext {
  refresh: () => Promise<void>;
  getOwnedPacksCount: (packConfigId: Pack.IPackConfig['id']) => number;
}

function willThrow(): any {
  throw new Error('Owned packs context not initialized');
}

const OwnedPacksContext = createContext<IOwnedPacksContext>({
  refresh: willThrow,
  getOwnedPacksCount: willThrow,
});

export const useOwnedPacksContext = () => useContext(OwnedPacksContext);

export const OwnedPacksProvider = ({ children }: PropsWithChildren<{}>) => {
  const { sport } = useCurrentSport();
  const auth = useAuthorization();
  const [ownedPacks, setOwnedPacks] = useState<
    IPacksCountByPackConfig | undefined
  >();

  const refresh = useCallback(async () => {
    setOwnedPacks(await fetchOwnedPacks({ sport }, auth));
  }, [auth, setOwnedPacks, sport]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useInterval(() => refresh(), CONTEXT_REFRESH_INTERVAL);

  const getOwnedPacksCount = useCallback(
    (packConfigId: Pack.IPackConfig['id']) => ownedPacks?.[packConfigId] || 0,
    [ownedPacks]
  );

  return (
    <OwnedPacksContext.Provider value={{ getOwnedPacksCount, refresh }}>
      {children}
    </OwnedPacksContext.Provider>
  );
};
