import BN from 'bn.js';
import * as React from 'react';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Blockchain, User } from 'ultimate-league-common';
import { useInterval, useLocalStorage } from 'usehooks-ts';

import { fetchUlcBalance } from '#common/blockchain';
import { IUserAccount, UserAccountContext } from '#common/store';
import { fetchMyAccount as fetchMyAccountService } from '#common/user';
import { analytics } from '#technical/amplitude';
import * as Logger from '#technical/logger';
import { useAuthorization } from '#technical/network/authorization';
import { PersistKey } from '#technical/persist/persist';
import * as GoogleAd from '#technical/tracking/googleAd';
import * as Wonderpush from '#technical/wonderpush';

const REFRESH_INTERVAL = 30000;

export const UserAccountContainer = ({ children }: PropsWithChildren<{}>) => {
  const authorization = useAuthorization();
  const isSigned = authorization.isSigned();
  const [userAccount, setUserAccount] = useLocalStorage<
    User.IUserAccount | undefined
  >(PersistKey.USER, undefined);

  const [balances, setBalances] = useState<IUserAccount['balances']>({});

  const fetchUnaBalance = useCallback(
    async (address: Blockchain.Address) => {
      const balance = await Blockchain.fetchUNABalance(address);

      setBalances((oldBalance) => ({
        ...oldBalance,
        [Blockchain.Token.Token.UNA]: new BN(balance),
      }));
    },
    [setBalances]
  );

  const fetchUlcBalanceData = useCallback(async () => {
    const balance = await fetchUlcBalance(authorization);

    setBalances((oldBalance) => ({
      ...oldBalance,
      [Blockchain.Token.Token.ULC]: new BN(balance),
    }));
  }, [setBalances, authorization]);

  const userWalletAddress = userAccount?.wallet?.address;
  const refreshTokensBalance = useCallback(async () => {
    if (userWalletAddress) {
      Promise.all([
        fetchUnaBalance(userWalletAddress),
        fetchUlcBalanceData(),
      ]).catch(() => {});
    }
  }, [fetchUnaBalance, fetchUlcBalanceData, userWalletAddress]);

  const refreshUserAccount = useCallback(async () => {
    const sUserAccount = await fetchMyAccountService(authorization);
    setUserAccount(sUserAccount);
    return sUserAccount;
  }, [setUserAccount, authorization]);

  useInterval(
    async () => {
      await refreshTokensBalance();
      await refreshUserAccount();
    },
    isSigned ? REFRESH_INTERVAL : null
  );

  const fetchMyAccount = useCallback(async () => {
    const sUserAccount = await refreshUserAccount();
    await refreshTokensBalance();

    Logger.setUser({
      id: sUserAccount.id,
      username: sUserAccount.username,
      role: sUserAccount.role,
    });
    analytics.configure({
      user: {
        id: sUserAccount.id,
        username: sUserAccount.username,
      },
    });
    GoogleAd.setUserData({ email: sUserAccount.email });
    Wonderpush.setUserId(sUserAccount.id);

    return sUserAccount;
  }, [refreshUserAccount, refreshTokensBalance]);

  useEffect(() => {
    if (isSigned) {
      fetchMyAccount();
    } else {
      setUserAccount(undefined);
      analytics.destroy();
      GoogleAd.setUserData(null);
      Wonderpush.unsetUserId();
    }
  }, [isSigned, fetchMyAccount, setUserAccount]);

  const value = useMemo<IUserAccount>(
    () => ({
      account: userAccount,
      refetch: fetchMyAccount,
      balances,
    }),
    [fetchMyAccount, userAccount, balances]
  );

  return (
    <UserAccountContext.Provider value={value}>
      {children}
    </UserAccountContext.Provider>
  );
};
