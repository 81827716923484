/* eslint-disable camelcase */
import { Blockchain, Pack, Payment } from 'ultimate-league-common';

import { CookiePolicy } from '#common/misc';
import { PaymentProvider } from '#common/payment';

type StandardTikTokEvent =
  | 'CompleteRegistration'
  | 'CompletePayment'
  | 'SubmitForm'
  | 'ViewContent'
  | 'Contact';

interface IParams {
  content_type: 'product' | 'product_group';
  content_category: 'pack' | 'marketplace_buy';
  content_id: string;
  quantity: number;
  /**
   * "price" is the price for a single item, and "value" is the total price of the order.
   * For example, if you have 2 items each sold for $10, the "price" parameter would pass "10" and the "value" parameter would pass "20."
   */
  price: number;
  value: number;
  currency: 'USD' | 'EUR';
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    ttq: {
      track(event: StandardTikTokEvent, params?: IParams): void;
      page(): void;
      on(): void;
      off(): void;
    };
  }
}

function ttq(): Window['ttq'] {
  if (
    process.env.NODE_ENV === 'production' &&
    CookiePolicy.getCookieConsents().adPerformances &&
    window.ttq
  ) {
    return window.ttq;
  }

  return {
    track: () => {},
    page: () => {},
    on: () => {},
    off: () => {},
  };
}

export function on() {
  return ttq().on();
}

export function off() {
  return ttq().off();
}

export function pageView() {
  return ttq()?.page();
}

export function completeRegistration() {
  return ttq().track('CompleteRegistration');
}

export function completeFTUE() {
  return ttq().track('SubmitForm');
}

export function registerTeam() {
  return ttq().track('Contact');
}

export function purchasePack(
  params: {
    price: number;
    quantity: number;
    provider: PaymentProvider;
    token?: Blockchain.Token.Token;
  },
  event: { packId: Pack.IPack['id'] }
) {
  const divide = (() => {
    if (params.provider === PaymentProvider.TOKEN) {
      return params.token === Blockchain.Token.Token.UNA ? 4 : 100;
    }

    return 1;
  })();

  return ttq().track('CompletePayment', {
    content_type: 'product',
    content_category: 'pack',
    content_id: event.packId,
    quantity: params.quantity,
    price: params.price,
    value: (params.price * params.quantity) / divide,
    currency: Payment.ActiveCurrency,
  });
}

export function purchaseMarketplace(
  params: {
    price: number;
    provider: PaymentProvider;
    percentFees: number;
  },
  event: { nftId: Blockchain.NFTCard.NftId }
) {
  const fees = (params.price * params.percentFees) / 100;
  const spent = params.provider !== PaymentProvider.TOKEN ? params.price : 0;
  const value = spent + fees / 4;

  return ttq().track('CompletePayment', {
    content_type: 'product',
    content_category: 'marketplace_buy',
    content_id: String(event.nftId),
    quantity: 1,
    price: params.price,
    value,
    currency: Payment.ActiveCurrency,
  });
}
