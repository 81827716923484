"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuarterKeys = exports.POINTS_BY_ACTION = exports.SCORING_ACTIONS = exports.PlayType = void 0;
var PlayType;
(function (PlayType) {
    PlayType["MISSED_TWO_POINTER"] = "2FGA";
    PlayType["TWO_POINTER"] = "2FGM";
    PlayType["MISSED_THREE_POINTER"] = "3FGA";
    PlayType["THREE_POINTER"] = "3FGM";
    PlayType["SHOT_REJECTED"] = "AG";
    PlayType["ASSIST"] = "AS";
    PlayType["BEGIN_PERIOD"] = "BP";
    PlayType["FOUL"] = "CM";
    PlayType["TECHNICAL_FOUL"] = "CMT";
    PlayType["DEF_REBOUND"] = "D";
    PlayType["END_GAME"] = "EG";
    PlayType["END_PERIOD"] = "EP";
    PlayType["MISSED_FREE_THROW"] = "FTA";
    PlayType["FREE_THROW_IN"] = "FTM";
    PlayType["BLOCK"] = "FV";
    PlayType["IN"] = "IN";
    PlayType["JB"] = "JB";
    PlayType["OFF_REBOUND"] = "O";
    PlayType["OFFENSIVE_FOUL"] = "OF";
    PlayType["OUT"] = "OUT";
    PlayType["FOUL_DRAWN"] = "RV";
    PlayType["STEAL"] = "ST";
    PlayType["TURNOVER"] = "TO";
    PlayType["TIME_OUT"] = "TOUT";
    PlayType["TV_TIME_OUT"] = "TOUT_TV";
    PlayType["TPOFF"] = "TPOFF";
})(PlayType || (exports.PlayType = PlayType = {}));
exports.SCORING_ACTIONS = [
    PlayType.FREE_THROW_IN,
    PlayType.TWO_POINTER,
    PlayType.THREE_POINTER,
];
exports.POINTS_BY_ACTION = (_a = {},
    _a[PlayType.FREE_THROW_IN] = 1,
    _a[PlayType.TWO_POINTER] = 2,
    _a[PlayType.THREE_POINTER] = 3,
    _a);
var QuarterKeys;
(function (QuarterKeys) {
    QuarterKeys["FirstQuarter"] = "FirstQuarter";
    QuarterKeys["SecondQuarter"] = "SecondQuarter";
    QuarterKeys["ThirdQuarter"] = "ThirdQuarter";
    QuarterKeys["ForthQuarter"] = "ForthQuarter";
    QuarterKeys["ExtraTime"] = "ExtraTime";
})(QuarterKeys || (exports.QuarterKeys = QuarterKeys = {}));
