import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { buildRoutePath, useSportRoute } from '#common/routing';

/**
 * Redirect logged in users inside the game based on the sport
 */
export function GameRoute() {
  const { search } = useLocation();
  const { sport } = useSportRoute();

  return (
    <Redirect
      to={{
        pathname: buildRoutePath('LEAGUES', { sport }),
        search,
      }}
    />
  );
}
