import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Brand, FTUE, Pack } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';

import { PackContentPreview } from '#common/pack';
import { WHITEPAPER_LINK } from '#constant';
import { derivate } from '#technical/hooks';
import { t } from '#technical/translate';
import {
  Button,
  Chip,
  Icon,
  IconButton,
  Link,
  Paragraph,
  Progress,
} from '#ui/components';

import { getObjectiveDescription, getObjectiveTitle } from './utils';

/* Utility */

/**
 * This merge is very tight to objectives loot tables.
 * We enforce that there is only 1 kind of loot
 * And we don't care about multiple drops
 */
function mergeLoots(loots: Pack.LootTable.ILoot[]): Pack.LootTable.ILoot {
  const firstDrop = loots[0]?.drops[0];
  if (!firstDrop) {
    return {
      drops: [],
    };
  }
  const relevantDrops = loots
    .map((loot) => loot.drops[0])
    .filter((drop) => drop?.dropType === firstDrop.dropType);
  const quantity = relevantDrops.reduce((acc, drop) => acc + drop.quantity, 0);

  if (firstDrop.dropType === Pack.LootTable.DropType.NFT_CARD) {
    return {
      drops: [
        {
          dropType: firstDrop.dropType,
          weight: 1,
          quantity,
          filter: firstDrop.filter,
        },
      ],
    };
  }

  return {
    drops: [
      {
        dropType: firstDrop.dropType,
        weight: 1,
        quantity,
      },
    ],
  };
}

/* Styled Components */

const Container = styled.div`
  position: fixed;
  z-index: 1050;
  display: flex;
  align-items: flex-end;
  bottom: ${({ theme }) => theme.spacing(16)};
  right: ${({ theme }) =>
    Brand.switchBrand({ UC: theme.spacing(80), LFP: theme.spacing(8) })};
`;

const Panel = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 0;
  bottom: 71px;
  width: 400px;
  max-width: calc(100vw - 2 * ${({ theme }) => theme.spacing(32)});
  overflow: auto;
  max-height: 90vh;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.primary['95']};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: all 400ms;
  transform: translateY(
    ${({ isOpen }) => (isOpen ? 'calc(0% + 72px)' : 'calc(100% + 100px)')}
  );

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  :hover {
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.primary['Light 10%']};
    }
  }
`;

const CollapseButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(8)};
  right: ${({ theme }) => theme.spacing(8)};
`;

const Header = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(16)};
  padding: ${({ theme }) => theme.spacing(32)};
`;

const ProgressContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const List = styled.ul`
  display: grid;
  gap: 1px;
  background-color: ${({ theme }) => theme.color.primary['90']};
`;

const Objective = styled.li`
  display: grid;
  gap: ${({ theme }) => theme.spacing(24)};
  padding: ${({ theme }) => theme.spacing(24)};

  color: ${({ theme }) => theme.color.primary['10']};
  background-color: ${({ theme }) => theme.color.primary['95']};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        color: ${({ theme }) => theme.color.primary['0']};
        background-color: ${({ theme }) => theme.color.primary['85']};
      }
      &:active {
        color: ${({ theme }) => theme.color.primary['0']};
        background-color: ${({ theme }) => theme.color.primary['80']};
      }
    `}
`;

const ObjectiveContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
`;

const ObjectiveInfo = styled.div`
  display: grid;
  gap: 4px;
`;

const ObjectiveText = styled(Paragraph)<{ stroked: boolean }>`
  ${({ stroked, theme }) =>
    stroked &&
    css`
      color: ${theme.color.primary['60']};
      text-decoration: line-through;
    `}
`;

const ObjectiveIndicator = styled.div<{ filled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.primary['Light 20%']};

  ${({ filled, theme }) =>
    filled &&
    css`
      color: ${theme.color.primary['100']};
      background-color: ${theme.color.primary['0']};
      border-color: ${theme.color.primary['0']};
    `}
`;

/* Main Component */

type IExtendedFTUEObjective = FTUE.IFTUEObjectives & {
  reward: Pick<Pack.IPackConfig, 'displayName' | 'visuals' | 'id' | 'loots'>;
};

export interface IObjectivesProps {
  objectives: Array<IExtendedFTUEObjective> | undefined;
  onClaimObjective(objective: Required<IExtendedFTUEObjective>): void;
  onRedirectToObjective(objective: FTUE.Objectives): void;
}

export function Objectives({
  objectives,
  onClaimObjective,
  onRedirectToObjective,
}: IObjectivesProps) {
  const {
    value: isExpanded,
    setTrue: expand,
    setFalse: collapse,
  } = useBoolean(false);

  const openObjectivesCount = objectives?.filter(
    ({ progress }) => progress !== FTUE.Progress.COMPLETED
  ).length;

  const progressInPercent = derivate(() => {
    const totalObjectives = objectives?.length;
    if (!totalObjectives) return 0;
    if (!openObjectivesCount) return 0;

    const completedObjectives = totalObjectives - openObjectivesCount;
    return Math.round((completedObjectives / totalObjectives) * 100);
  });

  const handleObjectiveClick = useCallback(
    ({ objective, progress }: FTUE.IFTUEObjectives) => {
      if (progress !== FTUE.Progress.INITIAL) return undefined;

      return () => {
        collapse();
        onRedirectToObjective(objective);
      };
    },
    [collapse, onRedirectToObjective]
  );

  const handleObjectiveClaim = useCallback(
    (objective: Required<IExtendedFTUEObjective>) => {
      collapse();
      onClaimObjective(objective);
    },
    [collapse, onClaimObjective]
  );

  return (
    <Container>
      {objectives && (
        <Button
          label={t('FTUE-OBJECTIVES-BUTTON')}
          variant="filled"
          trailingAdornment={
            <Chip
              label={openObjectivesCount}
              variant="filled"
              color="dark"
              size="S"
            />
          }
          onClick={expand}
        />
      )}

      <Panel isOpen={isExpanded}>
        <CollapseButton
          icon="keyboard_arrow_down__filled"
          variant="text"
          color="subtle"
          onClick={collapse}
        />
        <Header>
          <Paragraph variant="XXL" bold>
            {t('FTUE-OBJECTIVES-TITLE')}
          </Paragraph>
          <Paragraph variant="M" $textColor={({ primary }) => primary['20']}>
            {t('FTUE-OBJECTIVES-HELP', {
              link: (
                <Link
                  href={WHITEPAPER_LINK}
                  target="_blank"
                  textColor={({ primary }) => primary['0']}
                >
                  {t('FTUE-OBJECTIVES-LINK')}
                </Link>
              ),
            })}
          </Paragraph>
          <ProgressContainer>
            <Progress
              variant="linear"
              size="L"
              color="accent"
              value={progressInPercent}
            />
            <Paragraph variant="S" bold>
              {progressInPercent}%
            </Paragraph>
          </ProgressContainer>
        </Header>
        <List>
          {objectives?.map(({ objective, progress, reward }) => (
            <Objective
              key={objective}
              onClick={handleObjectiveClick({ objective, progress })}
            >
              <ObjectiveContent>
                <ObjectiveIndicator
                  filled={progress === FTUE.Progress.COMPLETED}
                >
                  {progress === FTUE.Progress.COMPLETED && (
                    <Icon name="check__outlined" size="L" />
                  )}
                </ObjectiveIndicator>
                <ObjectiveInfo>
                  <ObjectiveText
                    stroked={progress === FTUE.Progress.COMPLETED}
                    variant="L"
                    bold
                  >
                    {getObjectiveTitle(objective)}
                  </ObjectiveText>
                  <ObjectiveText
                    stroked={progress === FTUE.Progress.COMPLETED}
                    variant="S"
                    multiline
                    $textColor={({ primary }) => primary['40']}
                  >
                    {getObjectiveDescription(objective)}
                  </ObjectiveText>
                </ObjectiveInfo>
                <PackContentPreview
                  loot={mergeLoots(reward.loots)}
                  variant="L"
                  bold
                  $textColor={({ primary }) =>
                    progress === FTUE.Progress.COMPLETED
                      ? primary['60']
                      : undefined
                  }
                />
              </ObjectiveContent>
              {progress === FTUE.Progress.PENDING && reward && (
                <Button
                  label={t('FTUE-OBJECTIVES-CLAIM_REWARD')}
                  variant="filled"
                  color="accent"
                  size="S"
                  onClick={() =>
                    handleObjectiveClaim({ objective, progress, reward })
                  }
                />
              )}
            </Objective>
          ))}
        </List>
      </Panel>
    </Container>
  );
}
