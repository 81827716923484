"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandConfig = void 0;
var brand_1 = require("../brand");
var type_1 = require("./NFT-card/type");
exports.BrandConfig = (0, brand_1.switchBrand)({
    UC: {
        activatedBlockchains: ['polygon', 'bsc', 'base'],
        distributeBlockchain: 'bsc',
        mintBlockchain: 'bsc',
        paymentPriority: ['polygon', 'bsc', 'base'],
        activatedRarities: [
            type_1.RarityLevel.FUNGIBLE,
            type_1.RarityLevel.RARITY_3,
            type_1.RarityLevel.RARITY_2,
            type_1.RarityLevel.RARITY_1,
        ],
        enableConditionBonus: true,
        displayCardModifiers: true,
    },
    LFP: {
        activatedBlockchains: ['chiliz'],
        distributeBlockchain: 'chiliz',
        mintBlockchain: 'chiliz',
        paymentPriority: ['chiliz'],
        activatedRarities: [
            type_1.RarityLevel.FUNGIBLE,
            type_1.RarityLevel.RARITY_4,
            type_1.RarityLevel.RARITY_3,
            type_1.RarityLevel.RARITY_2,
            type_1.RarityLevel.RARITY_1,
        ],
        enableConditionBonus: false,
        displayCardModifiers: false,
    },
});
