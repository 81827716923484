import { Pack, SoccerData, Storage, User } from 'ultimate-league-common';

import { GOOGLE_PUBLIC_BUCKET_URL } from '#constant';

interface ITeamParams {
  storageType: Storage.StorageType.PUBLIC_TEAM_LOGO;
  id: SoccerData.Team.ITeam['id'];
  format: Storage.AssetFormat;
}

interface IAthleteParams {
  storageType: Storage.StorageType.PUBLIC_ATHLETE_MAIN;
  id: SoccerData.Athlete.IAthlete['id'];
  format: Storage.AssetFormat;
}

interface IPackParams {
  storageType: Storage.StorageType.PUBLIC_PACK_VISUAL;
  id: Pack.IPackConfig['id'];
  index: number;
}

interface IUserProfilePictureParams {
  storageType: Storage.StorageType.PUBLIC_USER_PROFILE_PICTURE;
  id: User.IMinimalUser['id'];
  profilePictureVersion: User.IMinimalUser['profilePictureVersion'];
}

interface ICompetitionParams {
  storageType: Storage.StorageType.PUBLIC_COMPETITION_ASSET;
  id: SoccerData.Competition.ICompetition['id'];
  format: Storage.AssetFormat;
}

export type Params =
  | ITeamParams
  | IAthleteParams
  | IPackParams
  | ICompetitionParams
  | IUserProfilePictureParams;

export function getPublicURL(params: Params) {
  switch (params.storageType) {
    case Storage.StorageType.PUBLIC_TEAM_LOGO:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/team/${params.id}-${params.format}.png`;

    case Storage.StorageType.PUBLIC_ATHLETE_MAIN:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/athlete/${params.id}-main-${params.format}.png`;

    case Storage.StorageType.PUBLIC_USER_PROFILE_PICTURE:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/user/${
        params.id
      }/profile-picture.png?v=${params.profilePictureVersion || '0'}`;

    case Storage.StorageType.PUBLIC_PACK_VISUAL:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/pack/${params.id}/visuals/${params.index}.png`;

    case Storage.StorageType.PUBLIC_COMPETITION_ASSET:
      return `${GOOGLE_PUBLIC_BUCKET_URL}/competition/${params.id}-${params.format}.png`;

    default:
      throw new Error('Invalid storage type');
  }
}
