"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leagueUpload = exports.update = exports.create = void 0;
var soccer_data_1 = require("../../soccer-data");
var storage_1 = require("../../storage");
var technical_1 = require("../../technical");
var type_1 = require("./type");
exports.create = {
    name: { presence: { allowEmpty: false }, type: 'string' },
    description: { presence: { allowEmpty: false }, type: 'string' },
    banner: {
        format: technical_1.id.format,
    },
    icon: {
        format: technical_1.id.format,
    },
    scoringMethod: {
        presence: { allowEmpty: false },
        type: 'string',
        inclusion: (0, technical_1.enumToArray)(type_1.LeagueScoringMethod),
    },
    sport: soccer_data_1.SportParameter,
};
exports.update = {
    name: { presence: { allowEmpty: false }, type: 'string' },
    description: { presence: { allowEmpty: false }, type: 'string' },
};
exports.leagueUpload = {
    fileExtension: storage_1.requestUpload.fileExtension,
};
