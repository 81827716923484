"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReferralPurchaseRequirement = exports.REFERRAL_PURCHASE_REQUIREMENT = void 0;
var brand_1 = require("../../brand");
var sport_1 = require("../../soccer-data/sport");
// Values in cents (2000 for 20$)
exports.REFERRAL_PURCHASE_REQUIREMENT = (0, brand_1.switchBrand)({
    UC: (_a = {},
        _a[sport_1.Sport.SOCCER] = 4000,
        _a[sport_1.Sport.BASKETBALL] = 4000,
        _a),
    LFP: (_b = {},
        _b[sport_1.Sport.SOCCER] = 1999,
        _b[sport_1.Sport.BASKETBALL] = 1999,
        _b),
});
function getReferralPurchaseRequirement(sport) {
    return exports.REFERRAL_PURCHASE_REQUIREMENT[sport];
}
exports.getReferralPurchaseRequirement = getReferralPurchaseRequirement;
