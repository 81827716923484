import { useMediaQuery } from '@mui/material';

import { IBreakpoints, useTheme } from '#ui/theme';

export function useViewport(query: (breakpoints: IBreakpoints) => string) {
  const { breakpoints } = useTheme();

  return useMediaQuery(query(breakpoints), { noSsr: true });
}

export function useResponsiveValue<GValue>(
  query: (breakpoints: IBreakpoints) => string,
  trueValue: GValue,
  falseValue: GValue
) {
  const condition = useViewport(query);

  return condition ? trueValue : falseValue;
}
