import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Payment, t } from 'ultimate-league-common';
import { useBoolean } from 'usehooks-ts';

import {
  deletePaymentMethod,
  fetchAvailablePayments,
  patchPaymentIntent,
  setSaleOngoing,
} from '#common/payment';
import { IFee } from '#common/transaction/types';
import * as Logger from '#technical/logger';
import { useAuthorization } from '#technical/network/authorization';
import { useMemoizedFetch } from '#technical/network/useMemoizedFetch';
import { useLazyRef } from '#technical/useLazyRef';
import {
  Button,
  Dialog,
  Paragraph,
  Progress,
  Skeleton,
  Spacer,
  showSnackbar,
} from '#ui/components';

import { IPaymentDetailsProps } from '../PaymentDetails';
import { CardInput } from './CardInput';
import { Checkout } from './Checkout';

const CardPaymentContainer = styled.div``;

const SavedCardsContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(24)};
`;

const AddCard = styled(Button)`
  width: 100%;
`;

const LoadingSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const LoadingProgress = styled(Progress)`
  margin: 0 auto;
`;

const LoadingParagraph = styled(Paragraph)`
  white-space: nowrap;
`;

const LoadingTotal = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacing(32)};
  border-top: 1px solid ${({ theme }) => theme.color.primary['Light 10%']};
`;

const LoadingButton = styled(Button)`
  width: 100%;
`;

const Loading = () => (
  <CardPaymentContainer>
    <Spacer size={32} />
    <LoadingParagraph variant="L">
      {t('CARD-PAYMENT_PAY-WITH')}
    </LoadingParagraph>
    <LoadingSummary>
      <Spacer size={48} />
      <LoadingProgress variant="circular" size="XL" value={undefined} />
      <Spacer size={48} />
    </LoadingSummary>
    <LoadingTotal>
      <LoadingParagraph variant="L" $textColor={({ primary }) => primary['40']}>
        {t('CARD-PAYMENT_YOU-WILL-PAY')}
      </LoadingParagraph>
      <Skeleton variant="text" width="25%" height={19} alignment="right" />
    </LoadingTotal>
    <Spacer size={40} />
    <LoadingButton
      label={t('PAYMENT_PROCEED_WITH_CARD')}
      variant="filled"
      size="M"
      disabled
    />
  </CardPaymentContainer>
);

export interface ICardPaymentProps
  extends Pick<
    IPaymentDetailsProps,
    'onCreatePaymentIntent' | 'onCancelPaymentIntent'
  > {
  fiatPrice: number;
  currency: string;
  guaranteeUntil?: Date;
  fees?: IFee[];
  onPayment?: () => Promise<void>;
}

export const CardPayment = ({
  currency,
  fiatPrice,
  guaranteeUntil,
  fees,
  onCreatePaymentIntent,
  onCancelPaymentIntent,
  onPayment,
}: ICardPaymentProps) => {
  const auth = useAuthorization();
  const [paymentIntent, setPaymentIntent] = useState<Payment.IPaymentIntent>();
  const [selectedPayment, setPayment] = useState<string>();
  const [fetchPaymentIntent, setFetchPaymentIntent] = useState(false);
  const [cardToRemove, setCardToRemove] = useState<Payment.ICard>();

  const [payments, loadingPayments, mFetchAvailablePayments] = useMemoizedFetch<
    void,
    Payment.AvailablePayment[]
  >(
    async (_, { auth: mAuth }) => {
      const { payments: sPayments } = await fetchAvailablePayments(mAuth);
      return sPayments;
    },
    {
      cacheKey: 'available-payments',
    }
  );

  const {
    value: removalConfirmation,
    setTrue: showRemovalConfirmation,
    setFalse: hideRemovalConfirmation,
  } = useBoolean(false);

  const handlePaymentChange = useCallback(
    async (nPayment: string) => {
      setPayment(nPayment);

      setFetchPaymentIntent(true);

      try {
        if (paymentIntent?.secret?.intentId) {
          if (!nPayment) {
            setPaymentIntent((oldPaymentIntent) => ({
              ...oldPaymentIntent!,
              secret: {
                ...oldPaymentIntent!.secret,
                requires: 'card',
              },
            }));
          } else {
            const sSecret = await patchPaymentIntent(
              { intentId: paymentIntent.secret.intentId },
              {
                payment: nPayment,
              },
              auth
            );

            setPaymentIntent((oldPaymentIntent) => ({
              ...oldPaymentIntent!,
              secret: sSecret,
            }));
          }
        } else {
          setPaymentIntent(await onCreatePaymentIntent?.(nPayment || null));
        }
      } catch (e) {
        setPayment(undefined);
      } finally {
        setFetchPaymentIntent(false);
      }
    },
    [auth, onCreatePaymentIntent, paymentIntent]
  );

  useEffect(() => mFetchAvailablePayments(), [mFetchAvailablePayments]);

  const rPaymentIntent = useLazyRef(paymentIntent);
  const rCancelPayment = useLazyRef(onCancelPaymentIntent);
  const hasSendPayment = useRef(false);
  useEffect(
    () => () => {
      if (!hasSendPayment.current && rPaymentIntent.current?.secret.intentId) {
        rCancelPayment
          .current?.(rPaymentIntent.current.secret.intentId)
          .catch(Logger.error);
      }
    },
    [rPaymentIntent, rCancelPayment, hasSendPayment]
  );

  const handlePayment = useCallback(async () => {
    hasSendPayment.current = true;

    if (rPaymentIntent.current?.secret.intentId) {
      await setSaleOngoing(rPaymentIntent.current?.secret.intentId, auth);
    }

    await onPayment?.();
  }, [rPaymentIntent, auth, onPayment]);

  const rHandlePaymentChange = useLazyRef(handlePaymentChange);
  useEffect(() => {
    if (payments?.length === 0) {
      rHandlePaymentChange.current('');
    }
  }, [payments, rHandlePaymentChange]);

  if (loadingPayments || payments === undefined) {
    return <Loading />;
  }

  const handleRemoveClick = (card: Payment.ICard) => {
    setCardToRemove(card);
    showRemovalConfirmation();
  };

  const removeCard = async () => {
    if (!cardToRemove) {
      return;
    }

    try {
      const response = await deletePaymentMethod(cardToRemove.id, auth);
      if (response.status === 204) {
        showSnackbar(
          t('CARD-PAYMENT_REMOVE-CARD-SUCCEEDED')
            .replace('{{brand}}', cardToRemove.brand.toUpperCase())
            .replace('{{last4}}', cardToRemove.last4),
          { severity: 'success', closeButton: true }
        );
        mFetchAvailablePayments();
      } else {
        showSnackbar(t('SOMETHING_WENT_WRONG'), {
          severity: 'error',
          closeButton: true,
        });
      }
    } catch (e) {
      showSnackbar(t('SOMETHING_WENT_WRONG'), {
        severity: 'error',
        closeButton: true,
      });
    } finally {
      setCardToRemove(undefined);
    }
  };

  return (
    <CardPaymentContainer>
      <Spacer size={32} />
      <Paragraph variant="L">{t('CARD-PAYMENT_PAY-WITH')}</Paragraph>
      <Spacer size={16} />
      {payments.length > 0 && (
        <SavedCardsContainer>
          {payments.map((paymentCard) =>
            Payment.isCard(paymentCard) ? (
              <CardInput
                key={paymentCard.id}
                isSelected={selectedPayment === paymentCard.id}
                brand={paymentCard.brand}
                last4={paymentCard.last4}
                onSelect={() => handlePaymentChange(paymentCard.id)}
                onDelete={() => handleRemoveClick(paymentCard)}
              />
            ) : null
          )}
        </SavedCardsContainer>
      )}
      {selectedPayment === undefined && (
        <AddCard
          leadingAdornment="add_card__filled"
          onClick={() => handlePaymentChange('')}
          label={t('CARD-PAYMENT_ADD-CARD')}
          variant="text"
          size="S"
        />
      )}
      <Checkout
        currency={currency}
        fiatPrice={fiatPrice}
        fees={fees}
        guaranteeUntil={guaranteeUntil}
        loading={fetchPaymentIntent}
        secret={paymentIntent?.secret}
        onSuccess={handlePayment}
      />
      {cardToRemove && (
        <Dialog
          open={removalConfirmation}
          icon="warning__outlined"
          title={t('CARD-PAYMENT_REMOVE-CARD-POPUP-TITLE')}
          message={t('CARD-PAYMENT_REMOVE-CARD-POPUP-TEXT')
            .replace('{{brand}}', cardToRemove.brand.toUpperCase())
            .replace('{{last4}}', cardToRemove.last4)}
          acceptLabel={t('CARD-PAYMENT_REMOVE-CARD-ACCEPT')}
          cancelLabel={t('CARD-PAYMENT_REMOVE-CARD-CANCEL')}
          onClose={hideRemovalConfirmation}
          onAccept={removeCard}
          onCancel={hideRemovalConfirmation}
          hideBackdrop
        />
      )}
    </CardPaymentContainer>
  );
};
