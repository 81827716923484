import MuiTab, { tabClasses, TabProps } from '@mui/material/Tab';
import React from 'react';
import styled, { css, DefaultTheme } from 'styled-components';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { Paragraph } from '../../../typography';

const getVariant = (size: Required<ITabProps>['size']) => {
  switch (size) {
    case 'L':
      return 'XXL';
    case 'M':
      return 'L';
    case 'S':
      return 'M';
    default:
      throw catchMissingSwitchCase(size);
  }
};

const isMultiLine = (size: Required<ITabProps>['size']) => {
  switch (size) {
    case 'L':
    case 'M':
      return true;
    case 'S':
      return false;
    default:
      throw catchMissingSwitchCase(size);
  }
};

const getTabStyles = (
  theme: DefaultTheme
): Record<
  Required<ITabProps>['size'],
  {
    paddingBlock: string;
    paddingInline: string;
  }
> => ({
  L: {
    paddingBlock: theme.spacing(8),
    paddingInline: theme.spacing(16),
  },
  M: {
    paddingBlock: theme.spacing(8),
    paddingInline: '14px',
  },
  S: {
    paddingBlock: theme.spacing(8),
    paddingInline: theme.spacing(8),
  },
});

const LabelContainer = styled.div<{ size: Required<ITabProps>['size'] }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
  border-radius: 100px;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing(8)};
  color: ${({ theme }) => theme.color.primary['40']};

  ${({ theme, size }) => css`
    ${() => {
      const tabStyles = getTabStyles(theme)[size];

      return css`
        padding-inline: ${tabStyles.paddingInline};
        padding-block: ${tabStyles.paddingBlock};
      `;
    }}}
  `}
`;

const StyledParagraph = styled(Paragraph)`
  transition: all 0ms;
`;

const StyledTab = styled(MuiTab)`
  &.${tabClasses.root} {
    text-transform: none;
    padding: 0;
    width: fit-content;
  }

  &.${tabClasses.root}.Mui-selected {
    ${LabelContainer} {
      color: ${({ theme }) => theme.color.primary['0']};
    }
  }

  &.${tabClasses.root}.Mui-disabled {
    ${LabelContainer} {
      color: ${({ theme }) => theme.color.primary['60']};
    }
  }

  &:hover {
    ${LabelContainer} {
      background: ${({ theme }) => theme.color.primary['Light 5%']};
      color: ${({ theme }) => theme.color.primary['0']};
    }
  }
`;

export interface ITabProps<GValue = string>
  extends Omit<TabProps, 'value' | 'variant' | 'icon'> {
  adornment?: JSX.Element;
  disabled?: boolean;
  label: React.ReactNode;
  size?: 'S' | 'M' | 'L';
  value: GValue;
}

export function Tab<GValue>({
  adornment,
  disabled = false,
  label,
  size = 'M',
  value,
  ...props
}: ITabProps<GValue>) {
  return (
    <StyledTab
      {...props}
      value={value}
      disabled={disabled}
      label={
        typeof label === 'string' ? (
          <LabelContainer size={size}>
            {adornment}
            <StyledParagraph
              variant={getVariant(size)}
              multiline={isMultiLine(size)}
            >
              {label}
            </StyledParagraph>
          </LabelContainer>
        ) : (
          <>{label}</>
        )
      }
      disableRipple
    />
  );
}
