import { Authorization, Prize } from 'ultimate-league-common';
import { IGetAvailableGameWeek } from 'ultimate-league-common/dist/core-game/prize';

import { buildQuery, fetchApi } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';
import { getRecaptchaHeader } from '#technical/recaptcha';

export async function fetchPrizes(
  query: Prize.IGetPrizes['query'],
  auth: IAuthorization
): Promise<Prize.IGetPrizes['response']> {
  const res = await fetchApi(
    `/prize?${query ? buildQuery(query) : ''}`,
    undefined,
    auth
  );
  return res.json();
}

export async function claimPrize(
  { id }: Prize.IClaimPrize['params'],
  auth: IAuthorization
): Promise<Prize.IClaimPrize['response']> {
  const res = await fetchApi(
    `/prize/${id}/claim`,
    {
      headers: await getRecaptchaHeader(
        Authorization.RecaptchaAction.CLAIM_PRIZE
      ),
    },
    auth
  );
  return res.json();
}

export async function fetchPrizeAvailableGameWeeks(
  query: IGetAvailableGameWeek['query'],
  auth: IAuthorization
): Promise<Prize.IGetAvailableGameWeek['response']> {
  const res = await fetchApi(
    `/prize/availableGameweeks?${buildQuery(query)}`,
    undefined,
    auth
  );
  return res.json();
}
