import React from 'react';

import { t } from '#technical/translate';
import { showSnackbar } from '#ui/components';

import { BuyChampPage } from './BuyChampPage';
import { useBuyChampPage } from './useBuyChampPage';
import { BuyChampPopup, useBuyChampPopup } from './views';

export const BuyChampRoute = () => {
  const {
    currentChampBalance,
    ongoingTransactions,
    unaChange,
    fetchTransactions,
  } = useBuyChampPage();

  const {
    isInProgress,
    champPrice,
    paymentDetails,
    purchaseConfirmation,
    open: openPopup,
    close: closePopup,
    onCreatePaymentIntent,
    onCancelPaymentIntent,
    onCardPayment,
  } = useBuyChampPopup();

  const handlePayment = async () => {
    await onCardPayment();
    fetchTransactions();
    showSnackbar(t('BUY_CHAMP_PAGE-WITHDRAW_ONGOING_MESSAGE'), {
      severity: 'success',
    });
  };

  return (
    <>
      <BuyChampPage
        currentChampBalance={currentChampBalance}
        ongoingTransactions={ongoingTransactions}
        unaChange={unaChange}
        onPopupOpen={openPopup}
        isInProgress={isInProgress}
      />

      <BuyChampPopup
        champPrice={champPrice}
        purchaseConfirmation={purchaseConfirmation}
        paymentDetails={paymentDetails}
        close={closePopup}
        onCreatePaymentIntent={onCreatePaymentIntent}
        onCancelPaymentIntent={onCancelPaymentIntent}
        onCardPayment={handlePayment}
      />
    </>
  );
};
