import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';

const createMUITheme = ({ color, breakpoints, spacing }: DefaultTheme) =>
  createTheme({
    palette: {
      background: {
        default: color.primary['100'],
        paper: color.primary['100'],
      },
      text: {
        primary: color.primary['0'],
      },
    },
    breakpoints: {
      values: breakpoints.values,
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            paddingBlock: 0,
            paddingLeft: spacing(16),
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingRight: '0!important',
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: color.primary['Dark 80%'],
          },
        },
      },
    },
  });

export function MUITheme({ children }: { children: ReactNode }) {
  const theme = useTheme();
  return (
    <ThemeProvider theme={createMUITheme(theme)}>{children}</ThemeProvider>
  );
}
