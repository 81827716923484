import { User } from 'ultimate-league-common';

let memoryReferralId: string | undefined;
export function setReferralId(referralIdOrURLParams: string | URLSearchParams) {
  const referralId =
    typeof referralIdOrURLParams === 'string'
      ? referralIdOrURLParams
      : referralIdOrURLParams.get('referralId');
  if (referralId) {
    memoryReferralId = referralId;
    window.localStorage.setItem('referralId', referralId);
  }
}

export function getReferralId() {
  return (
    memoryReferralId ||
    new URLSearchParams(global.location.search).get('referralId') ||
    window.localStorage.getItem('referralId') ||
    undefined
  );
}

export function consumeReferralId() {
  const referralId = getReferralId();
  memoryReferralId = undefined;
  window.localStorage.removeItem('referralId');
  return referralId;
}

let memoryCohortIds: string | undefined;
export function setCohortIds(
  urlParams: URLSearchParams = new URLSearchParams(global.location.search)
) {
  const cohortIds = urlParams.get('cc');
  if (cohortIds) {
    memoryCohortIds = cohortIds;
    window.localStorage.setItem('cohortIds', cohortIds);
  }
}

export function getCohortIds() {
  return (
    memoryCohortIds ||
    new URLSearchParams(global.location.search).get('cc') ||
    window.localStorage.getItem('cohortIds') ||
    undefined
  );
}

export function consumeCohortIds() {
  const cohortIds = getCohortIds();
  memoryCohortIds = undefined;
  window.localStorage.removeItem('cohortIds');
  return cohortIds;
}

let memoryUTMParams: User.IUTM | undefined;
export function setUTMParams(
  fromURL: URLSearchParams = new URLSearchParams(global.location.search)
) {
  const params: User.IUTM = {
    source: fromURL.get('utm_source') ?? undefined,
    medium: fromURL.get('utm_medium') ?? undefined,
    campaign: fromURL.get('utm_campaign') ?? undefined,
    content: fromURL.get('utm_content') ?? undefined,
  };
  const isEmpty =
    !params.source && !params.medium && !params.campaign && !params.content;
  if (!isEmpty) {
    memoryUTMParams = params;
    window.localStorage.setItem('utm', JSON.stringify(params));
  }
}

export function getUTMParams(): User.IUTM | undefined {
  if (memoryUTMParams) {
    return memoryUTMParams;
  }

  try {
    const local = window.localStorage.getItem('utm');
    if (local) {
      return JSON.parse(local);
    }
  } catch (e) {
    return undefined;
  }

  return undefined;
}
