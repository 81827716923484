import { useCallback, useState } from 'react';
import { Blockchain, Payment, t } from 'ultimate-league-common';
import { fromWei } from 'web3-utils';

import { weiToFloat } from '#common/blockchain/service';
import { PaymentProvider } from '#common/payment';
import {
  cancelIntentChampBuy,
  createIntentChampBuy,
} from '#common/payment/service';
import { useUserAccount } from '#common/store';
import { useTokenChange } from '#common/store/tokenChange';
import { IPaymentDetails } from '#common/transaction';
import { useAuthorization } from '#technical/network/authorization';
import * as FacebookPixel from '#technical/tracking/facebookPixel';
import * as GoogleAd from '#technical/tracking/googleAd';

import { IBuyChampPopup } from '../../types';

export interface IBuyChampPopupHook
  extends Pick<
    IBuyChampPopup,
    | 'open'
    | 'champPrice'
    | 'close'
    | 'isInProgress'
    | 'onCardPayment'
    | 'onCancelPaymentIntent'
    | 'onCreatePaymentIntent'
    | 'purchaseConfirmation'
    | 'paymentDetails'
  > {}

export const useBuyChampPopup = (): IBuyChampPopupHook => {
  const [champPrice, setChampPrice] = useState<number>(0);
  const [fiatPrice, setFiatPrice] = useState<number>(0);
  const [saleId, setSaleId] = useState('');
  const [purchaseConfirmation, setPurchaseConfirmation] = useState<string>();
  const [isInProgress, setIsInProgress] = useState(false);
  const { account, refetch: refetchAccount, balances } = useUserAccount();
  const tokenChange = useTokenChange();
  const auth = useAuthorization();
  const champTokenBalance = balances[Blockchain.Token.Token.UNA];
  const mgcTokenBalance = balances[Blockchain.Token.Token.ULC];

  const mgcPrice = tokenChange?.ulc
    ? weiToFloat(Blockchain.Token.fiatToTokenWei(fiatPrice, tokenChange.ulc), 2)
    : 0;

  const open = (champs: number, fiat: number) => {
    setChampPrice(champs);
    setFiatPrice(
      fiat *
        (1 +
          Payment.Token.TOKEN_PURCHASE_STRIPE_FEE +
          Payment.Token.TOKEN_PURCHASE_UNAGI_FEE)
    );
  };

  const close = () => {
    setChampPrice(0);
  };

  const onCardPayment = async () => {
    setIsInProgress(true);

    await refetchAccount();

    setPurchaseConfirmation(undefined);
    setPurchaseConfirmation(
      t('TRANSACTION-POPUP_BUY-CHAMP_PURCHASE-CONFIRMATION')
    );

    if (account) {
      const params = {
        quantity: champPrice,
        fiatPrice,
        saleId,
      };
      FacebookPixel.purchaseChamp(params, {
        userId: account.id,
      });
      GoogleAd.purchaseChamp(params, {
        userId: account.id,
      });
    }

    close();

    setIsInProgress(false);
  };

  const onCreatePaymentIntent = useCallback(
    async (payment?: string | null) => {
      const data: Payment.IRequestCreateIntentChampBuy['body'] = {
        quantity: champPrice,
      };
      if (payment) {
        data.payment = payment;
      }
      const paymentIntent = await createIntentChampBuy(data, auth);
      setSaleId(paymentIntent.saleId!);
      return paymentIntent;
    },
    [auth, champPrice]
  );

  const onCancelPaymentIntent = useCallback(
    (intentId: string) =>
      cancelIntentChampBuy(
        {
          method: Payment.Method.FIAT,
          intentId,
        },
        auth
      ),
    [auth]
  );

  const paymentDetails: IPaymentDetails = {
    availablePayments: [PaymentProvider.FIAT],
    availableTokens: [],
    fiatPrice,
    champPrice,
    mgcPrice,
    currency: '$',
    userBalance: {
      champ: champTokenBalance
        ? Number(fromWei(champTokenBalance, 'ether'))
        : 0,
      mgc: mgcTokenBalance ? Number(fromWei(mgcTokenBalance, 'ether')) : 0,
    },
    fees: [
      {
        label: t('TRANSACTION-POPUP_BUY-CHAMP_STRIPE_FEE'),
        fee: Payment.Token.TOKEN_PURCHASE_STRIPE_FEE * 100,
        value: `$${(
          fiatPrice * Payment.Token.TOKEN_PURCHASE_STRIPE_FEE
        ).toFixed(2)}`,
      },
      {
        label: t('TRANSACTION-POPUP_BUY-CHAMP_UNAGI_FEE'),
        fee: Payment.Token.TOKEN_PURCHASE_UNAGI_FEE * 100,
        value: `$${(fiatPrice * Payment.Token.TOKEN_PURCHASE_UNAGI_FEE).toFixed(
          2
        )}`,
      },
    ],
  };

  return {
    champPrice,
    purchaseConfirmation,
    isInProgress,
    open,
    close,
    paymentDetails,
    onCancelPaymentIntent,
    onCreatePaymentIntent,
    onCardPayment,
  };
};
