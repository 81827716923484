"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAuctions = void 0;
var NFT_card_1 = require("../blockchain/NFT-card");
var pagination_1 = require("../pagination");
var athlete_1 = require("../soccer-data/athlete");
var sport_1 = require("../soccer-data/sport");
var technical_1 = require("../technical");
var endpoint_1 = require("./endpoint");
exports.getAuctions = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: endpoint_1.AUCTIONS_PAGE_LIMIT }),
    },
    sort: {
        inclusion: (0, technical_1.enumToArray)(endpoint_1.AuctionSort),
    },
    sport: sport_1.SportParameter,
    card: {
        format: technical_1.id.format,
    },
    athlete: {
        array: (_a = {},
            _a[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _a.format = technical_1.id.format,
            _a),
    },
    'age.$gte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    'age.$lte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    position: {
        array: (_b = {},
            _b[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _b.inclusion = athlete_1.ALL_POSITIONS,
            _b),
    },
    country: {
        array: (_c = {},
            _c[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _c.format = technical_1.id.format,
            _c),
    },
    club: {
        array: (_d = {},
            _d[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _d.format = technical_1.id.format,
            _d),
    },
    season: {
        array: (_e = {},
            _e[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _e.format = technical_1.id.format,
            _e),
    },
    competition: {
        array: (_f = {},
            _f[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _f.format = technical_1.id.format,
            _f),
    },
    level: {
        array: (_g = {},
            _g[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _g.inclusion = (0, technical_1.enumToArray)(NFT_card_1.RarityLevel, false),
            _g),
    },
    hasNextGameWeekMatch: {
        type: 'boolean',
    },
};
