import { Authorization, Pack } from 'ultimate-league-common';

import { buildQuery, fetchApi } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';
import { getRecaptchaHeader } from '#technical/recaptcha';

export async function fetchPackConfig(params: {
  id: Pack.IPackConfig['id'];
  auth: IAuthorization;
}): Promise<Pack.IGetAvailablePack['response']> {
  const response = await fetchApi(`/pack/${params.id}`, undefined, params.auth);
  return response.json();
}

export const claimPack = async (
  { id }: Pack.IClaimPack['params'],
  auth: IAuthorization
): Promise<Pack.IClaimPack['response']> => {
  const response = await fetchApi(
    `/pack/${id}/claim`,
    {
      method: 'POST',
      headers: await getRecaptchaHeader(
        Authorization.RecaptchaAction.CLAIM_PACK
      ),
    },
    auth
  );

  return response.json();
};

export async function fetchPacks(
  query: Pack.IGetAvailablePacks['query'],
  auth: IAuthorization
): Promise<Pack.IGetAvailablePacks['response']> {
  const response = await fetchApi(
    `/pack?${buildQuery(query)}`,
    undefined,
    auth
  );
  return response.json();
}

export async function fetchOngoingPackSales(
  query: Pack.IGetPacksWithOngoingSale['query'],
  auth: IAuthorization
): Promise<Pack.IGetPacksWithOngoingSale['response']> {
  const response = await fetchApi(
    `/pack/ongoing-sale?${buildQuery(query)}`,
    undefined,
    auth
  );
  return response.json();
}

export async function fetchPacksToOpen(
  query: Pack.IGetPacksToOpen['query'],
  auth: IAuthorization
): Promise<Pack.IGetPacksToOpen['response']> {
  const response = await fetchApi(
    `/pack/to-open?${buildQuery(query)}`,
    undefined,
    auth
  );
  return response.json();
}

export async function fetchOwnedPacks(
  query: Pack.IGetOwnedPacks['query'],
  auth: IAuthorization
): Promise<Pack.IGetOwnedPacks['response']> {
  const response = await fetchApi(
    `/pack/owned?${buildQuery(query)}`,
    undefined,
    auth
  );
  return response.json();
}
