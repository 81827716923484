import { createContext, useContext } from 'react';

import { IWalletTransactions } from '../types';

function willThrow() {
  throw new Error('WalletTransactions context is not initialized');
}

export const WalletTransactionsContext = createContext<IWalletTransactions>({
  transactions: [],
  addTransaction: willThrow as any as IWalletTransactions['addTransaction'],
});

export const useWalletTransactions = () =>
  useContext(WalletTransactionsContext);
