import { ReactElement, ReactNode } from 'react';
import { t as tCommon } from 'ultimate-league-common';

export function t(key: string): string;
export function t<GInterpolation extends ReactNode>(
  key: string,
  interpolations: Record<string, GInterpolation>
): GInterpolation extends ReactElement ? Array<ReactNode> : string;
export function t<GInterpolation extends ReactNode>(
  key: string,
  interpolations?: Record<string, GInterpolation>
): string | Array<ReactNode> {
  const translation = tCommon(key);

  if (interpolations === undefined) return translation;

  const chunks = translation.split(/({{.+?}})/).map((value, index) => {
    if (index % 2 === 0) return value;
    const interpolationKey = value.match(/\{\{(\w+)\}\}/)?.[1];
    if (!interpolationKey) return value;

    return interpolations[interpolationKey];
  });

  if (chunks.every((chunk) => typeof chunk === 'string'))
    return chunks.join('');

  return chunks;
}
