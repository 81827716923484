import * as React from 'react';
import styled from 'styled-components';
import { SoccerData, t } from 'ultimate-league-common';

import { SocialLink, useFooterConfig } from '#common/navigation';
import { DISCORD_LINK } from '#constant';
import { Logo } from '#ui/brand';
import {
  BrandIcon,
  Button,
  Display,
  Divider,
  Icon,
  Paragraph,
} from '#ui/components';
import { DesignSystem } from '#ui/theme';

const Container = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(24)};
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(32)};
  flex-shrink: 0;

  border-radius: 24px;
  background: ${({ theme }) => theme.color.primary[90]};
  max-width: 360px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
  align-self: stretch;
  text-align: center;
`;

const DiscordButton = styled(Button)`
  width: 100%;

  ${({ theme: { breakpoints } }) => breakpoints.up('md')} {
    width: fit-content;
  }
`;

const StyledDivider = styled(Divider)`
  align-self: stretch;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const FooterSport = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
`;

const Socials = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(16)};
`;

const MaintenanceIcon = styled(Icon).attrs({
  name: 'handyman__filled',
  size: 'L',
})``;

export const Maintenance = () => {
  const footerConfig = useFooterConfig(SoccerData.BrandConfig.defaultSport);

  return (
    <DesignSystem sport={SoccerData.BrandConfig.defaultSport}>
      <Container>
        <Header>
          <Logo />
          <MaintenanceIcon />
        </Header>
        <TextContent>
          <Display variant="M">{t('MAINTENANCE_HEADLINE')}</Display>
          <Paragraph variant="L" $textColor={({ primary }) => primary['40']}>
            {t('MAINTENANCE_CONTENT')}
          </Paragraph>
        </TextContent>
        {DISCORD_LINK && (
          <DiscordButton
            variant="filled"
            size="S"
            label={t('PLAYHUB_NAVIGATION_JOIN_DISCORD')}
            as="a"
            href={DISCORD_LINK}
            target="_blank"
            trailingAdornment={<BrandIcon brand="Discord" variant="colored" />}
          />
        )}
        <StyledDivider opacity={10} />
        <Footer>
          {footerConfig.socialLinksColumn.games.map(({ label, links }) => (
            <FooterSport key={label}>
              {footerConfig.socialLinksColumn.games.length > 1 && (
                <Paragraph
                  variant="S"
                  uppercase
                  bold
                  $textColor={({ primary }) => primary['40']}
                >
                  {label}
                </Paragraph>
              )}
              <Socials>
                {links.map((link) => (
                  <SocialLink key={link.label} link={link} size="S" />
                ))}
              </Socials>
            </FooterSport>
          ))}
        </Footer>
      </Container>
    </DesignSystem>
  );
};
