import { useEffect } from 'react';
import { Blockchain, Prize, enumToArray } from 'ultimate-league-common';
import { useEffectOnce, useInterval } from 'usehooks-ts';

import { fetchPrizes } from '#common/prize';
import { useCurrentSport } from '#common/sport';
import { usePrizes } from '#common/store';
import { useAuthorization } from '#technical/network/authorization';

export function useBootstrap() {
  const auth = useAuthorization();
  const { upserts } = usePrizes();
  const { sport } = useCurrentSport();

  useEffect(() => {
    if (auth.isSigned()) {
      Promise.all(
        enumToArray(Prize.ContextType).map((contextType) =>
          fetchPrizes(
            {
              contextType,
              sport,
            },
            auth
          )
        )
      ).then((prizeResponses) =>
        prizeResponses.forEach(({ items }) => upserts(items))
      );
    }
  }, [auth, upserts, sport]);

  useEffectOnce(Blockchain.healthCheckAndSwitch);
  useInterval(Blockchain.healthCheckAndSwitch, 30_000);
}
