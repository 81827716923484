"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZERO_ADDRESS = exports.addressEquals = exports.withoutPrefix = exports.withPrefix = void 0;
function withPrefix(address) {
    var lowAddress = address.toLowerCase();
    return lowAddress.startsWith('0x') ? lowAddress : "0x".concat(lowAddress);
}
exports.withPrefix = withPrefix;
function withoutPrefix(address) {
    var lowAddress = address.toLowerCase();
    return lowAddress.startsWith('0x') ? lowAddress.slice(2) : lowAddress;
}
exports.withoutPrefix = withoutPrefix;
function addressEquals(addressA, addressB) {
    return withoutPrefix(addressA) === withoutPrefix(addressB);
}
exports.addressEquals = addressEquals;
exports.ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
