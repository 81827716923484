"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Polygon = exports.Chiliz = exports.Bsc = exports.Base = exports.get = void 0;
var base_1 = require("./base");
Object.defineProperty(exports, "Base", { enumerable: true, get: function () { return base_1.Base; } });
var bsc_1 = require("./bsc");
Object.defineProperty(exports, "Bsc", { enumerable: true, get: function () { return bsc_1.Bsc; } });
var chiliz_1 = require("./chiliz");
Object.defineProperty(exports, "Chiliz", { enumerable: true, get: function () { return chiliz_1.Chiliz; } });
var isActive_1 = require("./isActive");
var polygon_1 = require("./polygon");
Object.defineProperty(exports, "Polygon", { enumerable: true, get: function () { return polygon_1.Polygon; } });
function get(blockchain) {
    if (!(0, isActive_1.isActive)(blockchain)) {
        throw new Error('Blockchain is not active.');
    }
    switch (blockchain) {
        case 'polygon':
            return polygon_1.Polygon;
        case 'bsc':
            return bsc_1.Bsc;
        case 'chiliz':
            return chiliz_1.Chiliz;
        case 'base':
            return base_1.Base;
        default:
            throw new Error('Invalid blockchain');
    }
}
exports.get = get;
