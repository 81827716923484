import { createContext } from 'react';

import { IStepKeys, ITutorialSteps, KSequence } from './types';

export interface ITutorialContext {
  currentStep: IStepKeys | undefined;
  setCurrentStep<GSequence extends KSequence>(keys: IStepKeys<GSequence>): void;
  progress: {
    [GSequence in KSequence]: Record<ITutorialSteps[GSequence], boolean>;
  };
}
export const TutorialContext = createContext<ITutorialContext>({
  currentStep: undefined,
  setCurrentStep: () => {},
  progress: {
    DRAFT: {
      INTRO: false,
      BUDGET: false,
      GAMEWEEK: false,
    },
  },
});
