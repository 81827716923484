"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.translationToString = exports.t = exports.getLang = exports.setLang = exports.fetchLang = exports.defaultLang = exports.LANG = void 0;
var Token = __importStar(require("../blockchain/token"));
var Brand = __importStar(require("../brand"));
var payment_1 = require("../payment");
var defaultLang_1 = require("./defaultLang");
Object.defineProperty(exports, "defaultLang", { enumerable: true, get: function () { return defaultLang_1.defaultLang; } });
var type_1 = require("./type");
Object.defineProperty(exports, "LANG", { enumerable: true, get: function () { return type_1.LANG; } });
var MAPPING = (_a = {},
    _a[defaultLang_1.defaultLang] = defaultLang_1.defaultTranslations,
    _a);
var currentLang = defaultLang_1.defaultLang;
function fetchLang(lang) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _a = MAPPING;
                    _b = lang;
                    return [4 /*yield*/, import("./values/default/".concat(lang, "-web.json"))];
                case 1:
                    _a[_b] = _f.sent();
                    if (!(Brand.BRAND !== 'UC')) return [3 /*break*/, 3];
                    _c = MAPPING;
                    _d = lang;
                    _e = [__assign({}, MAPPING[lang])];
                    return [4 /*yield*/, import("./values/".concat(Brand.BRAND, "/").concat(lang, "-web.json"))];
                case 2:
                    _c[_d] = __assign.apply(void 0, _e.concat([(_f.sent())]));
                    _f.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.fetchLang = fetchLang;
function setLang(lang) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchLang(lang)];
                case 1:
                    _a.sent();
                    currentLang = lang;
                    return [2 /*return*/];
            }
        });
    });
}
exports.setLang = setLang;
function getLang() {
    return currentLang;
}
exports.getLang = getLang;
function t(key) {
    var _a;
    var defaultTranslations = MAPPING[currentLang];
    if (!defaultTranslations) {
        throw new Error('Translations not loaded.');
    }
    var translation = defaultTranslations[key] || ((_a = MAPPING[defaultLang_1.defaultLang]) === null || _a === void 0 ? void 0 : _a[key]);
    if (translation) {
        return translation
            .replaceAll('{{UNA}}', Token.Labels.UNA)
            .replaceAll('{{ULC}}', Token.Labels.ULC)
            .replaceAll('{{FIAT}}', payment_1.ActiveCurrency)
            .replaceAll('{{FIAT_$}}', payment_1.CurrencySigns[payment_1.ActiveCurrency]);
    }
    return "{".concat(key, "}");
}
exports.t = t;
function translationToString(value) {
    var _a, _b;
    return (_b = (_a = value[currentLang]) !== null && _a !== void 0 ? _a : value[defaultLang_1.defaultLang]) !== null && _b !== void 0 ? _b : '';
}
exports.translationToString = translationToString;
