/* eslint-disable react/no-unused-state */
import jwtDecode from 'jwt-decode';
import * as React from 'react';
import { Authorization } from 'ultimate-league-common';

import { AuthorizationError } from '../api';
import { AuthorizationContext, IAuthorization } from './context';

/**
 * The main reason we use an old React.Component is to return a Promise on setCredentials and setTwoFa methods.
 * To do this, we need the old this.state(state, callback) method from React.Component.
 */
export class AuthorizationContainer extends React.Component<
  {},
  IAuthorization
> {
  constructor(props: {}) {
    super(props);

    const credentialsLocalStorage = localStorage.getItem('credentials');

    const credentials = credentialsLocalStorage
      ? JSON.parse(credentialsLocalStorage)
      : undefined;

    this.state = {
      credentials,
      twoFaJWTs: {},
      setCredentials: this.setCredentials,
      setJWT: this.setJWT,
      setTwoFa: this.setTwoFa,
      getTwoFa: this.getTwoFa,
      isSigned: this.isSigned,
      shouldSign: false,
    };
  }

  public componentDidMount() {
    window.addEventListener('storage', this.handleChange);
  }

  public componentDidCatch(error: Error) {
    if (error instanceof AuthorizationError) {
      this.setState({
        shouldSign: true,
      });
    } else {
      throw error;
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('storage', this.handleChange);
  }

  private handleChange = (event: any) => {
    if (event.key !== 'credentials') {
      return;
    }

    const credentialsLocalStorage = localStorage.getItem('credentials');

    const credentials = credentialsLocalStorage
      ? JSON.parse(credentialsLocalStorage)
      : undefined;

    this.setState({
      credentials,
    });
  };

  private setCredentials: IAuthorization['setCredentials'] = (credentials) =>
    new Promise<void>((resolve) => {
      if (credentials) {
        localStorage.setItem('credentials', JSON.stringify(credentials));
      } else {
        localStorage.removeItem('credentials');
      }

      this.setState(
        {
          credentials,
          shouldSign: false,
        },
        resolve
      );
    });

  private setJWT: IAuthorization['setJWT'] = async (jwt) => {
    const { credentials } = this.state;

    if (!credentials) {
      throw new Error('Cannot set JWT without credentials');
    }

    await this.setCredentials({
      ...credentials,
      jwt,
    });
  };

  private setTwoFa: IAuthorization['setTwoFa'] = (jwt) => {
    const { twoFaJWTs } = this.state;
    const { exp, access } = jwtDecode<{
      exp: number;
      access: Authorization.TwoFaAccess;
    }>(jwt);

    return new Promise((resolve) => {
      this.setState(
        {
          twoFaJWTs: {
            ...twoFaJWTs,
            [access]: {
              expire: exp * 1000,
              jwt,
            },
          },
        },
        resolve
      );
    });
  };

  private getTwoFa: IAuthorization['getTwoFa'] = (access) => {
    const { twoFaJWTs } = this.state;

    const twoFa = twoFaJWTs[access];
    if (twoFa && twoFa.expire > Date.now()) {
      return twoFa.jwt;
    }

    return undefined;
  };

  private isSigned: IAuthorization['isSigned'] = () => {
    const { credentials } = this.state;

    return !!credentials;
  };

  public render() {
    const { children } = this.props;

    return (
      <AuthorizationContext.Provider value={this.state}>
        {children}
      </AuthorizationContext.Provider>
    );
  }
}
