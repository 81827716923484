import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Blockchain, Payment, t } from 'ultimate-league-common';

import { PaymentProvider } from '#common/payment';
import {
  Button,
  Icon,
  ITabOption,
  Message,
  Paragraph,
  Skeleton,
  Spacer,
  Tabs,
} from '#ui/components';

import { IPaymentDetails } from '../../types';
import { CardPayment } from './CardPayment';
import { TokenPayment } from './TokenPayment';

const PaymentDetailsContainer = styled.div`
  padding-inline: ${({ theme }) => theme.spacing(32)};
  padding-bottom: ${({ theme }) => theme.spacing(40)};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: ${({ theme }) => theme.color.primary['90']};
`;

const StyledTabs = styled(Tabs)`
  background: ${({ theme }) => theme.color.primary['95']};
  margin-inline: -${({ theme }) => theme.spacing(32)};
`;

const LoadingSummary = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LoadingPrice = styled.div`
  display: grid;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const LoadingParagraph = styled(Paragraph)`
  white-space: nowrap;
`;

const LoadingTotal = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(16)};
  border-top: 1px solid ${({ theme }) => theme.color.primary['Light 10%']};
`;

const LoadingButton = styled(Button)`
  width: 100%;
`;

const Loading = () => (
  <PaymentDetailsContainer>
    <Spacer size={32} />
    <LoadingSummary>
      <LoadingParagraph variant="L" $textColor={({ primary }) => primary['40']}>
        {t('TOKEN-PAYMENT_YOU-WILL-PAY')}
      </LoadingParagraph>
      <LoadingPrice>
        <Skeleton variant="text" width="50%" height={19} alignment="right" />
        <Skeleton variant="text" width="25%" height={14} alignment="right" />
      </LoadingPrice>
    </LoadingSummary>
    <Spacer size={32} />
    <LoadingTotal />
    <Spacer size={40} />
    <LoadingButton label="" variant="filled" size="M" $loading />
  </PaymentDetailsContainer>
);

export interface IPaymentDetailsProps {
  paymentDetails?: IPaymentDetails;
  insufficientFundsMessage?: string;
  guaranteeUntil?: Date;
  inProgress?: boolean;
  selectedToken?: Blockchain.Token.Token;
  onTokenChange?: (token: Blockchain.Token.Token) => void;
  onCreatePaymentIntent?: (
    payment: string | null
  ) => Promise<Payment.IPaymentIntent | undefined>;
  onCancelPaymentIntent?: (intentId: string) => Promise<void>;
  onTokenPayment?: (selectedToken: Blockchain.Token.Token) => Promise<void>;
  onCardPayment?: () => Promise<void>;
}

export const PaymentDetails = ({
  inProgress,
  insufficientFundsMessage,
  paymentDetails,
  guaranteeUntil,
  selectedToken,
  onTokenChange,
  onCreatePaymentIntent,
  onCancelPaymentIntent,
  onTokenPayment,
  onCardPayment,
}: IPaymentDetailsProps) => {
  const availableOptions: Record<
    PaymentProvider,
    ITabOption<PaymentProvider>
  > = useMemo(
    () => ({
      [PaymentProvider.TOKEN]: {
        label: t('PAYMENT-DETAILS_TOKENS-TAB'),
        value: PaymentProvider.TOKEN,
        adornment: <Icon name="toll__filled" />,
      },
      [PaymentProvider.FIAT]: {
        label: t('PAYMENT-DETAILS_CARD-TAB'),
        value: PaymentProvider.FIAT,
        adornment: <Icon name="credit_card__filled" />,
      },
    }),
    []
  );

  const [selectedTab, setSelectedTab] = useState<PaymentProvider | undefined>(
    paymentDetails?.availablePayments[0]
  );

  const handleTabChange = (newTab: PaymentProvider) => {
    setSelectedTab(newTab);
  };

  const tabOptions = useMemo(() => {
    const options: ITabOption<PaymentProvider>[] = [];

    if (!paymentDetails) {
      return options;
    }

    if (
      paymentDetails.availablePayments.includes(PaymentProvider.TOKEN) &&
      paymentDetails.availableTokens.length > 0
    ) {
      options.push(availableOptions[PaymentProvider.TOKEN]);
    }

    if (paymentDetails.availablePayments.includes(PaymentProvider.FIAT)) {
      options.push(availableOptions[PaymentProvider.FIAT]);
    }

    return options;
  }, [availableOptions, paymentDetails]);

  if (!paymentDetails) {
    return <Loading />;
  }

  return (
    <PaymentDetailsContainer>
      {tabOptions.length > 0 ? (
        <>
          {tabOptions.length > 1 && (
            <StyledTabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="fullWidth"
              options={tabOptions}
            />
          )}
          {selectedTab === PaymentProvider.TOKEN && (
            <TokenPayment
              insufficientFundsMessage={insufficientFundsMessage}
              inProgress={!!inProgress}
              selectedToken={selectedToken}
              onTokenChange={onTokenChange}
              onPayment={onTokenPayment}
              paymentDetails={paymentDetails}
            />
          )}
          {selectedTab === PaymentProvider.FIAT && (
            <CardPayment
              currency={paymentDetails.currency}
              fees={paymentDetails.fees}
              guaranteeUntil={guaranteeUntil}
              fiatPrice={paymentDetails.fiatPrice}
              onCreatePaymentIntent={onCreatePaymentIntent}
              onCancelPaymentIntent={onCancelPaymentIntent}
              onPayment={onCardPayment}
            />
          )}
        </>
      ) : (
        <Message
          icon="credit_card__filled"
          title={t('PAYMENT-DETAILS_SORRY-TITLE')}
          description={t('SOMETHING_WENT_WRONG')}
        />
      )}
    </PaymentDetailsContainer>
  );
};
