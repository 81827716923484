/* eslint-disable camelcase */
import { Blockchain, Pack, Payment, User } from 'ultimate-league-common';

import { CookiePolicy } from '#common/misc';
import { PaymentProvider } from '#common/payment';
import { analytics } from '#technical/amplitude';
import { showSnackbar } from '#ui/components';

type GoogleTagEvent = 'conversion';

interface IParams {
  send_to: string;
  value?: number;
  currency?: 'USD' | 'EUR';
  transaction_id?: string;
}

interface IUserData {
  email?: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    gtag?(command: 'event', eventName: GoogleTagEvent, params?: IParams): void;
    gtag?(
      command: 'set',
      property: 'user_data',
      params: IUserData | null
    ): void;
  }
}

const { GOOGLE_AD_ID } = process.env;
const { GOOGLE_AD_CONVERSION_SIGN_UP } = process.env;
const { GOOGLE_AD_CONVERSION_PURCHASE } = process.env;
const { GOOGLE_AD_CONVERSION_REGISTER_TEAM } = process.env;
const { GOOGLE_AD_CONVERSION_CLAIM_REWARD } = process.env;
const { GOOGLE_AD_CONVERSION_COMPLETE_FTUE } = process.env;

if (
  GOOGLE_AD_ID &&
  (!GOOGLE_AD_CONVERSION_SIGN_UP ||
    !GOOGLE_AD_CONVERSION_PURCHASE ||
    !GOOGLE_AD_CONVERSION_REGISTER_TEAM ||
    !GOOGLE_AD_CONVERSION_CLAIM_REWARD ||
    !GOOGLE_AD_CONVERSION_COMPLETE_FTUE)
) {
  throw new Error('Missing env GOOGLE_AD_*');
}

// Init debug flag for tracking
let debugFlag: boolean;
(() => {
  try {
    debugFlag = !!localStorage.getItem('debug-tracking');
    const url = new URL(window.location.href);
    if (url.searchParams.get('debug-tracking') === 'true' && !debugFlag) {
      localStorage.setItem('debug-tracking', 'true');
      debugFlag = true;
    } else if (
      url.searchParams.get('debug-tracking') === 'false' &&
      debugFlag
    ) {
      localStorage.removeItem('debug-tracking');
      debugFlag = false;
    }
    // eslint-disable-next-line no-empty
  } catch {}
})();
function logTracking(params: {
  command: string;
  event: string;
  sendTo: string;
  sendToRaw: string;
  value?: string | number;
  currency?: string;
  transactionId?: string;
}) {
  if (!CookiePolicy.getCookieConsents().adPerformances) {
    return;
  }

  try {
    analytics.logUATracking(params);

    if (debugFlag) {
      const data = params.value
        ? `${params.value}$ (${params.currency}) #${params.transactionId}`
        : '';
      showSnackbar(
        `${params.command}.${params.event}.${params.sendTo} ${data}`
      );
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

const gtag: Window['gtag'] = (command: any, eventName: any, params: any) => {
  if (process.env.NODE_ENV === 'production') {
    global.window?.gtag?.(command, eventName, params);
  }
};

export async function setUserData(data: IUserData | null) {
  logTracking({
    command: 'set',
    event: 'user_data',
    sendTo: `${GOOGLE_AD_ID}`,
    sendToRaw: `${GOOGLE_AD_ID}`,
    value: JSON.stringify(data),
  });

  return gtag?.('set', 'user_data', data);
}

export function completeRegistration() {
  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'SIGN_UP',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_SIGN_UP}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_SIGN_UP}`,
  });
}

export function completeFTUE() {
  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'COMPLETE_FTUE',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_COMPLETE_FTUE}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_COMPLETE_FTUE}`,
  });
}

export function registerTeam() {
  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'REGISTER_TEAM',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_REGISTER_TEAM}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_REGISTER_TEAM}`,
  });
}

export function claimReward() {
  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'CLAIM_REWARD',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_CLAIM_REWARD}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_CLAIM_REWARD}`,
  });
}

export function purchasePack(
  params: {
    price: number;
    quantity: number;
    provider: PaymentProvider;
    token?: Blockchain.Token.Token;
  },
  {
    userId,
    packId,
  }: { userId: User.IMinimalUser['id']; packId: Pack.IPack['id'] }
) {
  const divide = (() => {
    if (params.provider === PaymentProvider.TOKEN) {
      return params.token === Blockchain.Token.Token.UNA ? 4 : 100;
    }

    return 1;
  })();

  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'PURCHASE',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_PURCHASE}`,
    value: (params.price / divide) * params.quantity,
    currency: Payment.ActiveCurrency,
    transactionId: `PURCHASE_${userId}_${packId}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_PURCHASE}`,
    value: (params.price / divide) * params.quantity,
    currency: 'EUR',
    transaction_id: `PURCHASE_${userId}_${packId}`,
  });
}

export function purchaseMarketplace(
  params: {
    price: number;
    provider: PaymentProvider;
    percentFees: number;
  },
  event: { userId: User.IMinimalUser['id']; nftId: Blockchain.NFTCard.NftId }
) {
  const fees = (params.price * params.percentFees) / 100;
  const spent = params.provider !== PaymentProvider.TOKEN ? params.price : 0;
  const value = spent + fees / 4;

  logTracking({
    command: 'event',
    event: 'conversion',
    sendTo: 'PURCHASE',
    sendToRaw: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_PURCHASE}`,
    value,
    currency: Payment.ActiveCurrency,
    transactionId: `PURCHASE_NFT_${event.userId}_${event.nftId}_${params.price}`,
  });

  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_PURCHASE}`,
    value,
    currency: Payment.ActiveCurrency,
    transaction_id: `PURCHASE_NFT_${event.userId}_${event.nftId}_${params.price}`,
  });
}

export function purchaseChamp(
  params: {
    quantity: number;
    fiatPrice: number;
    saleId: string;
  },
  event: { userId: User.IMinimalUser['id'] }
) {
  return gtag?.('event', 'conversion', {
    send_to: `${GOOGLE_AD_ID}/${GOOGLE_AD_CONVERSION_PURCHASE}`,
    value: params.fiatPrice,
    currency: Payment.ActiveCurrency,
    transaction_id: `PURCHASE_CHAMP_${params.quantity}_${params.saleId}_${event.userId}`,
  });
}
