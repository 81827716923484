import { ComponentClass, SVGProps } from 'react';

import { IBasketballIconProps } from '../BasketballIcon';
import AverageScore from './Average Score.svg';
import Blocks from './Blocks.svg';
import Bully from './Bully.svg';
import Colossus from './Colossus.svg';
import Defiant from './Defiant.svg';
import DoubleDouble from './DoubleDouble.svg';
import MatchPlayed from './Match Played.svg';
import Medic from './Medic.svg';
import MinutesPlayed from './Minutes Played.svg';
import Points from './Points.svg';
import Rebounds from './Rebounds.svg';
import RobbinHood from './RobbinHood.svg';
import Steals from './Steals.svg';
import TripleDouble from './TripleDouble.svg';

export const ASSETS: Record<
  IBasketballIconProps['name'],
  ComponentClass<SVGProps<SVGSVGElement>>
> = {
  AverageScore,
  Blocks,
  MatchPlayed,
  MinutesPlayed,
  Points,
  Rebounds,
  Steals,
  Bully,
  Colossus,
  Defiant,
  DoubleDouble,
  Medic,
  RobbinHood,
  TripleDouble,
};
