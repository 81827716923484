import { SnackbarOrigin, VariantType, enqueueSnackbar } from 'notistack';

import { SnackbarAction } from './types';

interface ISnackbarOptions {
  severity?: VariantType;
  anchorOrigin?: SnackbarOrigin;
  action?: SnackbarAction;
  closeButton?: boolean;
}

export interface ISnackbarHook {
  showSnackbar(): void;
}

export const showSnackbar = (message: string, options?: ISnackbarOptions) => {
  const variant = options?.severity || 'default';
  const anchorOrigin = options?.anchorOrigin || {
    vertical: 'top',
    horizontal: 'right',
  };

  enqueueSnackbar(message, {
    ...options,
    variant,
    anchorOrigin,
    action: options?.action,
  });
};
