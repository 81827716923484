"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionType = exports.isOptaError = void 0;
function isOptaError(data) {
    return Object.prototype.hasOwnProperty.call(data, 'errorCode');
}
exports.isOptaError = isOptaError;
var DivisionType;
(function (DivisionType) {
    DivisionType["TOTAL"] = "total";
    DivisionType["HOME"] = "home";
    DivisionType["AWAY"] = "away";
    DivisionType["FORM_TOTAL"] = "form-total";
    DivisionType["FORM_HOME"] = "form-home";
    DivisionType["FORM_AWAY"] = "form-away";
    DivisionType["HALF_TIME_TOTAL"] = "half-time-total";
    DivisionType["HALF_TIME_HOME"] = "half-time-home";
    DivisionType["HALF_TIME_AWAY"] = "half-time-away";
    DivisionType["ATTENDANCE"] = "attendance";
    DivisionType["OVER_UNDER"] = "over-under";
})(DivisionType || (exports.DivisionType = DivisionType = {}));
