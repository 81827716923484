import React, { createContext, useContext, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import { pushDataLayer } from '#technical/tracking/gtm';

import { Consent, Consents, ICookiePolicy } from '../type';

enum TcfConsent {
  AD_PERFORMANCES = 7,
  IMPROVE_SERVICES = 10,
}

interface ITcfEvent {
  gdprApplies: true;
  eventStatus: 'tcloaded' | 'cmpuishown' | 'useractioncomplete';
  purpose: {
    consents: Partial<Record<TcfConsent, boolean>>;
  };
  acExtraVendors: string[];
}

interface ITcfApi {
  (
    action: 'show',
    version: 2,
    f: () => void,
    params: { jumpAt: null | 'banner' | 'privacy' }
  ): void;
  (
    action: 'addEventListener',
    version: 2,
    listener: (event: ITcfEvent, success: boolean) => void
  ): void;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    __tcfapi?: ITcfApi;
  }
}

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
const tcfAPI: ITcfApi = (...params) => window.__tcfapi?.(...params);

function parseTcfConsents(
  consents: Partial<Record<TcfConsent, boolean>>
): Consents {
  return {
    adPerformances: !!consents[TcfConsent.AD_PERFORMANCES],
    improveServices: !!consents[TcfConsent.IMPROVE_SERVICES],
  };
}

const context = createContext<{
  consents: Consents;
}>({
  consents: {},
});

let consents: Consents = {};
const Provider: ICookiePolicy['CookieProvider'] = ({ children, onConsent }) => {
  const [stateConsents, setConsents] = useState<
    Partial<Record<Consent, boolean>>
  >({});

  useEffectOnce(() => {
    tcfAPI('addEventListener', 2, (event, success) => {
      if (!success) return;
      if (!['tcloaded', 'useractioncomplete'].includes(event.eventStatus))
        return;

      consents = parseTcfConsents(event.purpose.consents);
      setConsents({ ...consents });

      (Object.keys(consents) as Consent[]).forEach((c) => {
        onConsent(c, !!consents[c]);

        if (consents[c]) {
          pushDataLayer({ event: `consent_${c}` });
        }
      });
    });
  });

  return (
    <context.Provider value={{ consents: stateConsents }}>
      {children}
    </context.Provider>
  );
};

export const TcfCookiePolicy: ICookiePolicy = {
  CookieProvider: Provider,
  CookieComponent: () => null,

  useCookieConsents: () => useContext(context).consents,
  getCookieConsents: () => consents,
  showCookiePolicy: () => tcfAPI('show', 2, () => {}, { jumpAt: 'privacy' }),
};
