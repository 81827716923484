import { isPast } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { SoccerData } from 'ultimate-league-common';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useIsFirstRender } from 'usehooks-ts';

import { isLoading, LOADING, TLazyData } from '#technical/data';

import { IGameWeekMeta, TGameWeekPosition } from '../types';

function getInitialGameWeekPosition({
  liveGameWeek,
  isParticipatingInLiveGameWeek,
}: {
  liveGameWeek: IGameWeekMeta;
  isParticipatingInLiveGameWeek: boolean;
}): number {
  const nextGameWeekPosition = liveGameWeek.position + 1;

  if (isPast(new Date(liveGameWeek.endDate))) return nextGameWeekPosition;
  if (!isParticipatingInLiveGameWeek) return nextGameWeekPosition;
  return liveGameWeek.position;
}

export const GAME_WEEK_SEARCH_PARAM = 'gameweek';

export interface IGameWeekParamParams {
  liveGameWeek: TLazyData<IGameWeekMeta>;
  isParticipatingInLiveGameWeek: TLazyData<boolean>;
  sport: SoccerData.Sport;
}
export function useGameWeekParam({
  sport,
  liveGameWeek,
  isParticipatingInLiveGameWeek,
}: IGameWeekParamParams): [
  param: TLazyData<TGameWeekPosition>,
  setParam: (value: TGameWeekPosition) => void
] {
  const [param, setParam] = useQueryParam(GAME_WEEK_SEARCH_PARAM, NumberParam);

  const isFirstRender = useIsFirstRender();

  // Reset game week position parameter on sport change
  useEffect(() => {
    if (isFirstRender) return;
    setParam(undefined, 'replaceIn');
  }, [sport, setParam]);

  // Initialise game week position
  useEffect(() => {
    if (param !== undefined) return;
    if (isLoading(liveGameWeek)) return;
    if (isLoading(isParticipatingInLiveGameWeek)) return;
    if (liveGameWeek.sport !== sport) return;

    const initialPosition = getInitialGameWeekPosition({
      liveGameWeek,
      isParticipatingInLiveGameWeek,
    });
    setParam(initialPosition, 'replaceIn');
  }, [liveGameWeek, param, isParticipatingInLiveGameWeek, setParam]);

  // validate given game week position
  useEffect(() => {
    if (typeof param !== 'number') return;
    if (isLoading(liveGameWeek)) return;

    if (!Number.isInteger(param)) {
      setParam(undefined, 'replaceIn');
      return;
    }
    if (param <= 0) {
      setParam(undefined, 'replaceIn');
      return;
    }

    const nextGameWeekPosition = liveGameWeek.position + 1;
    const validPosition = Math.min(Number(param), nextGameWeekPosition);
    setParam(validPosition, 'replaceIn');
  }, [param, liveGameWeek, setParam]);

  const updateParam = useCallback(
    (value: TGameWeekPosition) => setParam(value),
    [setParam]
  );

  return [param || LOADING, updateParam];
}
