import styled, { css } from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { ITypographyProps, typographyStyles } from '../common';

/* Utilities */

type KTypeScale = 'XXL' | 'XL' | 'L' | 'M' | 'S' | 'XS';

const getFontSize = (typeScale: KTypeScale): number => {
  switch (typeScale) {
    case 'XXL':
      return 120;
    case 'XL':
      return 80;
    case 'L':
      return 56;
    case 'M':
      return 40;
    case 'S':
      return 32;
    case 'XS':
      return 28;
    default:
      throw catchMissingSwitchCase(typeScale);
  }
};

/* Main Component */

export interface IDisplayProps extends ITypographyProps {
  /**
   * Figma text style that should be used globally or per supported viewport
   *
   * @example global
   * <Display variant="M">I am M on all viewports</Display/>
   *
   * @example per-viewport
   * <Display
   * variant={{
   *    M: 'S',
   *    T: 'M',
   *    D: 'L'
   *  }}>
   *    I am S on [M]obile, M on [T]ablet and L on [D]esktop
   * </Display/>
   */
  variant: KTypeScale | Record<'M' | 'T' | 'D', KTypeScale>;
}

export const Display = styled.h1<IDisplayProps>`
  font-family: ${Brand.switchBrand({
    UC: 'Söhne Schmal, sans-serif',
    LFP: 'Insatiable Display, sans-serif',
  })};
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  // Font size
  ${({ variant, theme: { breakpoints } }) => {
    if (typeof variant === 'string')
      return css`
        font-size: ${getFontSize(variant)}px;
      `;
    // Responsive font size
    return css`
      font-size: ${getFontSize(variant.M)}px;
      ${breakpoints.up('md')} {
        font-size: ${getFontSize(variant.T)}px;
      }
      ${breakpoints.up('lg')} {
        font-size: ${getFontSize(variant.D)}px;
      }
    `;
  }}

  // Common styles
  ${(props) => typographyStyles(props)}
`;
