import MuiDialog, { dialogClasses, DialogProps } from '@mui/material/Dialog';
import DialogActions, {
  dialogActionsClasses,
} from '@mui/material/DialogActions';
import React from 'react';
import styled from 'styled-components';

import { IconSlot, TIconProp } from '../../icons';
import { Button } from '../../inputs';
import { Paragraph } from '../../typography';

const DialogContainer = styled(MuiDialog)`
  .${dialogClasses.paper} {
    padding: ${({ theme }) => theme.spacing(24)};
    gap: ${({ theme }) => theme.spacing(16)};
    background-color: ${({ theme }) => theme.color.primary['90']};
    max-width: 312px;
    border-radius: 16px;
    box-shadow: 0px 8px 36px rgba(0, 8, 8, 0.24),
      0px 2px 6px rgba(8, 8, 8, 0.16);
  }

  .${dialogActionsClasses.spacing} {
    padding: ${({ theme }) => theme.spacing(8)} 0 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export interface IDialogProps {
  open: DialogProps['open'];
  title: string;
  message: string;
  icon?: TIconProp;
  hideBackdrop?: DialogProps['hideBackdrop'];
  acceptLabel: string;
  cancelLabel: string;
  onAccept(): void;
  onCancel(): void;
  onClose?: DialogProps['onClose'];
}

export function Dialog({
  open,
  title,
  message,
  icon,
  hideBackdrop,
  acceptLabel,
  cancelLabel,
  onAccept,
  onCancel,
  onClose,
}: IDialogProps) {
  return (
    <DialogContainer open={open} onClose={onClose} hideBackdrop={hideBackdrop}>
      {icon && (
        <IconContainer>
          <IconSlot source={icon} size="L" />
        </IconContainer>
      )}
      <Paragraph variant="XXL" textAlign={icon ? 'center' : 'left'}>
        {title}
      </Paragraph>
      <Paragraph
        variant="M"
        $textColor={({ primary }) => primary['40']}
        multiline
      >
        {message}
      </Paragraph>

      <DialogActions>
        <Button
          variant="text"
          size="S"
          label={cancelLabel}
          onClick={onCancel}
        />

        <Button
          variant="text"
          size="S"
          label={acceptLabel}
          onClick={onAccept}
        />
      </DialogActions>
    </DialogContainer>
  );
}
