import MuiDialog, { dialogClasses } from '@mui/material/Dialog';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useBoolean } from 'usehooks-ts';

import { IStepKeys, ITutorialContext, TutorialContext } from '#common/tutorial';

import { getTutorialSequences } from './const';
import { MultiStep, SingleStep } from './views';

/* Styled Components */

const StyledDialog = styled(MuiDialog)`
  &.${dialogClasses.root} {
    z-index: 3000;
  }

  .${dialogClasses.paper} {
    border-radius: 16px;
  }
`;

/* Context */

/* Provider */

/**
 * Use `TutorialProvider` to display tutorial sequences that guide the user through the app
 */
export function TutorialProvider({ children }: PropsWithChildren<{}>) {
  const [currentStep, setCurrentStep] = useState<IStepKeys>();

  const {
    value: isStepVisible,
    setTrue: showStep,
    setFalse: hideStep,
  } = useBoolean(false);

  const displayedStep =
    currentStep &&
    getTutorialSequences()[currentStep.sequence][currentStep.key];

  const [progress, setProgress] = useState<ITutorialContext['progress']>({
    DRAFT: {
      INTRO: false,
      BUDGET: false,
      GAMEWEEK: false,
    },
  });

  useEffect(() => {
    if (currentStep === undefined) return;
    showStep();
  }, [currentStep, showStep]);

  const handleStepDone = () => {
    setProgress((current) => {
      if (currentStep === undefined) return current;

      return {
        ...current,
        [currentStep.sequence]: {
          ...current[currentStep.sequence],
          [currentStep.key]: true,
        },
      };
    });
    hideStep();
  };

  return (
    <TutorialContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        progress,
      }}
    >
      {children}
      <StyledDialog open={isStepVisible} maxWidth="sm">
        {(() => {
          if (displayedStep === undefined) return null;

          if (Array.isArray(displayedStep))
            return <MultiStep steps={displayedStep} onDone={handleStepDone} />;

          return <SingleStep data={displayedStep} onDone={handleStepDone} />;
        })()}
      </StyledDialog>
    </TutorialContext.Provider>
  );
}
