"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.initValidators = exports.ARRAY_OPTIONS = exports.single = exports.validate = exports.ValidationError = void 0;
var validate_js_1 = require("validate.js");
Object.defineProperty(exports, "single", { enumerable: true, get: function () { return validate_js_1.single; } });
Object.defineProperty(exports, "validate", { enumerable: true, get: function () { return validate_js_1.validate; } });
var localization_1 = require("../../localization");
var ValidationError = /** @class */ (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(errors) {
        var _this = _super.call(this, 'Validation fails') || this;
        _this.errors = errors;
        Object.setPrototypeOf(_this, ValidationError.prototype);
        return _this;
    }
    ValidationError.FORM_ERROR = '__form-error__';
    return ValidationError;
}(Error));
exports.ValidationError = ValidationError;
var ARRAY_OPTIONS = '__options__';
exports.ARRAY_OPTIONS = ARRAY_OPTIONS;
var initValidators = function (translateMessages) {
    var translate = translateMessages ? localization_1.t : function (m) { return m; };
    /**
     * Allow to use an array validator in validate.js.
     * You can add an __options__: { single?: boolean } field for options.
     *
     * Usage :
     *
     *    {
     *      emails: {
     *        array: {
     *         // Insert any validator to apply to each items
     *          email: true
     *        }
     *      }
     *    }
     *
     */
    validate_js_1.validators.array = function (arrayItems, itemConstraints) {
        if (arrayItems === void 0) { arrayItems = []; }
        var options = itemConstraints[ARRAY_OPTIONS];
        var f = (options === null || options === void 0 ? void 0 : options.single) ? validate_js_1.single : validate_js_1.validate;
        if (!(arrayItems instanceof Array)) {
            return translate('ARRAY_VALIDATION_ERROR');
        }
        var arrayItemErrors = arrayItems.reduce(function (errors, item, index) {
            var error = f(item, itemConstraints);
            if (error) {
                errors[index] = error;
            }
            return errors;
        }, []);
        return Object.keys(arrayItemErrors).length === 0 ? null : arrayItemErrors;
    };
    validate_js_1.validators[ARRAY_OPTIONS] = function () { return null; };
    /**
     * replace pattern validator
     *
     * @param value
     * @param regex
     */
    validate_js_1.validators.match = function (value, regex) {
        if (value.match(regex)) {
            return null;
        }
        return translate('MATCH_VALIDATION_ERROR');
    };
    // https://validatejs.org/#validators-date : "Before using it we must add the parse and format functions"
    (0, validate_js_1.extend)(validate_js_1.validators.datetime, {
        parse: function (value) {
            return value ? new Date(value) : '';
        },
        format: function (value, options) {
            if (!value) {
                return undefined;
            }
            var isoDate = new Date(value).toISOString();
            return options.dateOnly ? isoDate.substr(0, 10) : isoDate;
        },
    });
    /**
     * Change default error message
     */
    validate_js_1.validators.email.options = { message: translate('EMAIL_VALIDATION_ERROR') };
    validate_js_1.validators.presence.options = {
        message: translate('PRESENCE_VALIDATION_ERROR'),
    };
    validate_js_1.validators.equality.options = {
        message: translate('EQUALITY_VALIDATION_ERROR'),
    };
    validate_js_1.validators.datetime.options = { message: (0, localization_1.t)('DATE_VALIDATION_ERROR') };
    validate_js_1.validators.format.options = { message: (0, localization_1.t)('FORMAT_VALIDATION_ERROR') };
    validate_js_1.validators.url.options = { message: (0, localization_1.t)('URL_VALIDATION_ERROR') };
};
exports.initValidators = initValidators;
