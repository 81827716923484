import qs from 'qs';
import {
  Authorization,
  GameView,
  SoccerData,
  Storage,
  User,
} from 'ultimate-league-common';

import { buildQuery, fetchApi, submitData } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';
import { getRecaptchaHeader } from '#technical/recaptcha';
import { getPublicURL } from '#technical/storage';

import { IClubMeta } from './ManagerSetup/types';

export function loadClubs(
  query: SoccerData.Team.IGetClubs['query'],
  auth: IAuthorization
): Promise<IClubMeta[]> {
  const extractClubMeta = (data: SoccerData.Team.ITeam): IClubMeta => ({
    id: data.id,
    displayName: data.name,
    competition: data.competition?.name || '',
    crest: getPublicURL({
      storageType: Storage.StorageType.PUBLIC_TEAM_LOGO,
      format: Storage.AssetFormat.MEDIUM,
      id: data.id,
    }),
  });

  return fetchApi(`/sport-data/club?${buildQuery(query)}`, undefined, auth)
    .then<SoccerData.Team.IGetClubs['response']>((result) => result.json())
    .then((data) => data.map(extractClubMeta));
}

export async function fetchDraftCards(
  query: User.IGetFTUECards['query'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<User.IGetFTUECards['response']> {
  const result = await fetchApi(
    `/user/ftue/cards?${qs.stringify(query)}`,
    { signal },
    auth
  );
  return result.json();
}

export function requestDraftSuggestion(
  sport: SoccerData.Sport,
  auth: IAuthorization
): Promise<User.IFTUETeamSuggestion['response']> {
  return fetchApi(
    `/user/ftue/draft-suggestion?${buildQuery({ sport })}`,
    undefined,
    auth
  ).then((res) => res.json());
}

export async function registerDraft(
  values: User.IFTUERegister['body'],
  auth: IAuthorization
): Promise<void> {
  return submitData('/user/ftue/register', values, auth, {
    headers: await getRecaptchaHeader(
      Authorization.RecaptchaAction.REGISTER_DRAFT
    ),
  });
}

export async function fetchFormations(
  query: GameView.IGetFormations['query'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameView.IGetFormations['response']> {
  const res = await fetchApi(
    `/gameview/formation?${buildQuery(query)}`,
    { signal },
    auth
  );

  return res.json();
}

export async function getObjectiveRewards(
  query: User.IGetObjectiveRewards['query'],
  auth: IAuthorization
): Promise<User.IGetObjectiveRewards['response']> {
  const result = await fetchApi(
    `/user/ftue/objective-rewards?${qs.stringify(query)}`,
    undefined,
    auth
  );
  return result.json();
}

export function claimObjectiveReward(
  values: User.IClaimObjectiveReward['body'],
  auth: IAuthorization
): Promise<User.IClaimObjectiveReward['response']> {
  return submitData('/user/ftue/claim-objective-reward', values, auth);
}
