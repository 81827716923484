"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadConfig = void 0;
var dotenv_1 = require("dotenv");
var path_1 = require("path");
function loadEnv(values) {
    Object.keys(values).forEach(function (key) {
        process.env[key] = values[key];
    });
}
function parseEnv(path) {
    var _a;
    var parsed = (_a = (0, dotenv_1.config)({
        path: path,
    }).parsed) !== null && _a !== void 0 ? _a : {};
    loadEnv(parsed);
    return parsed;
}
function loadConfig(configDirectoryPath, configs, debug) {
    var initialProcessEnv = __assign({}, process.env);
    var parsed = configs.reduce(function (acc, path) { return (__assign(__assign({}, parseEnv((0, path_1.resolve)(configDirectoryPath, path))), acc)); }, {});
    loadEnv(initialProcessEnv);
    if (debug) {
        console.log('------------------------------ CONFIG ------------------------------');
        Object.keys(parsed).forEach(function (key) {
            return console.log("".concat(key, "=").concat(process.env[key]));
        });
        console.log('---------------------------- END CONFIG ----------------------------');
    }
}
exports.loadConfig = loadConfig;
