import React from 'react';
import styled from 'styled-components';
import { Payment, t } from 'ultimate-league-common';

import { Display, Paragraph, Progress, Spacer } from '#ui/components';
import { useViewport } from '#ui/hooks';

import { OngoingTransactions, Widget } from './views';

const LoadingContainer = styled.div`
  display: grid;
  justify-items: center;
`;

const BuyChampPageContainer = styled.div`
  display: grid;
  justify-items: center;

  padding-inline: ${({ theme }) => theme.spacing(16)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-inline: 0;
  }
`;

const Title = styled.div`
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 480px;
  }
`;

const Content = styled.div`
  margin-inline: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 360px;
  }
`;

export interface IBuyChampPageProps {
  currentChampBalance: number | undefined;
  unaChange: Payment.IUnaChange | undefined;
  ongoingTransactions: Payment.IOngoingTransaction[] | undefined;
  isInProgress: boolean;
  onPopupOpen: (champPrice: number, fiatPrice: number) => void;
}

export const BuyChampPage = ({
  currentChampBalance,
  ongoingTransactions,
  unaChange,
  isInProgress,
  onPopupOpen,
}: IBuyChampPageProps) => {
  const isExtraSmall = useViewport((breakpoints) => breakpoints.down('sm'));

  return (
    <BuyChampPageContainer>
      <Spacer
        size={40}
        responsive={{
          sm: 80,
        }}
      />
      <Title>
        <Display variant={isExtraSmall ? 'M' : 'L'}>
          {t('BUY_CHAMP_PAGE-TITLE')}
        </Display>
        <Spacer size={16} />
        <Paragraph
          variant="L"
          $textColor={({ primary }) => primary['40']}
          multiline
        >
          {t('BUY_CHAMP_PAGE-DESCRIPTION')}
        </Paragraph>
      </Title>
      <Spacer
        size={40}
        responsive={{
          sm: 56,
        }}
      />
      <Content>
        <Widget
          currentChampBalance={currentChampBalance}
          unaChange={unaChange}
          isInProgress={isInProgress}
          onPopupOpen={onPopupOpen}
        />
        {ongoingTransactions && ongoingTransactions.length > 0 && (
          <OngoingTransactions transactions={ongoingTransactions} />
        )}
        {ongoingTransactions === undefined && (
          <LoadingContainer>
            <Spacer size={40} />
            <Paragraph variant="L" textAlign="center" bold>
              {t('BUY_CHAMP_PAGE-ONGOING_TRANSACTIONS-TITLE')}
            </Paragraph>
            <Spacer size={24} />
            <Progress
              value={undefined}
              variant="circular"
              color="info"
              size="XL"
            />
          </LoadingContainer>
        )}
      </Content>
      <Spacer
        size={56}
        responsive={{
          sm: 80,
        }}
      />
    </BuyChampPageContainer>
  );
};
