"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refresh = exports.faFinishSign = exports.faSetup = exports.firebaseSign = void 0;
var technical_1 = require("../technical");
exports.firebaseSign = {
    token: {
        presence: {
            allowEmpty: false,
        },
    },
};
exports.faSetup = {
    secret: {
        presence: {
            allowEmpty: false,
        },
    },
    totp: {
        format: /\d{6}/,
        presence: {
            allowEmpty: false,
        },
    },
};
exports.faFinishSign = {
    faToken: {
        presence: {
            allowEmpty: false,
        },
    },
    totp: {
        format: /\d{6}/,
        presence: {
            allowEmpty: false,
        },
    },
};
exports.refresh = {
    id: technical_1.id,
    refreshToken: {
        presence: {
            allowEmpty: false,
        },
    },
};
