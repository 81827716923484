import styled from 'styled-components';

/* Main Component */

export interface IDividerProps {
  opacity: 5 | 10;
}

export const Divider = styled.div<IDividerProps>`
  height: 1px;
  background-color: ${({ theme, opacity }) =>
    theme.color.primary[`Light ${opacity}%`]};
`;
