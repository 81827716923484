"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMinimalLeague = exports.LeagueScoringMethod = void 0;
var LeagueScoringMethod;
(function (LeagueScoringMethod) {
    LeagueScoringMethod["NEXT_GAMEWEEK"] = "NEXT_GAMEWEEK";
    LeagueScoringMethod["SEASON_START"] = "SEASON_START";
})(LeagueScoringMethod || (exports.LeagueScoringMethod = LeagueScoringMethod = {}));
function isMinimalLeague(league) {
    return !league.scoringMethod;
}
exports.isMinimalLeague = isMinimalLeague;
