"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Polygon = void 0;
var buildBlockchain_1 = require("./buildBlockchain");
var isActive_1 = require("./isActive");
exports.Polygon = (0, isActive_1.isActive)('polygon')
    ? (0, buildBlockchain_1.buildBlockchain)({
        primary: process.env.POLYGON_PRIMARY_PROVIDER_URL,
        secondary: process.env.POLYGON_SECONDARY_PROVIDER_URL,
    }, {
        ULTIMATE_LEAGUE_NFT_ADDRESS: process.env.POLYGON_ULTIMATE_LEAGUE_NFT_ADDRESS,
        ULTIMATE_LEAGUE_MARKETPLACE_ADDRESS: process.env.POLYGON_ULTIMATE_LEAGUE_MARKETPLACE_ADDRESS,
        UNAGI_TOKEN_ADDRESS: process.env.POLYGON_UNAGI_TOKEN_ADDRESS,
        DISTRIBUTION_MANAGER_ADDRESS: process.env.POLYGON_DISTRIBUTION_MANAGER_ADDRESS,
        TOKEN_TRANSFER_RELAY_ADDRESS: process.env.POLYGON_TOKEN_TRANSFER_RELAY_ADDRESS,
        LAST_SEEN_ADDRESS: process.env.POLYGON_LAST_SEEN_ADDRESS,
    })
    : undefined;
