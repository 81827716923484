"use strict";
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentLevelEntries = exports.exceedsOverchargeLimit = exports.isFullyBoosted = exports.getConditionState = exports.getTournamentEntriesFromCondition = exports.getConditionModifier = exports.computeCondition = exports.getConditionBonus = exports.getEdition = exports.PREVIOUS_CONDITION = exports.PERIODS = exports.TOURNAMENT_ENTRIES_BONUS = exports.TOURNAMENT_ENTRIES_MAX = exports.TOURNAMENT_ENTRIES = exports.CARDS_TO_BURN_LIMIT = exports.ConditionBonus = void 0;
var brandConfig_1 = require("../brandConfig");
var type_1 = require("./type");
var ConditionBonus;
(function (ConditionBonus) {
    ConditionBonus["GENESIS"] = "GENESIS";
    ConditionBonus["MINT"] = "MINT";
    ConditionBonus["GREAT"] = "GREAT";
    ConditionBonus["GOOD"] = "GOOD";
    ConditionBonus["STANDARD"] = "STANDARD";
})(ConditionBonus || (exports.ConditionBonus = ConditionBonus = {}));
exports.CARDS_TO_BURN_LIMIT = 5;
exports.TOURNAMENT_ENTRIES = (_a = {},
    _a[ConditionBonus.GENESIS] = Infinity,
    _a[ConditionBonus.MINT] = 10,
    _a[ConditionBonus.GREAT] = 20,
    _a[ConditionBonus.GOOD] = 30,
    _a[ConditionBonus.STANDARD] = 0,
    _a);
exports.TOURNAMENT_ENTRIES_MAX = exports.TOURNAMENT_ENTRIES.STANDARD +
    exports.TOURNAMENT_ENTRIES.GOOD +
    exports.TOURNAMENT_ENTRIES.GREAT +
    exports.TOURNAMENT_ENTRIES.MINT;
exports.TOURNAMENT_ENTRIES_BONUS = (_b = {},
    _b[type_1.RarityLevel.RARITY_4] = 0,
    _b[type_1.RarityLevel.RARITY_3] = 15,
    _b[type_1.RarityLevel.RARITY_2] = 85,
    _b[type_1.RarityLevel.RARITY_1] = 150,
    _b[type_1.RarityLevel.FUNGIBLE] = 0,
    _b);
exports.PERIODS = (_c = {},
    _c[ConditionBonus.GENESIS] = Infinity,
    _c[ConditionBonus.MINT] = 90,
    _c[ConditionBonus.GREAT] = 270,
    _c[ConditionBonus.GOOD] = 365,
    _c[ConditionBonus.STANDARD] = Infinity,
    _c);
exports.PREVIOUS_CONDITION = (_d = {},
    _d[ConditionBonus.GENESIS] = ConditionBonus.GENESIS,
    _d[ConditionBonus.MINT] = ConditionBonus.GREAT,
    _d[ConditionBonus.GREAT] = ConditionBonus.GOOD,
    _d[ConditionBonus.GOOD] = ConditionBonus.STANDARD,
    _d[ConditionBonus.STANDARD] = ConditionBonus.STANDARD,
    _d);
function getEdition(metadata) {
    var _a;
    return (_a = metadata.attributes.find(function (_a) {
        var type = _a.type;
        return type === 'special_edition';
    })) === null || _a === void 0 ? void 0 : _a.value;
}
exports.getEdition = getEdition;
function getConditionBonus(level, entries, edition) {
    if ((edition === null || edition === void 0 ? void 0 : edition.toUpperCase()) === 'GENESIS') {
        return ConditionBonus.GENESIS;
    }
    if (level === type_1.RarityLevel.FUNGIBLE) {
        return ConditionBonus.STANDARD;
    }
    return getConditionState(entries).current;
}
exports.getConditionBonus = getConditionBonus;
function computeCondition(level, entries, edition) {
    return { condition: getConditionBonus(level, entries, edition) };
}
exports.computeCondition = computeCondition;
function getConditionModifier(condition, modifierMatrix) {
    if (!brandConfig_1.BrandConfig.enableConditionBonus) {
        return 0;
    }
    return modifierMatrix.conditions[condition] || 0;
}
exports.getConditionModifier = getConditionModifier;
function getTournamentEntriesFromCondition(condition) {
    switch (condition) {
        case ConditionBonus.STANDARD:
            return exports.TOURNAMENT_ENTRIES[condition];
        case ConditionBonus.GOOD:
            return (exports.TOURNAMENT_ENTRIES[ConditionBonus.STANDARD] +
                exports.TOURNAMENT_ENTRIES[condition]);
        case ConditionBonus.GREAT:
            return (exports.TOURNAMENT_ENTRIES[ConditionBonus.STANDARD] +
                exports.TOURNAMENT_ENTRIES[ConditionBonus.GOOD] +
                exports.TOURNAMENT_ENTRIES[condition]);
        case ConditionBonus.MINT:
            return (exports.TOURNAMENT_ENTRIES[ConditionBonus.STANDARD] +
                exports.TOURNAMENT_ENTRIES[ConditionBonus.GOOD] +
                exports.TOURNAMENT_ENTRIES[ConditionBonus.GREAT] +
                exports.TOURNAMENT_ENTRIES[condition]);
        case ConditionBonus.GENESIS:
            return exports.TOURNAMENT_ENTRIES[condition];
        default:
            return 0;
    }
}
exports.getTournamentEntriesFromCondition = getTournamentEntriesFromCondition;
function getConditionState(entries) {
    if (entries === Infinity) {
        return {
            current: ConditionBonus.GENESIS,
            good: { entries: Infinity, max: Infinity },
            great: {
                entries: Infinity,
                max: Infinity,
            },
            mint: { entries: Infinity, max: Infinity },
        };
    }
    if (entries <= exports.TOURNAMENT_ENTRIES.STANDARD) {
        return {
            current: ConditionBonus.STANDARD,
            good: { entries: 0, max: exports.TOURNAMENT_ENTRIES.GOOD },
            great: {
                entries: 0,
                max: exports.TOURNAMENT_ENTRIES.GREAT,
            },
            mint: { entries: 0, max: exports.TOURNAMENT_ENTRIES.MINT },
        };
    }
    if (entries <= exports.TOURNAMENT_ENTRIES.STANDARD + exports.TOURNAMENT_ENTRIES.GOOD) {
        return {
            current: ConditionBonus.GOOD,
            good: { entries: entries, max: exports.TOURNAMENT_ENTRIES.GOOD },
            great: {
                entries: 0,
                max: exports.TOURNAMENT_ENTRIES.GREAT,
            },
            mint: { entries: 0, max: exports.TOURNAMENT_ENTRIES.MINT },
        };
    }
    if (entries <=
        exports.TOURNAMENT_ENTRIES.STANDARD +
            exports.TOURNAMENT_ENTRIES.GOOD +
            exports.TOURNAMENT_ENTRIES.GREAT) {
        return {
            current: ConditionBonus.GREAT,
            good: { entries: exports.TOURNAMENT_ENTRIES.GOOD, max: exports.TOURNAMENT_ENTRIES.GOOD },
            great: {
                entries: entries - (exports.TOURNAMENT_ENTRIES.STANDARD + exports.TOURNAMENT_ENTRIES.GOOD),
                max: exports.TOURNAMENT_ENTRIES.GREAT,
            },
            mint: { entries: 0, max: exports.TOURNAMENT_ENTRIES.MINT },
        };
    }
    return {
        current: ConditionBonus.MINT,
        good: { entries: exports.TOURNAMENT_ENTRIES.GOOD, max: exports.TOURNAMENT_ENTRIES.GOOD },
        great: {
            entries: exports.TOURNAMENT_ENTRIES.GREAT,
            max: exports.TOURNAMENT_ENTRIES.GREAT,
        },
        mint: {
            entries: entries -
                (exports.TOURNAMENT_ENTRIES.STANDARD +
                    exports.TOURNAMENT_ENTRIES.GOOD +
                    exports.TOURNAMENT_ENTRIES.GREAT),
            max: exports.TOURNAMENT_ENTRIES.MINT,
        },
    };
}
exports.getConditionState = getConditionState;
function isFullyBoosted(entries) {
    // TODO: Uncomment after buy back event
    // return entries >= TOURNAMENT_ENTRIES_MAX;
    return false;
}
exports.isFullyBoosted = isFullyBoosted;
function exceedsOverchargeLimit(entries, bonus) {
    // TODO: Uncomment after buy back event
    // if (isFullyBoosted(entries)) {
    //   return true;
    // }
    // let totalEntries = entries;
    // for (const b of bonus) {
    //   if (totalEntries > TOURNAMENT_ENTRIES_MAX) {
    //     return true;
    //   }
    //   totalEntries += b;
    // }
    return false;
}
exports.exceedsOverchargeLimit = exceedsOverchargeLimit;
var getCurrentLevelEntries = function (entries) {
    var conditionState = getConditionState(entries);
    var currentLevel = conditionState.current;
    switch (currentLevel) {
        case ConditionBonus.GENESIS:
            return Infinity;
        case ConditionBonus.GOOD:
            return conditionState.good.entries;
        case ConditionBonus.GREAT:
            return conditionState.great.entries;
        case ConditionBonus.MINT:
            return conditionState.mint.entries;
        case ConditionBonus.STANDARD:
            return 0;
        default:
            throw new Error("Uncaught switch case: ".concat(currentLevel));
    }
};
exports.getCurrentLevelEntries = getCurrentLevelEntries;
