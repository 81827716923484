"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSale = exports.NFT_SALE_TTL_UNIT = exports.NFT_SALE_TTL = exports.MAX_LIMIT = void 0;
var pagination_1 = require("../../pagination");
var NFT_card_1 = require("../NFT-card");
exports.MAX_LIMIT = 16;
exports.NFT_SALE_TTL = 3;
exports.NFT_SALE_TTL_UNIT = 'days';
exports.getSale = __assign(__assign({}, NFT_card_1.getNfts), { limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.MAX_LIMIT }),
    }, sort: {
        inclusion: [
            NFT_card_1.NftsSort.RECOMMENDED_DESC,
            NFT_card_1.NftsSort.PRICE_ASC,
            NFT_card_1.NftsSort.PRICE_DESC,
            NFT_card_1.NftsSort.LAST_UPDATE_ASC,
            NFT_card_1.NftsSort.LAST_UPDATE_DESC,
            NFT_card_1.NftsSort.SCORE_ASC,
            NFT_card_1.NftsSort.SCORE_DESC,
            NFT_card_1.NftsSort.BEST_VALUE_ASC,
            NFT_card_1.NftsSort.BEST_VALUE_DESC,
        ],
    }, 'price.$gte': {
        numericality: {
            greaterThanOrEqualTo: 0,
        },
    }, 'price.$lte': {
        numericality: {
            greaterThanOrEqualTo: 0,
        },
    }, hasNextGameWeekMatch: {
        type: 'boolean',
    } });
