import type { PropsWithChildren } from 'react';
import * as React from 'react';
import { createContext, useCallback, useContext } from 'react';
import type { Blockchain } from 'ultimate-league-common';

import { IStore, useStore } from './createStore';
import { useMinimalAthletes } from './minimalAthletes';

function willThrow(): void {
  throw new Error('Cards context is not initialized');
}

const cardsContext = createContext<IStore<Blockchain.NFTCard.ICard>>({
  pool: {},
  items: [],
  remove: willThrow,
  upsert: willThrow,
  upserts: willThrow,
});

export const useCards = () => useContext(cardsContext);

const getId = ({ id }: Blockchain.NFTCard.ICard) => id;

function shouldUpdateState(
  itemA: Blockchain.NFTCard.ICard,
  itemB: Blockchain.NFTCard.ICard
) {
  return (
    itemA.id !== itemB.id ||
    itemA.tournamentEntries !== itemB.tournamentEntries ||
    itemA.events.length !== itemB.events.length
  );
}

const CardsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { upsert: upsertAthlete, upserts: upsertAthletes } =
    useMinimalAthletes();

  const {
    upsert: upsertCard,
    upserts: upsertCards,
    ...cardStore
  } = useStore<Blockchain.NFTCard.ICard>(getId, shouldUpdateState);

  const upsert = useCallback<IStore<Blockchain.NFTCard.ICard>['upsert']>(
    (card) => {
      upsertAthlete(card.athlete);
      upsertCard(card);
    },
    [upsertAthlete, upsertCard]
  );

  const upserts = useCallback<IStore<Blockchain.NFTCard.ICard>['upserts']>(
    (cards) => {
      upsertAthletes(cards.map(({ athlete }) => athlete));
      upsertCards(cards);
    },
    [upsertAthletes, upsertCards]
  );

  return (
    <cardsContext.Provider value={{ ...cardStore, upsert, upserts }}>
      {children}
    </cardsContext.Provider>
  );
};

export const Provider = ({ children }: PropsWithChildren<{}>) => (
  <CardsProvider>{children}</CardsProvider>
);
