import React from 'react';
import styled from 'styled-components';

import { Button, Paragraph, Spacer } from '#ui/components';

import { ITutorialStep } from '../../types';

/* Styled Components */

const Container = styled.div`
  display: grid;
  max-width: 400px;
  border-radius: 16px;
  overflow: hidden;

  background-color: ${({ theme }) => theme.color.primary['90']};
`;

const Visual = styled.img`
  width: 100%;
`;

const Content = styled.div`
  display: grid;
  margin-top: auto;
  padding-inline: ${({ theme }) => theme.spacing(24)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-inline: ${({ theme }) => theme.spacing(32)};
  }
`;

/* Main Component */

export interface ISingleStepProps {
  data: ITutorialStep;
  onDone: () => void;
}

/**
 * Use `SingleStep` to display a single tutorial step to provide some useful information relating to a certain aspect of the app
 */
export function SingleStep({ data, onDone }: ISingleStepProps) {
  return (
    <Container>
      <Visual src={data.image} />
      <Content>
        <Spacer size={32} />
        <Paragraph variant="L" bold textAlign="center">
          {data.title}
        </Paragraph>
        <Spacer size={16} />
        <Paragraph
          variant="M"
          multiline
          textAlign="center"
          $textColor={({ primary }) => primary['20']}
        >
          {data.message}
        </Paragraph>
        <Spacer size={32} />
        <Button variant="tonal" label={data.button} size="S" onClick={onDone} />
        <Spacer size={32} />
      </Content>
    </Container>
  );
}
