import { User } from 'ultimate-league-common';

interface IPayload {
  person?: {
    id: string;
    username?: string;
  };
}

interface IConfig {
  payload: IPayload;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Rollbar: {
      error: (e: Error, ...params: unknown[]) => undefined;
      configure: (c: IConfig) => undefined;
    };
  }
}

export const setUser = (user: Pick<User.IUser, 'id' | 'username' | 'role'>) =>
  window.Rollbar.configure({
    payload: {
      person: user,
    },
  });

export const error = (e: Error, ...params: unknown[]) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(e, ...params);
  }
  if ('Rollbar' in window) {
    window.Rollbar.error(e, ...params);
  }
  return e;
};
