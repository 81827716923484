import { createGlobalStyle } from 'styled-components';

import { ICON_SIZES, ICON_STYLES } from './data';
import { KIconSize, KIconStyle } from './types';

export const fontIconClasses = {
  base: 'material-symbols-rounded',
  size: (size: KIconSize) => `icon-size-${size}`,
  style: (style: KIconStyle) => ICON_STYLES[style],
};

export const IconFont = createGlobalStyle`
    .${fontIconClasses.style('filled')} {
      font-variation-settings:
      'FILL' 1,
      'wght' 300,
      'GRAD' 0;
    }
    
    .${fontIconClasses.style('outlined')} {
      font-variation-settings:
      'FILL' 0,
      'wght' 300,
      'GRAD' 0;
    }
    
    .${fontIconClasses.size('S')} {
      font-size: ${ICON_SIZES.S}px;
      width: ${ICON_SIZES.S}px;
      height: ${ICON_SIZES.S}px;
      overflow: hidden;
    }
    
    .${fontIconClasses.size('M')} {
      font-size: ${ICON_SIZES.M}px;
      width: ${ICON_SIZES.M}px;
      height: ${ICON_SIZES.M}px;
      overflow: hidden;
    }
    
    .${fontIconClasses.size('L')} {
      font-size: ${ICON_SIZES.L}px;
      width: ${ICON_SIZES.L}px;
      height: ${ICON_SIZES.L}px;
      overflow: hidden;
    }
    
    .${fontIconClasses.size('XL')} {
      font-size: ${ICON_SIZES.XL}px;
      width: ${ICON_SIZES.XL}px;
      height: ${ICON_SIZES.XL}px;
      overflow: hidden;
    }
`;
