import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRoutePath } from '#common/routing';

import { PackStore } from './all-packs';
import { OngoingPackSalesProvider } from './context/OngoingPackSalesContext';
import { OwnedPacksProvider } from './context/OwnedPacksContext';
import { PacksToOpenProvider } from './context/PacksToOpenContext';
import { PackView } from './pack-view';

export * from './views';

export const PacksRouter = () => (
  <OngoingPackSalesProvider>
    <PacksToOpenProvider>
      <OwnedPacksProvider>
        <Switch>
          <Route
            exact
            path={getRoutePath('PACK_DETAILS')}
            component={PackView}
          />
          <Route
            exact
            path={getRoutePath('PACK_STORE')}
            component={PackStore}
          />
        </Switch>
      </OwnedPacksProvider>
    </PacksToOpenProvider>
  </OngoingPackSalesProvider>
);
