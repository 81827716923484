import { createContext, useContext } from 'react';
import { Authorization as AuthorizationCommon } from 'ultimate-league-common';

interface ITwoFaJWT {
  expire: number; // UNIX timestamp
  jwt: AuthorizationCommon.JWT;
}

export interface IAuthorization {
  credentials?: AuthorizationCommon.IAuthorization;
  twoFaJWTs: Partial<Record<AuthorizationCommon.TwoFaAccess, ITwoFaJWT>>;
  shouldSign?: boolean;
  setCredentials(
    credentials: AuthorizationCommon.IAuthorization | undefined
  ): Promise<void>;
  setJWT(jwt: AuthorizationCommon.JWT): Promise<void>;
  setTwoFa(jwt: AuthorizationCommon.JWT): Promise<void>;
  getTwoFa(
    access: AuthorizationCommon.TwoFaAccess
  ): AuthorizationCommon.JWT | undefined;
  isSigned(): boolean;
}

export const AuthorizationContext = createContext<IAuthorization>({
  twoFaJWTs: {},
  setCredentials() {
    throw new Error('You need to init AuthorizationContext first.');
  },
  setJWT() {
    throw new Error('You need to init AuthorizationContext first.');
  },
  setTwoFa() {
    throw new Error('You need to init AuthorizationContext first.');
  },
  getTwoFa() {
    throw new Error('You need to init AuthorizationContext first.');
  },
  isSigned() {
    throw new Error('You need to init AuthorizationContext first.');
  },
});

export const useAuthorization = () => useContext(AuthorizationContext);
