import * as React from 'react';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { GameView } from 'ultimate-league-common';

import { fetchGamePlayer } from '#common/game-view';
import { useCurrentDivision } from '#common/game-week';
import { useMemoizedFetch } from '#technical/network/useMemoizedFetch';

import { useCards } from './cards';
import { IStore, useStore } from './createStore';
import { useUsers } from './users';

function willThrow(): void {
  throw new Error('GamePlayers context is not initialized');
}

const gamePlayersContext = createContext<IStore<GameView.IGamePlayer>>({
  pool: {},
  items: [],
  remove: willThrow,
  upsert: willThrow,
  upserts: willThrow,
});

export const useGamePlayers = () => useContext(gamePlayersContext);

const getId = ({ id }: GameView.IGamePlayer) => id;

export const GamePlayersProvider = ({ children }: PropsWithChildren<{}>) => {
  const { upsert: upsertUser, upserts: upsertUsers } = useUsers();
  const { upserts: upsertCards } = useCards();
  const {
    upsert: upsertGamePlayer,
    upserts: upsertGamePlayers,
    ...gamePlayerStore
  } = useStore<GameView.IGamePlayer>(getId);

  const upsert = useCallback<IStore<GameView.IGamePlayer>['upsert']>(
    (gamePlayer) => {
      upsertUser(gamePlayer.user);
      const cards = gamePlayer.team?.cards;
      if (cards) {
        upsertCards(
          Object.keys(cards).map((position) => cards[position].athleteNftCard)
        );
      }
      upsertGamePlayer(gamePlayer);
    },
    [upsertGamePlayer, upsertCards, upsertUser]
  );

  const upserts = useCallback<IStore<GameView.IGamePlayer>['upserts']>(
    (gamePlayers) => {
      upsertGamePlayers(gamePlayers);
      upsertUsers(gamePlayers.map(({ user }) => user));
    },
    [upsertGamePlayers, upsertUsers]
  );

  return (
    <gamePlayersContext.Provider
      value={{ ...gamePlayerStore, upsert, upserts }}
    >
      {children}
    </gamePlayersContext.Provider>
  );
};

export function useGamePlayer(
  userId: GameView.IGamePlayer['user']['id'] | undefined,
  divisionId: GameView.IGamePlayer['divisionId'] | undefined
): {
  gamePlayer: GameView.IGamePlayer | undefined;
  loading: boolean;
} {
  const { items, upsert: upsertGamePlayer } = useGamePlayers();
  const gamePlayer = items.find(
    (item) => userId === item.user.id && divisionId === item.divisionId
  );
  const [, loading, mFetchGamePlayer] = useMemoizedFetch<
    GameView.IGetGamePlayer['params'],
    GameView.IGamePlayer | undefined
  >(
    (params, { signal, auth }) => fetchGamePlayer(params, auth, signal),

    {
      cacheKey: (params) => `game-player-${JSON.stringify(params)}`,
      handleData: (data) => data && upsertGamePlayer(data),
      cacheMS: 30_000,
      initialLoading: gamePlayer === undefined,
    }
  );

  useEffect(() => {
    if (divisionId && userId) {
      return mFetchGamePlayer({
        division: divisionId,
        user: userId,
      });
    }

    return () => null;
  }, [divisionId, userId, mFetchGamePlayer]);

  return {
    gamePlayer,
    loading,
  };
}

export function useCurrentGamePlayer(
  userId: GameView.IGamePlayer['user']['id'] | undefined
) {
  return useGamePlayer(userId, useCurrentDivision()?.id);
}
