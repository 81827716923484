"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SaleType = exports.SaleStatus = void 0;
var SaleStatus;
(function (SaleStatus) {
    SaleStatus["CREATED"] = "CREATED";
    SaleStatus["ONGOING"] = "ONGOING";
    SaleStatus["SUCCESS"] = "SUCCESS";
    SaleStatus["REFUNDED"] = "REFUNDED";
    SaleStatus["ERROR"] = "ERROR";
    SaleStatus["CANCELLED"] = "CANCELLED";
    SaleStatus["STOPPED"] = "STOPPED";
})(SaleStatus || (exports.SaleStatus = SaleStatus = {}));
var SaleType;
(function (SaleType) {
    SaleType["MARKETPLACE_MIDDLEMAN"] = "marketplace-middleman";
    SaleType["PACK"] = "pack";
    SaleType["CHAMP"] = "champ";
    SaleType["CARD_BOOST"] = "card-boost";
    SaleType["AUCTION_BID"] = "auction-bid";
})(SaleType || (exports.SaleType = SaleType = {}));
