import { SoccerData } from 'ultimate-league-common';

import { createStore } from './createStore';

export type AnyScoreComputation =
  | SoccerData.Athlete.IScoreComputation
  | SoccerData.Athlete.IMinimalScoreComputation;

export function getAthleteScoreComputationId(
  athleteId: string,
  matchId: string
) {
  return `${athleteId}_${matchId}`;
}

export const [
  athleteScoreComputationsContext,
  AthleteScoreComputationsProvider,
  useAthleteScoreComputations,
] = createStore<AnyScoreComputation>(({ athlete, match }) =>
  getAthleteScoreComputationId(athlete, match)
);
