declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    WonderPush?: {
      push(e: [string, ...any]): void;
    };
  }
}

export function setUserId(id: string) {
  try {
    window.WonderPush?.push(['setUserId', id]);
  } catch (err) {
    console.error('Failed to set wonderpush user id: ', err);
  }
}

export function unsetUserId() {
  try {
    window.WonderPush?.push(['unsetUserId']);
  } catch (err) {
    console.error('Failed to set wonderpush user id: ', err);
  }
}
