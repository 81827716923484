import {
  AddressElement,
  CardCvcElement,
  PaymentElement,
} from '@stripe/react-stripe-js';
import type {
  ConfirmCardPaymentData,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
  StripeCardElementOptions,
  StripeElements,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import React, { FormEventHandler, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Brand, Payment, t } from 'ultimate-league-common';

import { IFee } from '#common/transaction/types';
import { Button, Checkbox, Paragraph, Progress, Spacer } from '#ui/components';

import { GuaranteeUntil } from '../GuaranteeUntil';

export interface ICheckoutFormProps {
  loading?: boolean;
  fiatPrice: number;
  currency: string;
  fees?: IFee[];
  guaranteeUntil?: Date;
  onSuccess: () => void | Promise<void>;
  onRequestCancel?: () => void;
  stripe?: {
    stripe: Stripe;
    elements: StripeElements;
    secret: Payment.IClientSecret;
  };
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const NewCardContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const InputContainer = styled.div<{ alert?: boolean }>`
  padding-block: ${({ theme }) => theme.spacing(16)};
  padding-left: ${({ theme }) => theme.spacing(16)};
  padding-right: ${({ theme }) => theme.spacing(8)};
  cursor: text;
  border-radius: 8px;
  background: ${({ theme }) => theme.color.primary['Light 5%']};
  transition: all 200ms;
  border: 1px solid
    ${({ theme, alert }) =>
      alert ? theme.color.warning['30%'] : 'transparent'};

  &:hover {
    border: 1px solid
      ${({ theme, alert }) =>
        alert ? theme.color.warning['50'] : theme.color.primary['Light 20%']};
  }
`;

const SaveForLaterContainer = styled.label`
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const Fees = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(24)};
`;

const FeeRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block: ${({ theme }) => theme.spacing(16)};

  :not(:last-child) {
    border-bottom: ${({ theme }) =>
      `1px solid ${theme.color.primary['Light 10%']}`};
  }
`;

const FeeValue = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const Summary = styled.div<{ hasFees: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme, hasFees }) => (hasFees ? 0 : theme.spacing(16))};
  padding-top: ${({ theme }) => theme.spacing(16)};
  border-top: ${({ theme }) => `1px solid ${theme.color.primary['Light 10%']}`};
`;

const Actions = styled.div`
  gap: ${({ theme }) => theme.spacing(16)};
  display: grid;
  margin-top: ${({ theme }) => theme.spacing(40)};
  width: 100%;
`;

export const LoadingContainer = styled.div`
  min-height: 54px;
  width: 100%;
  display: flex;
`;

export const Loading = styled(Progress)`
  margin: 0 auto;
`;

const ElementsOption: Partial<
  StripeCardElementOptions | StripeCardCvcElementOptions
> = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: Brand.switchBrand({
        UC: 'rgba(255, 255, 255, 0.4)',
        LFP: 'rgba(0, 0, 0, 0.4)',
      }),
      backgroundColor: 'transparent',
      color: Brand.switchBrand({ UC: '#FFFFFF', LFP: '#091C3E' }),
      fontFamily: 'DM Sans, sans-serif',

      fontSize: '14px',
      fontVariant: "'pnum' on, 'lnum' on, 'ss03' on",
      '::placeholder': {
        color: Brand.switchBrand({
          UC: 'rgba(255, 255, 255, 0.4)',
          LFP: 'rgba(0, 0, 0, 0.4)',
        }),
      },
      padding: '0',
    },
    invalid: {
      iconColor: '#F08022',
      color: '#F08022',
    },
  },
};

export const CheckoutForm = ({
  currency,
  fiatPrice,
  loading,
  guaranteeUntil,
  fees,
  onSuccess,
  stripe,
}: ICheckoutFormProps) => {
  const [error, setError] = useState<string>();
  const [isOfferExpired, setIsOfferExpired] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [saveCard, setSaveCard] = useState(false);

  const handleCardChange = useCallback(
    async (event: StripePaymentElementChangeEvent) => {
      setDisabled(!event.complete);
      setError(undefined);
    },
    [setDisabled, setError]
  );

  const handleCVCChange = useCallback(
    async (event: StripeCardCvcElementChangeEvent) => {
      setDisabled(event.empty);
      setError(event.error ? event.error.message : '');
    },
    [setDisabled, setError]
  );

  const handleSubmit = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault();

      if (!stripe) {
        return;
      }

      if (stripe.secret.requires === 'card') {
        const submission = await stripe.elements.submit();
        if (!submission.error) {
          setError(undefined);
          setSubmitting(true);
          const payload = await stripe.stripe.confirmPayment({
            elements: stripe.elements,
            clientSecret: stripe.secret.secret,
            redirect: 'if_required',
            confirmParams: { save_payment_method: saveCard },
          });
          if (payload.error) {
            setError(payload.error.message);
          } else {
            await onSuccess();
          }
          setSubmitting(false);
        }
      } else {
        const data: ConfirmCardPaymentData = {};
        const cvcElement = stripe.elements.getElement('cardCvc');
        if (!cvcElement) {
          throw new Error('CheckoutForm should be inside a stripe element.');
        }
        data.payment_method = stripe.secret.paymentMethodId;
        data.payment_method_options = {
          card: {
            cvc: cvcElement,
          },
        };
        setError(undefined);
        setSubmitting(true);
        const payload = await stripe.stripe.confirmCardPayment(
          stripe.secret.secret,
          data
        );
        if (payload.error) {
          setError(payload.error.message);
        } else {
          await onSuccess();
        }
        setSubmitting(false);
      }
    },
    [stripe, onSuccess, saveCard]
  );

  const handleCVCContainerClick = useCallback(() => {
    stripe?.elements.getElement('cardCvc')?.focus();
  }, [stripe]);

  return (
    <Form onSubmit={handleSubmit}>
      {loading ? (
        <LoadingContainer>
          <Loading variant="circular" size="XL" value={undefined} />
        </LoadingContainer>
      ) : (
        <>
          {stripe?.secret.requires === 'card' && (
            <>
              <NewCardContainer>
                <AddressElement options={{ mode: 'billing' }} />
                <PaymentElement onChange={handleCardChange} />
                {error && (
                  <Paragraph
                    variant="S"
                    $textColor={({ warning }) => warning['50']}
                    textAlign="right"
                  >
                    {error}
                  </Paragraph>
                )}
              </NewCardContainer>
              <Spacer size={8} />
              <SaveForLaterContainer>
                <Checkbox
                  checked={saveCard}
                  onChange={setSaveCard}
                  color="accent"
                />
                <Paragraph
                  variant="M"
                  $textColor={({ primary }) =>
                    saveCard ? undefined : primary['40']
                  }
                >
                  {t('PAYMENT_SAVE_FOR_LATER')}
                </Paragraph>
              </SaveForLaterContainer>
            </>
          )}
          {stripe?.secret.requires === 'cvc' && (
            <NewCardContainer>
              <InputContainer alert={!!error} onClick={handleCVCContainerClick}>
                <CardCvcElement
                  options={ElementsOption}
                  onChange={handleCVCChange}
                />
              </InputContainer>
              {error && (
                <Paragraph
                  variant="S"
                  $textColor={({ warning }) => warning['50']}
                  textAlign="right"
                >
                  {error}
                </Paragraph>
              )}
            </NewCardContainer>
          )}
        </>
      )}
      {fees && (
        <Fees>
          {fees.map((feeItem) => (
            <FeeRow key={feeItem.label}>
              <Paragraph
                variant="M"
                $textColor={({ primary }) => primary['40']}
              >
                {feeItem.label}
              </Paragraph>
              <FeeValue>
                <Paragraph
                  variant="M"
                  $textColor={({ primary }) => primary['40']}
                >
                  {feeItem.value}
                </Paragraph>
                <Paragraph variant="M" bold>
                  {feeItem.fee}%
                </Paragraph>
              </FeeValue>
            </FeeRow>
          ))}
        </Fees>
      )}
      <Summary hasFees={!!fees}>
        <Paragraph variant="L" $textColor={({ primary }) => primary['40']}>
          {t('CARD-PAYMENT_YOU-WILL-PAY')}
        </Paragraph>
        <Paragraph variant="L" bold>{`${currency}${fiatPrice.toFixed(
          2
        )}`}</Paragraph>
      </Summary>
      <Actions>
        <Button
          type="submit"
          disabled={disabled || !stripe || isOfferExpired}
          $loading={submitting}
          label={t('PAYMENT_PROCEED_WITH_CARD')}
          variant="filled"
          size="S"
        />
      </Actions>
      {typeof guaranteeUntil !== 'undefined' && (
        <GuaranteeUntil
          guaranteeUntil={guaranteeUntil}
          onExpired={() => setIsOfferExpired(true)}
        />
      )}
    </Form>
  );
};
