import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Blockchain, Payment } from 'ultimate-league-common';
import { fromWei, toWei } from 'web3-utils';

import { t } from '#technical/translate';
import {
  Button,
  Chip,
  CurrencyIcon,
  Icon,
  Paragraph,
  Progress,
  Spacer,
} from '#ui/components';
import { IColorPalettes, TColorValue } from '#ui/theme';

const WidgetContainer = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.spacing(32)};
  background-color: ${({ theme }) => theme.color.primary['95']};
  border-radius: 24px;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.input<{
  $background?: (palettes: IColorPalettes) => TColorValue | undefined;
}>`
  background-color: ${({ theme, $background }) =>
    $background && $background(theme.color)};
  color: ${({ theme }) => theme.color.primary['0']};
  border: none;
  font-family: 'Söhne', sans-serif;
  font-size: 22px;
  padding: 0;
  width: 186px;
  text-overflow: ellipsis;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  &:focus {
    outline-width: 0;
  }
`;

const MiddleIconContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing(8)};
  top: -22px;
  left: calc(50% - 24px);
  background-color: ${({ theme }) => theme.color.primary['90']};
`;

const BottomContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.primary['90']};
  border-radius: 24px;
  margin: -${({ theme }) => theme.spacing(32)};
  padding: ${({ theme }) => theme.spacing(32)};
`;

const BuyButton = styled(Button)`
  width: 100%;
`;

export interface IWidgetProps {
  currentChampBalance: number | undefined;
  unaChange: Payment.IUnaChange | undefined;
  isInProgress: boolean;
  onPopupOpen: (champPrice: number, fiatPrice: number) => void;
}

const DEFAULT_CHAMP_VALUE = 100;

export const Widget = ({
  currentChampBalance,
  unaChange,
  isInProgress,
  onPopupOpen,
}: IWidgetProps) => {
  const [selectedInput, setSelectedInput] = useState<
    'fiat' | 'champ' | undefined
  >('fiat');

  const [champInputPrice, setChampInputPrice] = useState<string | number>(
    DEFAULT_CHAMP_VALUE
  );

  const [fiatInputPrice, setFiatInputPrice] = useState<string | number>('');

  useEffect(() => {
    if (!unaChange) {
      return;
    }

    const price = Blockchain.Token.tokenWeiToFiat(
      toWei(Number(DEFAULT_CHAMP_VALUE).toFixed(10)),
      unaChange
    );

    setFiatInputPrice(price);
  }, [unaChange]);

  // Temp variables are needed for two-way conversion
  const [tempFiatPrice, setTempFiatPrice] = useState(fiatInputPrice);
  const [tempChampPrice, setTempChampPrice] = useState(champInputPrice);

  const champPerOneFiat = unaChange
    ? Math.round(Number(fromWei(Blockchain.Token.fiatToTokenWei(1, unaChange))))
    : '';

  const handleFiatPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempChampPrice(
      e.target.value && unaChange
        ? Math.round(
            Number(
              fromWei(
                Blockchain.Token.fiatToTokenWei(
                  Number(e.target.value),
                  unaChange
                )
              )
            )
          ).toString()
        : ''
    );
    setFiatInputPrice(e.target.value);
  };

  const handleChampPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!unaChange) {
      return;
    }

    const convertedFiatValue = Blockchain.Token.tokenWeiToFiat(
      toWei(Number(e.target.value).toFixed(10)),
      unaChange
    );

    setTempFiatPrice(convertedFiatValue ? String(convertedFiatValue) : '');
    setChampInputPrice(e.target.value ? Number(e.target.value) : '');
  };

  const handleFiatInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setFiatInputPrice(Number(e.target.value).toFixed(2));
    }

    setChampInputPrice(tempChampPrice);
  };

  const handleChampInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setChampInputPrice(
      e.target.value ? Math.round(Number(e.target.value)) : ''
    );
    setFiatInputPrice(tempFiatPrice);
  };

  const handleFiatInputSelect = () => {
    setSelectedInput('fiat');
    setTempChampPrice(champInputPrice);
  };

  const handleChampInputSelect = () => {
    setSelectedInput('champ');
    setTempFiatPrice(fiatInputPrice);
  };

  const fiatPriceToDisplay =
    selectedInput === 'champ' ? tempFiatPrice : fiatInputPrice;
  const champPriceToDisplay =
    selectedInput === 'fiat' ? tempChampPrice : champInputPrice;

  return (
    <WidgetContainer>
      <Paragraph variant="M" bold>
        {t('BUY_CHAMP_PAGE-WIDGET-BUY')}
      </Paragraph>
      <Spacer size={16} />
      <InputRow>
        <Input
          type="number"
          placeholder={t('BUY_CHAMP_PAGE-WIDGET-CHAMP_PLACEHOLDER')}
          value={champPriceToDisplay}
          onChange={handleChampPriceChange}
          onMouseDown={handleChampInputSelect}
          onBlur={handleChampInputBlur}
          $background={({ primary }) => primary['95']}
        />
        <Chip
          variant="tonal"
          size="L"
          label={t('TOKEN_UNA')}
          trailingIcon={<CurrencyIcon coin={Blockchain.Token.Token.UNA} />}
        />
      </InputRow>
      <Spacer size={8} />
      <Paragraph variant="M" $textColor={({ primary }) => primary['40']}>
        {currentChampBalance
          ? t('BUY_CHAMP_PAGE-WIDGET-CURRENT_BALANCE').replace(
              '{{balance}}',
              currentChampBalance.toFixed(2)
            )
          : '-'}
      </Paragraph>
      <Spacer size={80} />
      <BottomContainer>
        <MiddleIconContainer>
          {isInProgress ? (
            <Progress
              value={undefined}
              variant="circular"
              color="info"
              size="XL"
            />
          ) : (
            <Icon size="XL" name="cached__filled" />
          )}
        </MiddleIconContainer>
        <Paragraph variant="M" $textColor={({ primary }) => primary['40']} bold>
          {t('BUY_CHAMP_PAGE-WIDGET-FOR')}
        </Paragraph>
        <Spacer size={16} />
        <InputRow>
          <Input
            type="number"
            placeholder={t('BUY_CHAMP_PAGE-WIDGET-FIAT_PLACEHOLDER')}
            value={fiatPriceToDisplay}
            onChange={handleFiatPriceChange}
            onBlur={handleFiatInputBlur}
            onMouseDown={handleFiatInputSelect}
            disabled={isInProgress}
            $background={({ primary }) => primary['90']}
          />
          <Chip
            variant="tonal"
            size="L"
            label="USD"
            trailingIcon={<Icon name="monetization_on__filled" />}
          />
        </InputRow>
        <Spacer size={8} />
        {Number(fiatPriceToDisplay) <
          Payment.MINIMUM_FIAT_FOR_STRIPE_PAYMENTS && (
          <Paragraph variant="M" $textColor={({ warning }) => warning['50']}>
            {t('BUY_CHAMP_PAGE-WIDGET-MINIMUM_USD', {
              usd: Payment.MINIMUM_FIAT_FOR_STRIPE_PAYMENTS,
            })}
          </Paragraph>
        )}
        <Paragraph variant="M" $textColor={({ primary }) => primary['40']}>
          {`1 USD = ${champPerOneFiat} ${t('TOKEN_UNA')}`}
        </Paragraph>
        <Spacer size={24} />
        <BuyButton
          size="M"
          variant="filled"
          color="info"
          label={t('BUY_CHAMP_PAGE-WIDGET-BUY_BUTTON')}
          onClick={() =>
            onPopupOpen(Number(champPriceToDisplay), Number(fiatPriceToDisplay))
          }
          disabled={
            isInProgress ||
            Number(fiatPriceToDisplay) <
              Payment.MINIMUM_FIAT_FOR_STRIPE_PAYMENTS
          }
        />
      </BottomContainer>
    </WidgetContainer>
  );
};
