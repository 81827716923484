"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionSort = exports.AUCTIONS_PAGE_LIMIT = exports.BID_INCREMENT_MULTIPLIER = exports.LATEST_BIDS_COUNT = void 0;
exports.LATEST_BIDS_COUNT = 5;
exports.BID_INCREMENT_MULTIPLIER = 1.05;
exports.AUCTIONS_PAGE_LIMIT = 20;
var AuctionSort;
(function (AuctionSort) {
    AuctionSort["EXPIRING"] = "EXPIRING";
    AuctionSort["NEWEST"] = "NEWEST";
    AuctionSort["OLDEST"] = "OLDEST";
    AuctionSort["LOWEST_PRICE"] = "LOWEST_PRICE";
    AuctionSort["HIGHEST_PRICE"] = "HIGHEST_PRICE";
})(AuctionSort || (exports.AuctionSort = AuctionSort = {}));
