"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.request = void 0;
var localization_1 = require("../localization");
var technical_1 = require("../technical");
exports.request = {
    email: {
        email: true,
        presence: true,
    },
    username: {
        presence: {
            allowEmpty: false,
        },
    },
    message: {
        presence: {
            allowEmpty: false,
        },
    },
    lang: {
        inclusion: (0, technical_1.enumToArray)(localization_1.LANG),
    },
};
