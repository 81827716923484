import React from 'react';

import { BIconProps, getIconAttrs } from '../Icon';
import { ASSETS } from './assets';

/* Main Component */

export interface IBasketballIconProps extends BIconProps {
  name:
    | 'AverageScore'
    | 'Blocks'
    | 'MatchPlayed'
    | 'MinutesPlayed'
    | 'Points'
    | 'Rebounds'
    | 'Steals'
    | 'Bully'
    | 'Colossus'
    | 'Defiant'
    | 'DoubleDouble'
    | 'Medic'
    | 'RobbinHood'
    | 'TripleDouble';
}

export function BasketballIcon({ name, ...iconProps }: IBasketballIconProps) {
  const SVG = ASSETS[name];
  return <SVG {...getIconAttrs(iconProps)} />;
}
