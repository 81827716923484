import { FTUE } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';
import { t } from '#technical/translate';

export const getObjectiveTitle = (objective: FTUE.Objectives) => {
  switch (objective) {
    case FTUE.Objectives.GAMEWEEK:
      return t('FTUE-OBJECTIVES-GAMEWEEK_TITLE');
    case FTUE.Objectives.PACK:
      return t('FTUE-OBJECTIVES-PACK_TITLE');
    case FTUE.Objectives.REGISTER:
      return t('FTUE-OBJECTIVES-REGISTER_TITLE');
    case FTUE.Objectives.SQUAD:
      return t('FTUE-OBJECTIVES-SQUAD_TITLE');
    default:
      throw catchMissingSwitchCase(objective);
  }
};

export const getObjectiveDescription = (objective: FTUE.Objectives) => {
  switch (objective) {
    case FTUE.Objectives.GAMEWEEK:
      return t('FTUE-OBJECTIVES-GAMEWEEK_DESCRIPTION');
    case FTUE.Objectives.PACK:
      return t('FTUE-OBJECTIVES-PACK_DESCRIPTION');
    case FTUE.Objectives.REGISTER:
      return t('FTUE-OBJECTIVES-REGISTER_DESCRIPTION');
    case FTUE.Objectives.SQUAD:
      return t('FTUE-OBJECTIVES-SQUAD_DESCRIPTION');
    default:
      throw catchMissingSwitchCase(objective);
  }
};
