import { createTheme as createMuiTheme } from '@mui/material';
import { DefaultTheme } from 'styled-components';

import { IThemeConfig, TSpacingLevel } from './types';

export function createTheme({
  spacing,
  breakpoints,
  color,
}: IThemeConfig): DefaultTheme {
  return {
    color,
    spacing: (level: TSpacingLevel) => spacing[level],
    breakpoints: createMuiTheme({ breakpoints: { values: breakpoints } })
      .breakpoints,
  };
}
