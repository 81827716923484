"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.switchBrand = exports.BRAND = void 0;
var BrandValues = ['UC', 'LFP'];
var currentBrand = process.env.BRAND;
if (!currentBrand || !BrandValues.includes(currentBrand)) {
    throw new Error("Invalid env BRAND=".concat(currentBrand));
}
exports.BRAND = currentBrand;
function switchBrand(cases) {
    var c = cases[exports.BRAND];
    if (typeof c === 'function') {
        return c();
    }
    return c;
}
exports.switchBrand = switchBrand;
