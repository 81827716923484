"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Features = exports.getAppName = void 0;
var SERVICE = process.env.SERVICE;
if (!SERVICE) {
    throw new Error('Missing env SERVICE');
}
var BRAND = process.env.BRAND;
if (!BRAND) {
    throw new Error('Missing env BRAND');
}
function getAppName() {
    return "".concat(BRAND, "-").concat(SERVICE);
}
exports.getAppName = getAppName;
var Features;
(function (Features) {
    Features["TOURNAMENTS"] = "Tournaments";
    Features["MAINTENANCE"] = "Maintenance";
    Features["HOLDER_FIRST"] = "HolderFirst";
    Features["AUTO_REGISTER"] = "AutoRegister";
    Features["UNA_IO"] = "UNA_IO";
    Features["AUCTIONS"] = "Auctions";
    Features["WHITELIST_SIGNUP"] = "WhitelistSignUp";
})(Features || (exports.Features = Features = {}));
