import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { Payment, t } from 'ultimate-league-common';

import { Paragraph, Progress, Spacer } from '#ui/components';

const Transactions = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(8)};
`;

const TransactionItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(24)};
  padding: ${({ theme }) => theme.spacing(24)};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.color.primary['95']};
`;

const TransactionItemLeftColumn = styled.div`
  display: grid;
  gap: 4px;
`;

const TransactionItemRightColumn = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(16)};
`;

export interface IOngoingTransactionsProps {
  transactions: Payment.IOngoingTransaction[];
}

export const OngoingTransactions = ({
  transactions,
}: IOngoingTransactionsProps) => (
  <>
    <Spacer size={40} />
    <Paragraph variant="L" textAlign="center" bold>
      {t('BUY_CHAMP_PAGE-ONGOING_TRANSACTIONS-TITLE')}
    </Paragraph>
    <Spacer size={24} />
    <Transactions>
      {transactions.map((transaction) => (
        <TransactionItem key={transaction.id}>
          <TransactionItemLeftColumn>
            <Paragraph variant="M" bold>
              {`${transaction.champPrice.toFixed(2)} ${t('TOKEN_UNA')}`}
            </Paragraph>
            <Paragraph
              variant="S"
              $textColor={({ primary }) => primary['Light 60%']}
            >
              {`${transaction.fiatPrice.toFixed(
                2
              )} ${transaction.currency.toUpperCase()}`}
            </Paragraph>
          </TransactionItemLeftColumn>
          <TransactionItemRightColumn>
            <Paragraph
              variant="S"
              $textColor={({ primary }) => primary['Light 60%']}
            >
              {format(new Date(transaction.date), 'dd.MM.yyyy, HH:mm')}
            </Paragraph>
            <Progress
              value={undefined}
              variant="circular"
              color="info"
              size="L"
            />
          </TransactionItemRightColumn>
        </TransactionItem>
      ))}
    </Transactions>
  </>
);
