"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringEnumToObject = exports.enumToArray = void 0;
/**
 * Convert an Enumeration to a Array<string|number>
 *
 * @param enumeration Enumeration to convert
 * @param ofString Convert Enumeration to a string Array (or number Array otherwise)
 *
 * @returns Array<any>
 */
function enumToArray(enumeration, ofString) {
    if (ofString === void 0) { ofString = true; }
    return Object.keys(enumeration)
        .map(function (key) { return enumeration[key]; })
        .filter(function (value) {
        return ofString ? typeof value === 'string' : typeof value === 'number';
    });
}
exports.enumToArray = enumToArray;
function stringEnumToObject(e, valueMapper) {
    return Object.values(e).reduce(function (output, key) {
        var _a;
        return Object.assign(output, (_a = {},
            _a[key] = valueMapper(key),
            _a));
    }, {});
}
exports.stringEnumToObject = stringEnumToObject;
