"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ON_CHAIN_BALANCE_CONFIG = exports.WalletStatus = exports.isAuctionBidWalletTransaction = exports.isNftTransferWalletTransaction = exports.isDistributeWalletTransaction = exports.isWithdrawWalletTransaction = exports.isMarketplaceWalletTransaction = exports.MarketplaceTransactionErrorCode = exports.TransactionStatus = exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
    TransactionType["WITHDRAW"] = "WITHDRAW";
    TransactionType["CREATE_SALE"] = "CREATE_SALE";
    TransactionType["UPDATE_SALE"] = "UPDATE_SALE";
    TransactionType["ACCEPT_SALE"] = "ACCEPT_SALE";
    TransactionType["DESTROY_SALE"] = "DESTROY_SALE";
    TransactionType["DISTRIBUTE"] = "DISTRIBUTE";
    TransactionType["NFT_TRANSFER"] = "NFT_TRANSFER";
    TransactionType["AUCTION_BID"] = "AUCTION_BID";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["CREATED"] = "CREATED";
    TransactionStatus["SENT"] = "SENT";
    TransactionStatus["SUCCESS"] = "SUCCESS";
    TransactionStatus["FAILED"] = "FAILED";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
var MarketplaceTransactionErrorCode;
(function (MarketplaceTransactionErrorCode) {
    MarketplaceTransactionErrorCode["ALREADY_EXISTS"] = "ALREADY_EXISTS";
    MarketplaceTransactionErrorCode["NOT_AVAILABLE"] = "NOT_AVAILABLE";
    MarketplaceTransactionErrorCode["STALED"] = "STALED";
    MarketplaceTransactionErrorCode["NOT_ACCEPTED"] = "NOT_ACCEPTED";
})(MarketplaceTransactionErrorCode || (exports.MarketplaceTransactionErrorCode = MarketplaceTransactionErrorCode = {}));
function isMarketplaceWalletTransaction(walletTransaction) {
    return [
        TransactionType.DESTROY_SALE,
        TransactionType.CREATE_SALE,
        TransactionType.UPDATE_SALE,
        TransactionType.ACCEPT_SALE,
    ].includes(walletTransaction.transactionType);
}
exports.isMarketplaceWalletTransaction = isMarketplaceWalletTransaction;
function isWithdrawWalletTransaction(walletTransaction) {
    return walletTransaction.transactionType === TransactionType.WITHDRAW;
}
exports.isWithdrawWalletTransaction = isWithdrawWalletTransaction;
function isDistributeWalletTransaction(walletTransaction) {
    return walletTransaction.transactionType === TransactionType.DISTRIBUTE;
}
exports.isDistributeWalletTransaction = isDistributeWalletTransaction;
function isNftTransferWalletTransaction(walletTransaction) {
    return walletTransaction.transactionType === TransactionType.NFT_TRANSFER;
}
exports.isNftTransferWalletTransaction = isNftTransferWalletTransaction;
function isAuctionBidWalletTransaction(walletTransaction) {
    return walletTransaction.transactionType === TransactionType.AUCTION_BID;
}
exports.isAuctionBidWalletTransaction = isAuctionBidWalletTransaction;
var WalletStatus;
(function (WalletStatus) {
    WalletStatus["PENDING"] = "PENDING";
    WalletStatus["RESOLVED"] = "RESOLVED";
})(WalletStatus || (exports.WalletStatus = WalletStatus = {}));
exports.ON_CHAIN_BALANCE_CONFIG = {
    polygon: {
        minimal: 0.1,
        target: 0.15,
        maximum: 1,
    },
    bsc: {
        minimal: 0.00075,
        target: 0.0012,
        maximum: 0.0025,
    },
    chiliz: {
        minimal: 0.8,
        target: 1.6,
        maximum: 3,
    },
    base: {
        minimal: 0.000005,
        target: 0.00001,
        maximum: 0.00005,
    },
};
