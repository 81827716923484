import { useCallback, useEffect, useState } from 'react';
import { Blockchain, Payment } from 'ultimate-league-common';
import { fromWei } from 'web3-utils';

import { fetchOngoingChampSales } from '#common/payment/service';
import { useUserAccount } from '#common/store';
import { useTokenChange } from '#common/store/tokenChange';
import { useAuthorization } from '#technical/network/authorization';

import { IBuyChampPageProps } from './BuyChampPage';

export type IBuyChampPageHook = Pick<
  IBuyChampPageProps,
  'currentChampBalance' | 'ongoingTransactions' | 'unaChange'
> & { fetchTransactions: () => Promise<void> };

export const useBuyChampPage = (): IBuyChampPageHook => {
  const { balances, refetch: refetchAccount } = useUserAccount();
  const auth = useAuthorization();
  const unaChange = useTokenChange()?.una;

  const [transactions, setTransactions] =
    useState<Payment.IOngoingTransaction[]>();

  const champTokenBalance = balances[Blockchain.Token.Token.UNA];

  const currentChampBalance = champTokenBalance
    ? Number(fromWei(champTokenBalance, 'ether'))
    : undefined;

  const fetchTransactions = useCallback(async () => {
    const ongoingTransactions = await fetchOngoingChampSales(auth);
    setTransactions(ongoingTransactions);

    await refetchAccount();
  }, [auth, refetchAccount]);

  useEffect(() => {
    // Fetch transactions initially
    fetchTransactions();

    // Check for ongoing transactions every 5 seconds
    const intervalId = setInterval(fetchTransactions, 5000);

    // Cleanup function to stop interval when component unmounts
    return () => clearInterval(intervalId);
  }, [fetchTransactions]);

  return {
    currentChampBalance,
    ongoingTransactions: transactions,
    unaChange,
    fetchTransactions,
  };
};
