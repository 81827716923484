import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ITutorialStep } from '../../types';
import { SingleStep } from '../SingleStep';

const Container = styled.div`
  overflow: hidden;
  max-width: 400px;
`;

const Frame = styled.div<{ offset: number; total: number }>`
  display: grid;
  grid-auto-flow: column;
  margin-left: ${({ offset }) => offset * -100}%;
  width: ${({ total }) => total * 100}%;
  transition: margin-left 300ms;
`;

/* Main Component */

export interface IMultiStepProps {
  steps: ITutorialStep[];
  initialStep?: number;
  onDone(): void;
}

/**
 * Use `MultiStep` to display a sequence of useful information relating to a certain aspect of the app
 */
export function MultiStep({ steps, onDone }: IMultiStepProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = useCallback(
    () =>
      setCurrentIndex((current) => {
        const nextValue = current + 1;
        if (nextValue === steps.length) {
          onDone();
          return current;
        }

        return nextValue;
      }),
    [steps.length, onDone]
  );

  return (
    <Container>
      <Frame offset={currentIndex} total={steps.length}>
        {steps.map((step) => (
          <SingleStep key={step.title} data={step} onDone={handleNext} />
        ))}
      </Frame>
    </Container>
  );
}
