import httpStatus from 'http-status';
import { GameView } from 'ultimate-league-common';

import { buildQuery, fetchApi } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';

export async function fetchGameViewManagers(
  { division }: GameView.IGetGameWeekRankedPlayers['params'],
  query: GameView.IGetGameWeekRankedPlayers['query'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameView.IGetGameWeekRankedPlayers['response']> {
  const res = await fetchApi(
    `/gameview/${division}/players?${buildQuery(query)}`,
    { signal },
    auth
  );
  return res.json();
}

export async function fetchGamePlayer(
  { division, user }: GameView.IGetGamePlayer['params'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameView.IGetGamePlayer['response'] | undefined> {
  const response = await fetchApi(
    `/gameview/${division}/${user}`,
    { signal },
    auth
  );

  if (response.status === httpStatus.NO_CONTENT) {
    return undefined;
  }

  return response.json();
}

export async function deleteTeam(
  query: GameView.IDeleteTeam['query'],
  { gamePlayer }: GameView.IDeleteTeam['params'],
  auth: IAuthorization
) {
  await fetchApi(
    `/gameview/${gamePlayer}?${buildQuery(query)}`,
    { method: 'DELETE' },
    auth
  );
}

export async function fetchTournamentEligibility(
  {
    params: { gameWeekPosition, gameView },
    query: { sport },
  }: Omit<GameView.IGetTournamentEligibility, 'response'>,
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<GameView.IGetTournamentEligibility['response']> {
  const response = await fetchApi(
    `/gameview/${gameWeekPosition}/${gameView}/eligibility?sport=${sport}`,
    { signal },
    auth
  );
  return response.json();
}
