import { Blockchain, User } from 'ultimate-league-common';

import { fetchApi, submitData } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';

export async function fetchUser(
  userId: User.IUser['id'],
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<User.IMinimalUser> {
  const response = await fetchApi(`/user/${userId}`, { signal }, auth);
  return response.json();
}

export async function fetchUserByAddress(
  walletAddress: Blockchain.Address,
  signal?: AbortSignal
): Promise<User.IMinimalUser> {
  const response = await fetchApi(
    `/user/address/${Blockchain.withoutPrefix(walletAddress)}`,
    { signal }
  );
  return response.json();
}

export async function fetchMyAccount(
  auth: IAuthorization
): Promise<User.IUserAccount> {
  const response = await fetchApi('/user/me', undefined, auth);
  return response.json();
}
export async function getKYC(
  auth: IAuthorization,
  signal?: AbortSignal
): Promise<User.KYC.IGetKYC['response']> {
  const response = await fetchApi('/user/kyc', { signal }, auth);
  return response.json();
}

export function initiateWorkflowExecution(
  auth: IAuthorization
): Promise<User.KYC.IInitiateWorkflowExecution['response']> {
  return submitData('/user/kyc', {}, auth);
}

export function disconnectDiscord(auth: IAuthorization) {
  return submitData(`/user/discord/disconnect`, {}, auth);
}

export function connectDiscord(code: string, auth: IAuthorization) {
  return submitData(`/user/discord/connect`, { code }, auth, {
    headers: { 'Content-Type': 'application/json' },
  });
}
