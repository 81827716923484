import { useFlagsStatus } from '@unleash/proxy-client-react';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Blockchain,
  FeatureFlags,
  FTUE,
  Prize as PrizeCommon,
  Storage as StorageCommon,
  User,
  Wallet as WalletCommon,
} from 'ultimate-league-common';
import { fromWei } from 'web3-utils';

import { useBootstrap } from '#common/bootstrap';
import { GameWeeksStore } from '#common/game-week';
import { useFooterConfig, useHeaderConfig } from '#common/navigation';
import { PackOpeningPopup, usePackOpeningPopup } from '#common/pack';
import {
  buildRoutePath,
  getRoutePath,
  replaceSportParam,
} from '#common/routing';
import { useCurrentSport } from '#common/sport';
import {
  TotalParticipantsContainer,
  usePrizes,
  useUserAccount,
} from '#common/store';
import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';
import { useFlag } from '#technical/feature-flags';
import { useAuthorization } from '#technical/network/authorization';
import { getPublicURL } from '#technical/storage';

import { Account } from './account';
import { Athlete } from './athlete';
import { Auction } from './auction';
import { Auctions } from './auctions';
import { BuyChampRoute } from './buy-champ/BuyChampRoute';
import { Card } from './card';
import { Collection } from './collection';
import { Contact } from './contact';
import {
  claimObjectiveReward,
  FTUEGuard,
  IObjectivesProps,
  Objectives,
  useObjectives,
} from './ftue';
import { Leagues } from './leagues';
import { Marketplace } from './marketplace';
import { Footer, Header, IHeaderProps } from './navigation';
import { PacksRouter } from './packs';
import { ReferralProgram } from './referral';
import { Rewards } from './rewards';
import { GameRoute } from './routing';
import { Tournaments } from './tournaments';
import { Wallet } from './wallet';

const PlayRouter = () => (
  <FTUEGuard enforceDraft>
    <GameWeeksStore>
      <TotalParticipantsContainer>
        <Switch>
          <Route
            path={[
              getRoutePath('LEAGUES'),
              getRoutePath('LEAGUE_INVITATION'),
              getRoutePath('LEAGUE_INVITATION_LEGACY'),
            ]}
            component={Leagues}
          />

          <Route
            path={[getRoutePath('TOURNAMENTS'), getRoutePath('TOURNAMENT')]}
            component={Tournaments}
          />
        </Switch>
      </TotalParticipantsContainer>
    </GameWeeksStore>
  </FTUEGuard>
);

/* Styled Components */

const RoutePlaceholder = styled.div`
  height: 100vh;
`;

/* Main Component */

export function Game() {
  const { account, balances, refetch: refetchAccount } = useUserAccount();
  const auth = useAuthorization();
  const { pathname } = useLocation();
  const { replace, push } = useHistory();
  const tournamentsEnabled = useFlag(FeatureFlags.Features.TOURNAMENTS);
  const unaIOEnabled = useFlag(FeatureFlags.Features.UNA_IO);
  const auctionsEnabled = useFlag(FeatureFlags.Features.AUCTIONS);
  const { flagsReady } = useFlagsStatus();

  const { sport: currentSport, switchSport } = useCurrentSport();

  const handleChangeSport: IHeaderProps['onSportChange'] = (sport) => {
    const nextPath = replaceSportParam(pathname, sport);
    if (nextPath) replace(nextPath);
    switchSport(sport);
  };

  useBootstrap();

  useEffect(() => {
    const wonderChatWrapper = document.getElementById('wonderchat-wrapper');

    if (wonderChatWrapper) {
      wonderChatWrapper.style.display = 'block';
    }
  }, []);

  const { objectives, allObjectivesClaimed } = useObjectives(
    account?.data[currentSport].ftue,
    currentSport
  );
  const handleClaimObjective = useCallback(
    async (params: User.IClaimObjectiveReward['body']) => {
      const { items } = await claimObjectiveReward(params, auth);

      await refetchAccount();

      return items;
    },
    [auth, refetchAccount]
  );
  const handleObjectiveRedirect = useCallback<
    IObjectivesProps['onRedirectToObjective']
  >(
    (objective) => {
      switch (objective) {
        case FTUE.Objectives.REGISTER:
        case FTUE.Objectives.GAMEWEEK:
          return push(buildRoutePath('LEAGUES', { sport: currentSport }));
        case FTUE.Objectives.PACK:
          return push(buildRoutePath('PACK_STORE', { sport: currentSport }));
        case FTUE.Objectives.SQUAD:
          return push(buildRoutePath('FTUE', { sport: currentSport }));
        default:
          throw catchMissingSwitchCase(objective);
      }
    },
    [currentSport, push]
  );

  const { showPackOpeningPopup, props: packOpeningPopupsProps } =
    usePackOpeningPopup<User.IClaimObjectiveReward['body']>(
      handleClaimObjective
    );

  const { items: prizes } = usePrizes();

  const rewards = prizes.filter(
    ({ pack, context }) =>
      !pack.open &&
      pack.sport === currentSport &&
      (context.contextType === PrizeCommon.ContextType.GAMEMODE ||
        context.eventType === PrizeCommon.EventType.REFERRAL_INVITEE ||
        context.eventType === PrizeCommon.EventType.REFERRAL_INVITER)
  );

  const champTokenBalance = balances[Blockchain.Token.Token.UNA];
  const mgcTokenBalance = balances[Blockchain.Token.Token.ULC];

  const headerConfig = useHeaderConfig(currentSport);
  const footerConfig = useFooterConfig(currentSport);

  const walletIsLoading =
    !account?.wallet ||
    account.wallet.status === WalletCommon.WalletStatus.PENDING;

  const pendingTransactions =
    account?.wallet?.transactions?.filter(
      ({ status }) =>
        status === WalletCommon.TransactionStatus.CREATED ||
        status === WalletCommon.TransactionStatus.SENT
    ) || [];

  if (!flagsReady) return <RoutePlaceholder />;

  return (
    <>
      <Header
        {...headerConfig}
        currentSport={
          headerConfig.sports.find((sport) => sport.type === currentSport)!
        }
        onSportChange={handleChangeSport}
        rewards={rewards.length}
        userData={
          account && {
            userName: account.username ?? '',
            email: account.email,
            image: account.hasProfilePicture
              ? getPublicURL({
                  storageType:
                    StorageCommon.StorageType.PUBLIC_USER_PROFILE_PICTURE,
                  id: account.id,
                  profilePictureVersion: account.profilePictureVersion,
                })
              : undefined,
          }
        }
        wallet={{
          champ: champTokenBalance
            ? Number(fromWei(champTokenBalance, 'ether'))
            : 0,
          mgc: mgcTokenBalance ? Number(fromWei(mgcTokenBalance, 'ether')) : 0,
          url: buildRoutePath('WALLET', {}),
          isLoading:
            !account ||
            !champTokenBalance ||
            !mgcTokenBalance ||
            walletIsLoading ||
            pendingTransactions.length > 0,
        }}
      />
      <Suspense fallback={<RoutePlaceholder />}>
        <Switch>
          <Route
            exact
            path={[
              getRoutePath('LEAGUES'),
              getRoutePath('LEAGUE_INVITATION'),
              getRoutePath('LEAGUE_INVITATION_LEGACY'),
              ...(tournamentsEnabled
                ? [getRoutePath('TOURNAMENTS'), getRoutePath('TOURNAMENT')]
                : []),
            ]}
            component={PlayRouter}
          />
          <Route
            exact
            path={getRoutePath('MARKETPLACE')}
            component={Marketplace}
          />
          {auctionsEnabled && [
            <Route
              key="auction"
              exact
              path={getRoutePath('AUCTION')}
              component={Auction}
            />,
            <Route
              key="auctions"
              exact
              path={getRoutePath('AUCTIONS')}
              component={Auctions}
            />,
          ]}
          <Route
            exact
            path={getRoutePath('COLLECTION')}
            component={Collection}
          />
          <Route
            exact
            path={[getRoutePath('FUNGIBLE_CARD'), getRoutePath('NFT_CARD')]}
            component={Card}
          />
          <Route path={getRoutePath('ATHLETE')} component={Athlete} />
          <Route
            exact
            path={[getRoutePath('PACK_DETAILS'), getRoutePath('PACK_STORE')]}
            component={PacksRouter}
          />
          <Route exact path={getRoutePath('ACCOUNT')} component={Account} />
          <Route exact path={getRoutePath('WALLET')} component={Wallet} />
          <Route exact path={getRoutePath('REWARDS')} component={Rewards} />
          <Route
            exact
            path={getRoutePath('REFERRAL_PROGRAM')}
            component={ReferralProgram}
          />
          {unaIOEnabled && (
            <Route
              exact
              path={getRoutePath('BUY_CHAMP')}
              component={BuyChampRoute}
            />
          )}
          <Route exact path={getRoutePath('CONTACT')} component={Contact} />

          <Route
            exact
            path={[getRoutePath('GAME'), '*']}
            component={GameRoute}
          />
        </Switch>

        {(!allObjectivesClaimed || packOpeningPopupsProps.isVisible) && (
          <Suspense fallback={null}>
            <Objectives
              objectives={objectives}
              onClaimObjective={({ reward, objective }) =>
                showPackOpeningPopup(reward, {
                  ftueObjective: objective,
                  sport: currentSport,
                })
              }
              onRedirectToObjective={handleObjectiveRedirect}
            />
            <PackOpeningPopup {...packOpeningPopupsProps} />
          </Suspense>
        )}
      </Suspense>

      <Footer {...footerConfig} />
    </>
  );
}
