import { t } from 'ultimate-league-common';

import { ITutorialSteps } from '#common/tutorial';

import { DraftBudget, DraftGameweek, DraftIntro0, DraftIntro1 } from './assets';
import { ITutorialSequence } from './types';

export function getTutorialSequences() {
  const DRAFT_TUTORIAL: ITutorialSequence<ITutorialSteps['DRAFT']> = {
    INTRO: [
      {
        image: DraftIntro0,
        title: t('TUTORIAL-DRAFT-INTRO-0_TITLE'),
        message: t('TUTORIAL-DRAFT-INTRO-0_MESSAGE'),
        button: t('TUTORIAL-DRAFT-INTRO-0_BUTTON'),
      },
      {
        image: DraftIntro1,
        title: t('TUTORIAL-DRAFT-INTRO-1_TITLE'),
        message: t('TUTORIAL-DRAFT-INTRO-1_MESSAGE'),
        button: t('TUTORIAL-DRAFT-INTRO-1_BUTTON'),
      },
    ],
    BUDGET: {
      image: DraftBudget,
      title: t('TUTORIAL-DRAFT-BUDGET_TITLE'),
      message: t('TUTORIAL-DRAFT-BUDGET_MESSAGE'),
      button: t('TUTORIAL-DRAFT-BUDGET_BUTTON'),
    },
    GAMEWEEK: {
      image: DraftGameweek,
      title: t('TUTORIAL-DRAFT-GAMEWEEK_TITLE'),
      message: t('TUTORIAL-DRAFT-GAMEWEEK_MESSAGE'),
      button: t('TUTORIAL-DRAFT-GAMEWEEK_BUTTON'),
    },
  };

  return {
    DRAFT: DRAFT_TUTORIAL,
  };
}
