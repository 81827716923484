"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QUEUE = void 0;
var QUEUE;
(function (QUEUE) {
    QUEUE["CREATE_WALLET_SUCCESS"] = "CREATE_WALLET_SUCCESS";
    QUEUE["MINT_SUCCESS"] = "MINT_SUCCESS";
    QUEUE["DISTRIBUTE_SUCCESS"] = "DISTRIBUTE_SUCCESS";
    QUEUE["NFT_TRANSFERRED"] = "NFT_TRANSFERRED";
    QUEUE["TOKEN_PAYMENT_REFUNDED"] = "TOKEN_PAYMENT_REFUNDED";
    QUEUE["TOKEN_PAYMENT_EXECUTED"] = "TOKEN_PAYMENT_EXECUTED";
    QUEUE["SALE_CREATED"] = "SALE_CREATED";
    QUEUE["SALE_UPDATED"] = "SALE_UPDATED";
    QUEUE["SALE_ACCEPTED"] = "SALE_ACCEPTED";
    QUEUE["SALE_DESTROYED"] = "SALE_DESTROYED";
    QUEUE["TRANSACTION_STATUS_CHANGE"] = "TRANSACTION_STATUS_CHANGE";
    QUEUE["TOKEN_TRANSFERRED"] = "TOKEN_TRANSFERRED";
})(QUEUE || (exports.QUEUE = QUEUE = {}));
