import { ComponentClass, SVGProps } from 'react';

import { IBrandIconProps } from '../BrandIcon';
import AppleColored from './colored/Apple.svg';
import DiscordColored from './colored/Discord.svg';
import FacebookColored from './colored/Facebook.svg';
import GoogleColored from './colored/Google.svg';
import InstagramColored from './colored/Instagram.svg';
import LinkedInColored from './colored/LinkedIn.svg';
import MessengerColored from './colored/Messenger.svg';
import MetamaskColored from './colored/Metamask.svg';
import RedditColored from './colored/Reddit.svg';
import WhatsappColored from './colored/Whatsapp.svg';
import AppleMono from './mono/Apple.svg';
import DiscordMono from './mono/Discord.svg';
import FacebookMono from './mono/Facebook.svg';
import GoogleMono from './mono/Google.svg';
import InstagramMono from './mono/Instagram.svg';
import LinkedInMono from './mono/LinkedIn.svg';
import MessengerMono from './mono/Messenger.svg';
import MetamaskMono from './mono/Metamask.svg';
import RedditMono from './mono/Reddit.svg';
import TikTokMono from './mono/TikTok.svg';
import TwitchMono from './mono/Twitch.svg';
import TwitterMono from './mono/Twitter.svg';
import WhatsappMono from './mono/Whatsapp.svg';

export const ASSETS: Record<
  IBrandIconProps['brand'],
  Record<IBrandIconProps['variant'], ComponentClass<SVGProps<SVGSVGElement>>>
> = {
  Apple: {
    mono: AppleMono,
    colored: AppleColored,
  },
  Discord: {
    mono: DiscordMono,
    colored: DiscordColored,
  },
  Facebook: {
    mono: FacebookMono,
    colored: FacebookColored,
  },
  Google: {
    mono: GoogleMono,
    colored: GoogleColored,
  },
  Instagram: {
    mono: InstagramMono,
    colored: InstagramColored,
  },
  LinkedIn: {
    mono: LinkedInMono,
    colored: LinkedInColored,
  },
  Messenger: {
    mono: MessengerMono,
    colored: MessengerColored,
  },
  Metamask: {
    mono: MetamaskMono,
    colored: MetamaskColored,
  },
  Reddit: {
    mono: RedditMono,
    colored: RedditColored,
  },
  TikTok: {
    mono: TikTokMono,
    colored: TikTokMono,
  },
  Twitch: {
    mono: TwitchMono,
    colored: TwitchMono,
  },
  Twitter: {
    mono: TwitterMono,
    colored: TwitterMono,
  },
  Whatsapp: {
    mono: WhatsappMono,
    colored: WhatsappColored,
  },
};
