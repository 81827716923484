import BN from 'bn.js';
import { createContext, useContext } from 'react';
import { Blockchain, User } from 'ultimate-league-common';

import { createStore } from './createStore';

export interface IUserAccount {
  refetch: () => Promise<User.IUserAccount>;

  account?: User.IUserAccount;
  balances: Partial<Record<Blockchain.Token.Token, BN>>;
}

function willThrow() {
  throw new Error('UserAccount context is not initialized');
}

export const UserAccountContext = createContext<IUserAccount>({
  balances: {},
  refetch: willThrow as unknown as IUserAccount['refetch'],
});

export const useUserAccount = () => useContext(UserAccountContext);

export const [usersContext, UsersProvider, useUsers] =
  createStore<User.IMinimalUser>(({ id }) => id);
