import React, { FC } from 'react';
import styled from 'styled-components';

import { Paragraph, Skeleton, Spacer } from '#ui/components';

const PopupHeaderContainer = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: ${({ theme }) => theme.spacing(40)};
  padding-bottom: ${({ theme }) => theme.spacing(32)};
  padding-inline: ${({ theme }) => theme.spacing(32)};
  background-color: ${({ theme }) => theme.color.primary['95']};
`;

const PopupHeaderDetails = styled.div`
  display: flex;
  gap: 4px;
`;

export interface IPopupHeaderProps {
  isLoading: boolean;
  title?: string;
  name?: React.ReactNode;
}

export const PopupHeader: FC<IPopupHeaderProps> = ({
  isLoading,
  title,
  name,
  children,
}) => (
  <PopupHeaderContainer>
    {isLoading ? (
      <Skeleton variant="text" height={19} width="25%" />
    ) : (
      <Paragraph variant="L" $textColor={({ primary }) => primary['60']}>
        {title}
      </Paragraph>
    )}
    <Spacer size={24} />
    {isLoading ? (
      <Skeleton variant="text" height={26} width="75%" />
    ) : (
      <Paragraph variant="XXL">{name}</Paragraph>
    )}
    <Spacer size={16} />
    {isLoading ? (
      <Skeleton variant="text" height={20} width="50%" />
    ) : (
      <PopupHeaderDetails>{children}</PopupHeaderDetails>
    )}
  </PopupHeaderContainer>
);
