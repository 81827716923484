import React from 'react';

import { BIconProps, getIconAttrs } from '../Icon';
import { ASSETS } from './assets';

/* Main Component */

export interface ICustomIconProps extends BIconProps {
  name:
    | 'BasketballJersey'
    | 'Bid'
    | 'Cards'
    | 'CardsMono'
    | 'CHZ'
    | 'FootballJersey'
    | 'Gift'
    | 'Infinity'
    | 'Persona'
    | 'Season'
    | 'Trophy';
}

export function CustomIcon({ name, ...iconProps }: ICustomIconProps) {
  const SVG = ASSETS[name];
  return <SVG {...getIconAttrs(iconProps)} />;
}
