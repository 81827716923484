import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Pack } from 'ultimate-league-common';
import { useInterval } from 'usehooks-ts';

import { fetchPacksToOpen } from '#common/pack';
import { useCurrentSport } from '#common/sport';
import { useAuthorization } from '#technical/network/authorization';

import { CONTEXT_REFRESH_INTERVAL } from '../config';
import { IPacksByPackConfig } from '../types';

interface IPacksToOpenContext {
  refresh: () => Promise<void>;
  getPacksToOpen: (packConfigId: Pack.IPackConfig['id']) => Pack.PackId[];
  getPackConfig: (
    packConfigId: Pack.IPackConfig['id']
  ) => Pack.IPackConfig | undefined;
  getPackConfigIds: () => Pack.IPackConfig['id'][];
}

function willThrow(): any {
  throw new Error('Ongoing pack sales context not initialized');
}

const PacksToOpenContext = createContext<IPacksToOpenContext>({
  refresh: willThrow,
  getPacksToOpen: willThrow,
  getPackConfig: willThrow,
  getPackConfigIds: willThrow,
});

export const usePacksToOpenContext = () => useContext(PacksToOpenContext);

export const PacksToOpenProvider = ({ children }: PropsWithChildren<{}>) => {
  const { sport } = useCurrentSport();
  const auth = useAuthorization();
  const [packsToOpen, setPacksToOpen] = useState<
    IPacksByPackConfig | undefined
  >();

  const refresh = useCallback(async () => {
    setPacksToOpen(await fetchPacksToOpen({ sport }, auth));
  }, [auth, setPacksToOpen, sport]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useInterval(() => refresh(), CONTEXT_REFRESH_INTERVAL);

  const getPacksToOpen = useCallback(
    (packConfigId: Pack.IPackConfig['id']) =>
      packsToOpen?.[packConfigId]?.packs || [],
    [packsToOpen]
  );
  const getPackConfig = useCallback(
    (packConfigId: Pack.IPackConfig['id']) =>
      packsToOpen?.[packConfigId]?.packConfig,
    [packsToOpen]
  );
  const getPackConfigIds = useCallback(
    () => (packsToOpen ? Object.keys(packsToOpen) : []),
    [packsToOpen]
  );

  return (
    <PacksToOpenContext.Provider
      value={{ getPacksToOpen, getPackConfig, getPackConfigIds, refresh }}
    >
      {children}
    </PacksToOpenContext.Provider>
  );
};
