import { LOADING, TLazyData, TLoadingState } from './types';

/**
 * Checks if the given data is loaded.
 */
export function isLoaded<Data>(data: TLazyData<Data>): data is Data {
  return data !== LOADING;
}

/**
 * Checks if the provided data is in the loading state.
 */
export function isLoading<Data>(data: TLazyData<Data>): data is TLoadingState {
  return data === LOADING;
}

/**
 * Converts a data value to a lazy data value (`undefined` is treated as a loading state).
 */
export function toLazyData<Data>(data: Data | undefined): TLazyData<Data> {
  if (data === undefined) return LOADING;
  return data;
}

/**
 * Convers a lazy data value to undedined, if the data is loading.
 */
export function fromLazyData<Data>(data: TLazyData<Data>): Data | undefined {
  if (isLoading(data)) return undefined;
  return data;
}

/**
 * Picks a property from a lazy data value and turns its value into a lazy data value.
 */
export function lazyProp<
  Data,
  DataType extends NonNullable<Data>,
  Key extends DataType extends never ? never : keyof DataType
>(
  data: TLazyData<Data>,
  key: Key
): TLazyData<
  Data extends undefined | null ? DataType[Key] | undefined : DataType[Key]
>;
/**
 * Picks a property from a lazy data value and turns its value into a lazy data value, with a fallback value that is used while the data is laoding
 */
export function lazyProp<
  Data,
  DataType extends NonNullable<Data>,
  Key extends DataType extends never ? never : keyof DataType,
  Fallback extends DataType extends never ? never : DataType[Key]
>(data: TLazyData<Data>, key: Key, fallback: Fallback): Fallback;
export function lazyProp<
  Data,
  DataType extends NonNullable<Data>,
  Key extends DataType extends never ? never : keyof DataType,
  Fallback extends DataType extends never ? never : DataType[Key]
>(data: TLazyData<Data>, key: Key, fallback?: Fallback) {
  if (isLoading(data)) return arguments.length === 3 ? fallback : LOADING;

  return (data as any)?.[key];
}

/**
 * Transforms a lazy data value into a custom lazy data value.
 */
export function lazyDerivative<Data, Result>(
  data: TLazyData<Data>,
  picker: (data: Data) => Result
): TLazyData<Result>;
/**
 * Transforms a lazy data value into a custom lazy data value with a fallback value that will be used while the data is laoding.
 */
export function lazyDerivative<Data, Result>(
  data: TLazyData<Data>,
  picker: (data: Data) => Result,
  fallback: Result
): Result;
export function lazyDerivative<Data, Result>(
  data: TLazyData<Data>,
  picker: (data: Data) => Result,
  fallback?: Result
) {
  if (isLoading(data)) return arguments.length === 3 ? fallback : LOADING;
  return picker(data);
}
