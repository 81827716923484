import { Authorization } from 'ultimate-league-common';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    grecaptcha: {
      ready(cb: () => void): void;
      execute(siteKey: string, { action }: { action: string }): Promise<string>;
    };
  }
}

const { GRECAPTCHA_API_KEY } = process.env;
if (!GRECAPTCHA_API_KEY) {
  throw new Error('Missing env GRECAPTCHA_API_KEY');
}

export async function getRecaptchaToken(
  action: Authorization.RecaptchaAction | 'contact'
) {
  await new Promise<void>((resolve) => window.grecaptcha.ready(resolve));
  return window.grecaptcha.execute(GRECAPTCHA_API_KEY!, {
    action,
  });
}

export async function getRecaptchaHeader(
  action: Authorization.RecaptchaAction
) {
  return {
    [Authorization.AUTHORIZATION_RECAPTCHA_HEADER]: await getRecaptchaToken(
      action
    ),
  };
}
