import * as React from 'react';
import { Payment } from 'ultimate-league-common';

import { Billing } from '#ui/legacy/icons';

interface IProps {
  brand: Payment.CardBrand;
}

interface ISize {
  height: number | string;
  width: number | string;
}

// TODO: We need to add these card brands to `BrandIcon` when we get all card designs
// https://www.figma.com/file/IrtfA1C5rYcrsqMxgnGGEK/Icons?node-id=1%3A76&t=K6CjYtsihTZ3LMLo-0
const CardBrandIcons: Record<
  Payment.CardBrand,
  React.JSXElementConstructor<Partial<ISize>>
> = {
  [Payment.CardBrand.AMEX]: Billing.Amex,
  [Payment.CardBrand.DINERSCLUB]: Billing.DinersClub,
  [Payment.CardBrand.DISCOVER]: Billing.Discover,
  [Payment.CardBrand.JCB]: Billing.JCB,
  [Payment.CardBrand.MASTERCARD]: Billing.Mastercard,
  [Payment.CardBrand.VISA]: Billing.Visa,
};

export const CardBrandIcon = ({ brand }: IProps) => {
  const Icon = CardBrandIcons[brand] || null;

  return Icon && <Icon height={24} width={24} />;
};
