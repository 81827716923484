import { useEffect, useRef } from 'react';

export function useLazyRef<S>(state: S) {
  const ref = useRef(state);
  useEffect(() => {
    ref.current = state;
  }, [state]);

  return ref;
}
