"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonPosition = exports.PersonTypeName = exports.GameStatus = void 0;
var GameStatus;
(function (GameStatus) {
    GameStatus["Planned"] = "Planned";
    GameStatus["Confirmed"] = "Confirmed";
    GameStatus["Suspended"] = "Suspended";
    GameStatus["Postponed"] = "Postponed";
    GameStatus["Cancelled"] = "Cancelled";
    GameStatus["Walkover"] = "Walkover";
})(GameStatus || (exports.GameStatus = GameStatus = {}));
var PersonType;
(function (PersonType) {
    PersonType["Z"] = "Z";
    PersonType["J"] = "J";
    PersonType["D"] = "D";
    PersonType["E"] = "E";
    PersonType["F"] = "F";
})(PersonType || (PersonType = {}));
var PersonTypeName;
(function (PersonTypeName) {
    PersonTypeName["UNIFIED_SCORE_CREW"] = "Unified_Score_Crew";
    PersonTypeName["PLAYER"] = "Player";
    PersonTypeName["DOCTOR"] = "Doctor";
    PersonTypeName["COACH"] = "Coach";
    PersonTypeName["TEAM_FOLLOWER"] = "Team_Follower";
})(PersonTypeName || (exports.PersonTypeName = PersonTypeName = {}));
var PersonPosition;
(function (PersonPosition) {
    PersonPosition[PersonPosition["GUARD"] = 1] = "GUARD";
    PersonPosition[PersonPosition["FORWARD"] = 2] = "FORWARD";
    PersonPosition[PersonPosition["CENTER"] = 3] = "CENTER";
})(PersonPosition || (exports.PersonPosition = PersonPosition = {}));
