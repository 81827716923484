import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ultimate-league-common';

import { Icon, Paragraph } from '#ui/components';

const GuaranteeUntilContainer = styled.div<{ isExpired: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacing(24)};

  color: ${({ theme, isExpired }) =>
    isExpired ? theme.color.warning['50'] : theme.color.primary['40']};
`;

interface IGuaranteeUntilProps {
  guaranteeUntil: Date;
  onExpired(): void;
}

export const GuaranteeUntil = ({
  guaranteeUntil,
  onExpired,
}: IGuaranteeUntilProps) => {
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(guaranteeUntil, new Date())
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          clearInterval(intervalId);
          onExpired();
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [guaranteeUntil, onExpired]);

  const isExpired = secondsLeft <= 0;

  return (
    <GuaranteeUntilContainer isExpired={isExpired}>
      {secondsLeft !== undefined && (
        <>
          <Icon name="timer__outlined" size="M" />
          <Paragraph variant="S">
            {isExpired
              ? t('PAYMENT_GUARANTEE_EXPIRED')
              : t('PAYMENT_GUARANTEE_UNTIL').replace(
                  '{{seconds}}',
                  String(secondsLeft)
                )}
          </Paragraph>
        </>
      )}
    </GuaranteeUntilContainer>
  );
};
