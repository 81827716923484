import React, { forwardRef, MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { IconSlot, TIconProp } from '../../icons';
import { Paragraph } from '../../typography';
import { computeColors } from './Chip.config';

/* Styled Components */

const Container = styled.div<
  Pick<Required<IChipProps>, 'variant' | 'color' | 'size' | 'disabled'> & {
    compactPaddingLeft: boolean;
    compactPaddingRight: boolean;
  }
>`
  ${({ variant, color, size, disabled, onClick, theme }) => css`
    // Layout
    display: flex;
    align-items: center;

    outline-width: 1px;
    outline-offset: -1px;
    outline-style: solid;
    border-radius: 100px;

    white-space: nowrap;

    // Sizing
    box-sizing: border-box;
    height: ${() => {
      switch (size) {
        case 'L':
          return '40px';
        case 'M':
          return '32px';
        case 'S':
          return '24px';
        case 'XS':
          return '20px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};
    min-width: ${() => {
      switch (size) {
        case 'L':
          return '40px';
        case 'M':
          return '32px';
        case 'S':
          return '24px';
        case 'XS':
          return '20px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};
    padding: ${() => {
      switch (size) {
        case 'L':
          return '12px 8px';
        case 'M':
          return '8px 6px';
        case 'S':
          return '4px';
        case 'XS':
          return '2px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};

    // Colors
    ${() => {
      const colorConfig = computeColors(theme.color)[color][variant];

      if (disabled)
        return css`
          color: ${colorConfig.disabled.text};
          background-color: ${colorConfig.disabled.background};
          outline-color: ${colorConfig.disabled.border || 'transparent'};
        `;

      return css`
        color: ${colorConfig.default.text};
        background-color: ${colorConfig.default.background};
        outline-color: ${colorConfig.default.border || 'transparent'};

        ${onClick &&
        css`
          &.hover,
          &:hover {
            color: ${colorConfig.hover.text};
            outline-color: ${colorConfig.hover.border};
            background-color: ${colorConfig.hover.background};
          }

          &.focus,
          &:focus {
            outline: none;
            color: ${colorConfig.focused.text};
            outline-color: ${colorConfig.focused.border};
            background-color: ${colorConfig.focused.background};
          }

          &.active,
          &:active {
            color: ${colorConfig.pressed.text};
            outline-color: ${colorConfig.pressed.border};
            background-color: ${colorConfig.pressed.background};
          }
        `}
      `;
    }}

    // Interaction
    cursor: ${onClick && !disabled ? 'pointer' : undefined};
    transition: all 300ms;
  `}
`;

const Content = styled(Paragraph)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-inline: 4px;
  gap: 4px;
  flex-grow: 2;

  &:empty {
    display: none;
  }
`;

/* Main Component */
export interface IChipProps {
  label?: ReactNode;
  variant: 'filled' | 'outlined' | 'text' | 'tonal';
  color?:
    | 'default'
    | 'subtle'
    | 'accent'
    | 'warning'
    | 'info'
    | 'success'
    | 'error'
    | 'dark'
    | 'virtual';
  // TODO adjust XS once it is added to Figma
  size: 'XS' | 'S' | 'M' | 'L';
  leadingIcon?: TIconProp;
  trailingIcon?: TIconProp;
  disabled?: boolean;
  boldLabel?: boolean;
  onClick?(event: MouseEvent<HTMLDivElement>): void;
  className?: string;
}

/**
 * Use 'Chip' to display a label with two optional icons in four different colors & variants.
 */
export const Chip = forwardRef<HTMLDivElement, IChipProps>(
  (
    {
      label,
      variant,
      color = 'default',
      size,
      leadingIcon,
      trailingIcon,
      disabled = false,
      boldLabel,
      className,
      onClick,
      ...props
    },
    ref
  ) => (
    <Container
      className={className}
      tabIndex={!disabled && onClick ? 0 : undefined}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      compactPaddingLeft={leadingIcon !== undefined}
      compactPaddingRight={trailingIcon !== undefined}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {leadingIcon && (
        <IconSlot source={leadingIcon} size={size === 'XS' ? 'S' : size} />
      )}
      <Content
        variant={size === 'L' ? 'M' : 'S'}
        textAlign="center"
        bold={boldLabel}
      >
        {label}
        {trailingIcon && (
          <IconSlot source={trailingIcon} size={size === 'XS' ? 'S' : size} />
        )}
      </Content>
    </Container>
  )
);
