"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractEventListener = void 0;
var technical_1 = require("../technical");
var provider_1 = require("./provider");
var ContractEventListener = /** @class */ (function () {
    function ContractEventListener(blockchain, contractName, eventName, contract, loadState, saveState, Logger, blockOffset) {
        if (blockOffset === void 0) { blockOffset = ContractEventListener.BLOCK_OFFSET; }
        this.listening = false;
        this.blockchain = blockchain;
        this.web3 = (0, provider_1.get)(this.blockchain).web3;
        this.contractName = contractName;
        this.eventName = eventName;
        this.contract = contract;
        this.loadState = loadState;
        this.saveState = saveState;
        this.Logger = Logger;
        this.blockOffset = blockOffset;
    }
    ContractEventListener.prototype.listenEvents = function (onEvent, options) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.listening = true;
                return [2 /*return*/, (0, technical_1.timeoutLoop)({
                        jobTitle: "[EventListener] ".concat(this.contractName, ".").concat(this.eventName, ".").concat(this.blockOffset),
                        job: function () { return _this.fetchAndDispatch(onEvent, options); },
                        intervalMs: ContractEventListener.INTERVAL_MS,
                        timeoutMs: ContractEventListener.TIMEOUT_MS,
                        onStop: function () {
                            if (_this.listening) {
                                _this.listening = false;
                            }
                        },
                        Logger: this.Logger,
                    })];
            });
        });
    };
    ContractEventListener.prototype.fetchAndDispatch = function (onEvent, options) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.Logger.debug("[EventListener] ".concat(this.blockchain, ".").concat(this.contractName, ".").concat(this.eventName, ".").concat(this.blockOffset, " fetchAndDispatch"));
                        return [4 /*yield*/, this.forEachLatestEvents(onEvent, options)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this.Logger.warn(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ContractEventListener.prototype.forEachLatestEvents = function (onEvent, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var state, toBlock, fromBlock, index, getPastEventsOptions, events, _i, events_1, e;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.loadState(this.blockchain, this.contractName, this.eventName)];
                    case 1:
                        state = _b.sent();
                        return [4 /*yield*/, this.web3.eth.getBlockNumber()];
                    case 2:
                        toBlock = (_b.sent()) - this.blockOffset;
                        fromBlock = (_a = state === null || state === void 0 ? void 0 : state.lastFetchedBlock) !== null && _a !== void 0 ? _a : toBlock - ContractEventListener.MAX_BLOCKS_DIFF;
                        index = 0;
                        _b.label = 3;
                    case 3:
                        if (!(index <
                            Math.ceil((1 + toBlock - fromBlock) / ContractEventListener.MAX_BLOCKS_DIFF))) return [3 /*break*/, 11];
                        if (!this.listening) {
                            return [3 /*break*/, 11];
                        }
                        getPastEventsOptions = __assign({ fromBlock: fromBlock + index * ContractEventListener.MAX_BLOCKS_DIFF, toBlock: Math.min(toBlock, fromBlock + (index + 1) * ContractEventListener.MAX_BLOCKS_DIFF - 1) }, options);
                        this.Logger.debug("[EventListener] ".concat(this.blockchain, ".").concat(this.contractName, ".").concat(this.eventName, ".").concat(this.blockOffset, " getPastEvents: Fetching events in blocks"), getPastEventsOptions);
                        return [4 /*yield*/, this.contract.getPastEvents(this.eventName, getPastEventsOptions)];
                    case 4:
                        events = _b.sent();
                        this.Logger.debug("[EventListener] ".concat(this.blockchain, ".").concat(this.contractName, ".").concat(this.eventName, ".").concat(this.blockOffset, " getPastEvents: Received ").concat(events.length, " event(s)"));
                        if (!this.listening) return [3 /*break*/, 8];
                        _i = 0, events_1 = events;
                        _b.label = 5;
                    case 5:
                        if (!(_i < events_1.length)) return [3 /*break*/, 8];
                        e = events_1[_i];
                        return [4 /*yield*/, onEvent(e)];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7:
                        _i++;
                        return [3 /*break*/, 5];
                    case 8: return [4 /*yield*/, this.saveState(this.blockchain, this.contractName, this.eventName, {
                            lastFetchedBlock: toBlock,
                        })];
                    case 9:
                        _b.sent();
                        _b.label = 10;
                    case 10:
                        index++;
                        return [3 /*break*/, 3];
                    case 11: return [2 /*return*/];
                }
            });
        });
    };
    ContractEventListener.MAX_BLOCKS_DIFF = 2000;
    ContractEventListener.BLOCK_OFFSET = 5;
    ContractEventListener.INTERVAL_MS = 30000;
    ContractEventListener.TIMEOUT_MS = 60000;
    return ContractEventListener;
}());
exports.ContractEventListener = ContractEventListener;
