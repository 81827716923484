import React, { PropsWithChildren } from 'react';

import { AthleteScoreComputationsProvider } from './athleteScoreComputations';
import { AuctionsStore } from './auctions';
import { Provider as NftsProvider } from './cards';
import { CompetitionsProvider } from './competitions';
import { GamePlayersProvider } from './gamePlayers';
import { LeaguesStore } from './leagues';
import { MatchesStore } from './matches';
import { MinimalAthletesProvider } from './minimalAthletes';
import { ModifierMatricesProvider } from './modifierMatrices';
import { NftMarketplaceSalesProvider } from './nftMarketplaceSales';
import { PacksProvider } from './packs';
import { PrizesProvider } from './prize';
import { TokenChangeProvider } from './tokenChange';
import { UsersProvider } from './users';

export const RootProvider = ({ children }: PropsWithChildren<{}>) => (
  <UsersProvider>
    <ModifierMatricesProvider>
      <CompetitionsProvider>
        <MinimalAthletesProvider>
          <MatchesStore>
            <NftsProvider>
              <PacksProvider>
                <PrizesProvider>
                  <LeaguesStore>
                    <GamePlayersProvider>
                      <TokenChangeProvider>
                        <AuctionsStore>
                          <NftMarketplaceSalesProvider>
                            <AthleteScoreComputationsProvider>
                              {children}
                            </AthleteScoreComputationsProvider>
                          </NftMarketplaceSalesProvider>
                        </AuctionsStore>
                      </TokenChangeProvider>
                    </GamePlayersProvider>
                  </LeaguesStore>
                </PrizesProvider>
              </PacksProvider>
            </NftsProvider>
          </MatchesStore>
        </MinimalAthletesProvider>
      </CompetitionsProvider>
    </ModifierMatricesProvider>
  </UsersProvider>
);
