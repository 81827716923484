/**
 * Symbol that represents the loading state for lazy data
 */
export const LOADING = Symbol('Loading');

export type TLoadingState = typeof LOADING;

/**
 * Value that will be will be resolved after being loaded.
 */
export type TLazyData<Data> = Data | TLoadingState;
