import React from 'react';

import { BIconProps, SVGIcon } from '../Icon';
import { ASSETS } from './assets';

/* Main Component */

type KBrandicon =
  | 'Apple'
  | 'Discord'
  | 'Facebook'
  | 'Google'
  | 'Instagram'
  | 'LinkedIn'
  | 'Messenger'
  | 'Metamask'
  | 'Reddit'
  | 'TikTok'
  | 'Twitch'
  | 'Twitter'
  | 'Whatsapp';
type KBrandVariant = 'mono' | 'colored';

export interface IBrandIconProps extends BIconProps {
  brand: KBrandicon;
  variant: KBrandVariant;
}

export function BrandIcon({ brand, variant, ...baseProps }: IBrandIconProps) {
  const asset = ASSETS[brand][variant];
  return <SVGIcon source={asset} {...baseProps} />;
}
