import { Blockchain } from 'ultimate-league-common';

import type { IRaritySvg } from '../../RarityIcon';
import Common from './Common.svg';
import Epic from './Epic.svg';
import Mythic from './Mythic.svg';
import Unique from './Unique.svg';

export const ASSETS_UC: IRaritySvg = {
  [Blockchain.NFTCard.RarityLevel.FUNGIBLE]: Common,
  [Blockchain.NFTCard.RarityLevel.RARITY_4]: null,
  [Blockchain.NFTCard.RarityLevel.RARITY_3]: Epic,
  [Blockchain.NFTCard.RarityLevel.RARITY_2]: Mythic,
  [Blockchain.NFTCard.RarityLevel.RARITY_1]: Unique,
};
