import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializePerformance } from 'firebase/performance';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { initValidators } from 'ultimate-league-common';

import { setCohortIds, setUTMParams } from '#common/referral';
import { SportProvider } from '#common/sport';
import * as config from '#technical/firebase/config';

import { Root } from './Root';
import { Application } from './app/Application';

const app = initializeApp(config);
initializePerformance(app, {
  dataCollectionEnabled: true,
  instrumentationEnabled: true,
});

initValidators(true);
getAuth().useDeviceLanguage();
setUTMParams();
setCohortIds();

if (process.env.NODE_ENV === 'production') {
  hotjar.initialize(3233791, 6);
}

ReactDOM.render(
  <Root>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
    </Helmet>

    <Router>
      <SportProvider>
        <Application />
      </SportProvider>
    </Router>
  </Root>,
  document.getElementById('root')
);
