"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAthletes = void 0;
var pagination_1 = require("../../pagination");
var sport_1 = require("../sport");
exports.getAthletes = __assign(__assign({}, pagination_1.pagination), { q: {
        type: 'string',
        length: {
            minimum: 3,
            maximum: 30,
        },
    }, updatedAfter: {
        datetime: true,
    }, updatedBefore: {
        datetime: true,
    }, sport: sport_1.SportParameter });
