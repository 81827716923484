import { useCallback, useMemo } from 'react';
import { GameWeek, SoccerData } from 'ultimate-league-common';

import { IStore, createStore } from '#common/store';

function getGameWeekUniqueId(gameWeek: GameWeek.IGameWeek) {
  return `${gameWeek.sport}_${gameWeek.position}`;
}

const [, GameWeeksStore, _useGameWeeksStore] = createStore<GameWeek.IGameWeek>(
  getGameWeekUniqueId,
  (itemA, itemB) => getGameWeekUniqueId(itemA) !== getGameWeekUniqueId(itemB)
);

export { GameWeeksStore };

export const useGameWeeksStore = (
  sport: SoccerData.Sport | undefined
): IStore<GameWeek.IGameWeek> => {
  const {
    items: gwItems,
    remove: gwRemove,
    upsert,
    upserts,
  } = _useGameWeeksStore();

  const items = useMemo(
    () => gwItems.filter(({ sport: gwSport }) => gwSport === sport),
    [gwItems, sport]
  );
  const pool = items.reduce(
    (acc, item) => ({
      ...acc,
      [item.position]: item,
    }),
    {}
  );

  const remove = useCallback<IStore<GameWeek.IGameWeek>['remove']>(
    (id) => gwRemove(`${sport}_${id}`),
    [gwRemove, sport]
  );

  return {
    pool,
    items,
    remove,
    upsert,
    upserts,
  };
};
