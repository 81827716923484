"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c, _d, _e, _f, _g;
Object.defineProperty(exports, "__esModule", { value: true });
exports.discordConnect = exports.getObjectiveReward = exports.claimObjectiveReward = exports.ftueRegister = exports.ftueDraftSuggestion = exports.getFTUECards = exports.FTUE_MAX_PRICE = exports.FTUE_MIN_PRICE = exports.FTUE_CARDS_MAX_LIMIT = exports.updateProfilePicture = exports.updateFavoriteTeam = exports.updateUsername = void 0;
var blockchain_1 = require("../blockchain");
var NFT_card_1 = require("../blockchain/NFT-card");
var ftue_1 = require("../ftue");
var localization_1 = require("../localization");
var pagination_1 = require("../pagination");
var soccer_data_1 = require("../soccer-data");
var athlete_1 = require("../soccer-data/athlete");
var technical_1 = require("../technical");
exports.updateUsername = {
    username: {
        presence: {
            allowEmpty: false,
        },
        format: {
            pattern: '^[a-zA-Z0-9_-]*$',
            message: (0, localization_1.t)('USERNAME_CONTAINING_ERROR'),
        },
        length: {
            maximum: 32,
            minimum: 3,
            tooShort: (0, localization_1.t)('USERNAME_TOO_SHORT_ERROR'),
            tooLong: (0, localization_1.t)('USERNAME_TOO_LONG_ERROR'),
        },
    },
};
exports.updateFavoriteTeam = {
    sport: soccer_data_1.SportParameter,
    favoriteTeam: {
        format: technical_1.id.format,
    },
};
exports.updateProfilePicture = {
    profilePicture: {
        format: technical_1.id.format,
    },
};
exports.FTUE_CARDS_MAX_LIMIT = 24;
// Careful, those values are used to compute FTUE prices
exports.FTUE_MIN_PRICE = (_a = {},
    _a[soccer_data_1.Sport.SOCCER] = 2,
    _a[soccer_data_1.Sport.BASKETBALL] = 10,
    _a);
exports.FTUE_MAX_PRICE = (_b = {},
    _b[soccer_data_1.Sport.SOCCER] = 18,
    _b[soccer_data_1.Sport.BASKETBALL] = 500,
    _b);
exports.getFTUECards = {
    offset: pagination_1.pagination.offset,
    limit: {
        numericality: __assign(__assign({}, pagination_1.pagination.limit.numericality), { lessThanOrEqualTo: exports.FTUE_CARDS_MAX_LIMIT }),
    },
    sort: {
        inclusion: (0, technical_1.enumToArray)(blockchain_1.NFTCard.NftsSort),
    },
    athlete: {
        array: (_c = {},
            _c[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _c.format = technical_1.id.format,
            _c),
    },
    'price.$gte': {
        numericality: {
            greaterThanOrEqualTo: 0,
        },
    },
    'price.$lte': {
        numericality: {
            greaterThanOrEqualTo: 0,
        },
    },
    'age.$gte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    'age.$lte': {
        numericality: {
            greaterThanOrEqualTo: NFT_card_1.MIN_AGE,
            lessThanOrEqualTo: NFT_card_1.MAX_AGE,
        },
    },
    position: {
        array: (_d = {},
            _d[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _d.inclusion = athlete_1.ALL_POSITIONS,
            _d),
    },
    country: {
        array: (_e = {},
            _e[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _e.format = technical_1.id.format,
            _e),
    },
    club: {
        array: (_f = {},
            _f[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _f.format = technical_1.id.format,
            _f),
    },
    competition: {
        array: (_g = {},
            _g[technical_1.ARRAY_OPTIONS] = {
                single: true,
            },
            _g.format = technical_1.id.format,
            _g),
    },
    sport: soccer_data_1.SportParameter,
};
exports.ftueDraftSuggestion = {
    sport: soccer_data_1.SportParameter,
};
exports.ftueRegister = {
    sport: soccer_data_1.SportParameter,
    cards: {
        presence: {
            allowEmpty: false,
        },
    },
};
exports.claimObjectiveReward = {
    sport: soccer_data_1.SportParameter,
    ftueObjective: {
        inclusion: (0, technical_1.enumToArray)(ftue_1.Objectives),
        presence: {
            allowEmpty: false,
        },
    },
};
exports.getObjectiveReward = {
    sport: soccer_data_1.SportParameter,
    ftueObjective: {
        inclusion: (0, technical_1.enumToArray)(ftue_1.Objectives),
        presence: {
            allowEmpty: false,
        },
    },
};
exports.discordConnect = {
    code: {
        type: 'string',
        presence: {
            allowEmpty: false,
        },
    },
};
