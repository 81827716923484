import { lazy } from 'react';

export const Athlete = lazy(() =>
  import('./AthletePage').then((module) => ({ default: module.AthletePage }))
);

export * from './AthleteProfile/useAthleteProfile';
export const AthleteProfilePopup = lazy(() =>
  import('./AthleteProfilePopup').then((module) => ({
    default: module.AthleteProfilePopup,
  }))
);
