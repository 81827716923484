import META from './meta.json';
import { KIconName } from './types';

export const ICONS = Object.values(META.icons).reduce<KIconName[]>(
  (prev, group) => [
    ...prev,
    ...Object.keys(group).filter((key): key is KIconName => true),
  ],
  []
);

export const ICON_GROUPS = Object.entries(META.icons).map<{
  group: string;
  icons: KIconName[];
}>(([group, members]) => ({
  group,
  icons: Object.keys(members).filter((key): key is KIconName => true),
}));

export const ICON_STYLES = META.styles;
export const ICON_SIZES = META.sizes;
