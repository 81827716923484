import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SoccerData } from 'ultimate-league-common';

import { SportParams } from '#common/routing';
import { useCurrentSport } from '#common/sport';
import { derivate } from '#technical/hooks';

/**
 * Make sure to apply the correct theme based on the url
 * This hook should be used within routes that need a sport prefix in the url
 */
export const useSportRoute = () => {
  const { sport: plainSportParam } = useParams<{ sport: string | undefined }>();

  const sportParam = derivate(() => {
    if (plainSportParam === undefined) return undefined;

    return Object.entries(SportParams).reduce<SoccerData.Sport | undefined>(
      (prev, [key, value]) => {
        if (prev) return prev;
        if (value !== plainSportParam) return undefined;
        return key as SoccerData.Sport;
      },
      undefined
    );
  });

  const { sport, switchSport } = useCurrentSport();

  useEffect(() => {
    if (sportParam === undefined) return;

    switchSport(sportParam);
  }, [sportParam, switchSport]);

  return { sport };
};
