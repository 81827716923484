import React, { isValidElement } from 'react';
import styled from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { IColorPalettes } from '#ui/theme';

import { IconSlot, TIconProp } from '../../icons';
import { Button, IButtonProps } from '../../inputs';
import { Paragraph } from '../../typography';

/* Styled Components */

const Container = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  gap: ${({ theme }) => theme.spacing(16)};
`;

const SubtleIcon = styled(IconSlot)`
  color: ${({ theme }) => theme.color.primary['40']};
`;

const Title = styled(Paragraph).attrs({
  variant: 'M',
  textAlign: 'center',
  bold: true,
})`
  padding-bottom: ${({ theme }) => theme.spacing(8)};

  &:empty {
    display: none;
  }
`;

const TextContent = styled.div`
  display: grid;
  justify-items: center;
`;

const Actions = styled.div`
  display: grid;
  justify-items: center;
  gap: ${({ theme }) => theme.spacing(8)};

  &:empty {
    display: none;
  }
`;

/* Main Component */

type IMessageCTA = Pick<
  IButtonProps,
  'label' | 'leadingAdornment' | 'trailingAdornment'
> & { onClick(): void };

export interface IMessageProps {
  icon: TIconProp;
  title?: string;
  description?: string;
  primaryAction?: IMessageCTA;
  secondaryAction?: IMessageCTA;
  className?: string;
}

const textColor = Brand.switchBrand({
  UC:
    () =>
    ({ primary }: IColorPalettes) =>
      primary['10'],
  LFP:
    () =>
    ({ primary }: IColorPalettes) =>
      primary['0'],
});

export function Message({
  icon,
  title,
  description,
  primaryAction,
  secondaryAction,
  className,
}: IMessageProps) {
  return (
    <Container className={className}>
      {isValidElement(icon) ? icon : <SubtleIcon source={icon} size="XL" />}
      <TextContent>
        <Title $textColor={textColor}>{title}</Title>
        {description && (
          <Paragraph
            variant="S"
            multiline
            textAlign="center"
            $textColor={textColor}
          >
            {description}
          </Paragraph>
        )}
      </TextContent>
      <Actions>
        {primaryAction && <Button {...primaryAction} variant="filled" />}
        {secondaryAction && <Button {...secondaryAction} variant="text" />}
      </Actions>
    </Container>
  );
}
