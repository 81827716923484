"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftsDrawerType = exports.NftsSort = void 0;
var NftsSort;
(function (NftsSort) {
    NftsSort["RECOMMENDED_ASC"] = "RECOMMENDED_ASC";
    NftsSort["RECOMMENDED_DESC"] = "RECOMMENDED_DESC";
    NftsSort["LAST_UPDATE_ASC"] = "LAST_UPDATE_ASC";
    NftsSort["LAST_UPDATE_DESC"] = "LAST_UPDATE_DESC";
    NftsSort["PRICE_ASC"] = "PRICE_ASC";
    NftsSort["PRICE_DESC"] = "PRICE_DESC";
    NftsSort["SCORE_ASC"] = "SCORE_ASC";
    NftsSort["SCORE_DESC"] = "SCORE_DESC";
    NftsSort["SCORE_RARITY_ASC"] = "SCORE_RARITY_ASC";
    NftsSort["SCORE_RARITY_DESC"] = "SCORE_RARITY_DESC";
    NftsSort["RARITY_ASC"] = "RARITY_ASC";
    NftsSort["RARITY_DESC"] = "RARITY_DESC";
    NftsSort["CONDITION_ASC"] = "CONDITION_ASC";
    NftsSort["CONDITION_DESC"] = "CONDITION_DESC";
    NftsSort["BEST_VALUE_ASC"] = "BEST_VALUE_ASC";
    NftsSort["BEST_VALUE_DESC"] = "BEST_VALUE_DESC";
    NftsSort["BOOST_CARDS_DEFAULT"] = "BOOST_CARDS_DEFAULT";
    NftsSort["BURN_CARDS_DEFAULT"] = "BURN_CARDS_DEFAULT";
})(NftsSort || (exports.NftsSort = NftsSort = {}));
var NftsDrawerType;
(function (NftsDrawerType) {
    NftsDrawerType["BOOST"] = "boost";
    NftsDrawerType["BURN"] = "burn";
})(NftsDrawerType || (exports.NftsDrawerType = NftsDrawerType = {}));
