"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbnailSizes = exports.RarityScore = exports.RarityLevel = void 0;
var brand_1 = require("../../brand");
var RarityLevel;
(function (RarityLevel) {
    /**
     * Common
     */
    RarityLevel[RarityLevel["FUNGIBLE"] = 0] = "FUNGIBLE";
    /**
     * Rare
     */
    RarityLevel[RarityLevel["RARITY_4"] = 40] = "RARITY_4";
    /**
     * Epic
     */
    RarityLevel[RarityLevel["RARITY_3"] = 60] = "RARITY_3";
    /**
     * Mythic
     */
    RarityLevel[RarityLevel["RARITY_2"] = 80] = "RARITY_2";
    /**
     * Unique
     */
    RarityLevel[RarityLevel["RARITY_1"] = 100] = "RARITY_1";
})(RarityLevel || (exports.RarityLevel = RarityLevel = {}));
exports.RarityScore = (0, brand_1.switchBrand)({
    UC: (_a = {},
        _a[RarityLevel.RARITY_1] = 3000,
        _a[RarityLevel.RARITY_2] = 100,
        _a[RarityLevel.RARITY_3] = 10,
        _a[RarityLevel.RARITY_4] = 0,
        _a[RarityLevel.FUNGIBLE] = 0,
        _a),
    LFP: (_b = {},
        _b[RarityLevel.RARITY_1] = 500,
        _b[RarityLevel.RARITY_2] = 50,
        _b[RarityLevel.RARITY_3] = 10,
        _b[RarityLevel.RARITY_4] = 1,
        _b[RarityLevel.FUNGIBLE] = 0,
        _b),
});
exports.ThumbnailSizes = [
    {
        width: 80,
        sizes: [80, 160],
    },
    {
        width: 120,
        sizes: [120, 240],
    },
    {
        width: 160,
        sizes: [160, 320],
    },
    {
        width: 240,
        sizes: [240, 320],
    },
    {
        width: 320,
        sizes: [320],
    },
];
