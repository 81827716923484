import React, { cloneElement, forwardRef } from 'react';
import styled from 'styled-components';

import { typographyStyles } from '../../typography/common';
import { fontIconClasses } from './IconFont';
import { ICON_SIZES } from './data';
import {
  CustomIcon,
  CustomIconSource,
  KFontIcon,
  KIconSize,
  KIconStyle,
} from './types';

export type BIconProps = {
  size?: KIconSize;
  className?: string;
};

export type TIconProp = KFontIcon | CustomIcon;

/* Main Component */

export interface IIconProps extends BIconProps {
  name: KFontIcon;
}

const FontIcon = styled.span`
  ${typographyStyles({})}
`;

/**
 * Use `Icon` to display a generic icon.
 *
 * The icon will be rendered using an icon font (Material Icons)
 */
export const Icon = forwardRef<HTMLSpanElement, IIconProps>(
  ({ name, size = 'M', className, ...props }, ref) => {
    const [iconName, style] = name.split('__') as [KFontIcon, KIconStyle];

    const classes = [
      fontIconClasses.base,
      fontIconClasses.size(size),
      fontIconClasses.style(style),
      className,
    ]
      .filter(Boolean)
      .join(' ');
    return (
      <FontIcon className={classes} ref={ref} {...props}>
        {iconName}
      </FontIcon>
    );
  }
);

export const getIconAttrs = ({
  size = 'M',
  ...props
}: BIconProps): { width: number; height: number; className?: string } => ({
  width: ICON_SIZES[size],
  height: ICON_SIZES[size],
  ...props,
});
export interface ISVGIconProps extends BIconProps {
  source: CustomIconSource;
}
/**
 * Use `SVGIcon` to display a custom icon based on an SVG.
 */
export const SVGIcon = forwardRef<CustomIconSource, ISVGIconProps>(
  ({ source: SVG, ...props }, ref) => (
    <SVG {...getIconAttrs(props)} ref={ref as any} />
  )
);

export interface IImageIconProps extends BIconProps {
  src: string;
  alt: string;
}
/**
 * Use `ImageIcon` to display a custom icon based on an image.
 */
export const ImageIcon = forwardRef<CustomIconSource, IImageIconProps>(
  ({ src, alt, ...props }, ref) => (
    <img alt={alt} src={src} {...getIconAttrs(props)} ref={ref as any} />
  )
);

export interface IIconSlotProps extends BIconProps {
  source: TIconProp;
}
/**
 * Use `IconSlot` to put an generic or cusotm icon into another component and let it overwrite the base props of the icon
 */
export function IconSlot({ source, ...baseProps }: IIconSlotProps) {
  if (typeof source === 'string') {
    return <Icon name={source} {...baseProps} />;
  }

  return cloneElement(source, { ...baseProps, ...source.props });
}

export { ICON_SIZES };
