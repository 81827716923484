"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCard = exports.CardBrand = exports.Type = exports.Method = exports.CurrencySigns = exports.ActiveCurrency = void 0;
var brand_1 = require("../brand");
exports.ActiveCurrency = (0, brand_1.switchBrand)({
    UC: 'USD',
    LFP: 'EUR',
});
exports.CurrencySigns = {
    EUR: '€',
    USD: '$',
};
var Method;
(function (Method) {
    Method["FIAT"] = "fiat";
    Method["ULC_TOKEN"] = "ulc-token";
    Method["UNA_TOKEN"] = "una-token";
    Method["NFCHAMP_TOKEN"] = "nfchamp-token";
})(Method || (exports.Method = Method = {}));
var Type;
(function (Type) {
    Type["CARD"] = "card";
    Type["OTHER"] = "other";
})(Type || (exports.Type = Type = {}));
var CardBrand;
(function (CardBrand) {
    CardBrand["MASTERCARD"] = "mastercard";
    CardBrand["AMEX"] = "amex";
    CardBrand["DISCOVER"] = "discover";
    CardBrand["DINERSCLUB"] = "diners";
    CardBrand["JCB"] = "jcb";
    CardBrand["VISA"] = "visa";
})(CardBrand || (exports.CardBrand = CardBrand = {}));
function isCard(payment) {
    return payment.type === Type.CARD;
}
exports.isCard = isCard;
