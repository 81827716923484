import styled, { css } from 'styled-components';

import { KBreakpoint } from '../../../theme';

/* Main Component */

export type TSpacerSize =
  | 4
  | 8
  | 12
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 68
  | 72
  | 76
  | 80
  | 88
  | 128;

export interface ISpacerProps {
  size: TSpacerSize;
  responsive?: Partial<Record<KBreakpoint, TSpacerSize>>;
}

export const Spacer = styled.div<ISpacerProps>`
  width: 100%;
  height: ${({ size }) => size}px;
  transition: height 300ms ease-in-out;

  ${({ responsive, theme }) =>
    responsive &&
    Object.entries(responsive).map(
      ([breakpoint, size]) => css`
        ${theme.breakpoints.up(breakpoint as KBreakpoint)} {
          height: ${size}px;
        }
      `
    )}
`;
