import BN from 'bn.js';
import { Blockchain, Wallet } from 'ultimate-league-common';
import { toWei, unitMap, Units } from 'web3-utils';

import { buildQuery, fetchApi } from '#technical/network/api';
import { IAuthorization } from '#technical/network/authorization';

export function weiToFloat(weiOrWeiBn: string | BN, fractionDigits: number) {
  const power = 10 ** fractionDigits;
  const weiBn =
    typeof weiOrWeiBn === 'string' ? new BN(weiOrWeiBn) : weiOrWeiBn;
  const safeWei = weiBn
    .mul(new BN(power))
    .div(new BN((unitMap as unknown as Units).ether));
  const decimalPart = safeWei.mod(new BN(power));
  const integerPart = safeWei.div(new BN(power));
  return integerPart.toNumber() + decimalPart.toNumber() / power;
}

export function toFixed(str: string, fractionDigits: number) {
  const [integerPart, decimalPart] = str.split('.');
  if (fractionDigits === 0) {
    return String(integerPart);
  }
  return `${integerPart}.${(
    decimalPart?.substring(0, fractionDigits) ?? ''
  ).padEnd(fractionDigits, '0')}`;
}

export async function fetchAPIUnaChange({
  safeRange,
}: { safeRange?: boolean } = {}): Promise<
  Blockchain.Token.IGETUnaChange['response']
> {
  const res = await fetchApi(`/token/una-change?${buildQuery({ safeRange })}`);
  return res.json();
}

async function fetchUlcBalanceCent(
  auth: IAuthorization
): Promise<Wallet.IGetULCBalance['response']> {
  const res = await fetchApi('/wallet/ulcBalance', undefined, auth);
  return res.json();
}

export async function fetchUlcBalance(
  auth: IAuthorization
): Promise<Blockchain.BigNumber> {
  const { ulcBalanceCent } = await fetchUlcBalanceCent(auth);
  return toWei((ulcBalanceCent / 100).toString());
}
