"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SportParameter = exports.Sports = exports.Sport = void 0;
var technical_1 = require("../technical");
var Sport;
(function (Sport) {
    Sport["SOCCER"] = "SOCCER";
    Sport["BASKETBALL"] = "BASKETBALL";
})(Sport || (exports.Sport = Sport = {}));
exports.Sports = (0, technical_1.enumToArray)(Sport);
exports.SportParameter = {
    inclusion: exports.Sports,
    presence: {
        allowEmpty: false,
    },
};
