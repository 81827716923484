import { Brand, SoccerData } from 'ultimate-league-common';

export const SportParams = Brand.switchBrand({
  UC: {
    [SoccerData.Sport.SOCCER]: 'football',
    [SoccerData.Sport.BASKETBALL]: 'basketball',
  },
  LFP: {
    [SoccerData.Sport.SOCCER]: 'f',
  },
});

const Sports = Brand.switchBrand<'football|basketball' | 'f'>({
  UC: 'football|basketball',
  LFP: 'f',
});

export const ROUTES = {
  LANDING: '/',
  LOGIN: `/sign/:sport(${Sports})`,
  REGISTER: `/signup/:sport(${Sports})`,
  EMAIL_VERIFICATION: '/email-verification',

  FTUE: '/welcome',

  GAME: `/:sport(${Sports})`,

  LEAGUES: `/leagues/:sport(${Sports})`,
  LEAGUE_INVITATION: '/leagues/i/:inviteCode',
  LEAGUE_INVITATION_LEGACY: '/i/:inviteCode',

  TOURNAMENTS: `/tournaments/:sport(${Sports})`,
  TOURNAMENT: `/tournaments/:sport(${Sports})/:gameViewId/:divisionId`,

  AUCTION: `/auction/:auctionId`,
  AUCTIONS: `/auctions/:sport(${Sports})`,

  MARKETPLACE: `/marketplace/:sport(${Sports})`,

  COLLECTION: `/collection/:sport(${Sports})/:userId?`,

  ATHLETE: '/athlete/:athleteId',
  FUNGIBLE_CARD: '/card/:cardId',
  NFT_CARD: '/nft/:nftId',

  PACK_STORE: `/packs/:sport(${Sports})`,
  PACK_DETAILS: '/pack/:packConfigId',

  WALLET: '/wallet',
  FUNDS: '/funds',
  REWARDS: '/rewards',
  ACCOUNT: '/account',
  REFERRAL_PROGRAM: `/referral-program/:sport(${Sports})`,
  REFERRAL: '/r/:referralId',

  BUY_CHAMP: '/buy-champ',

  CONTACT: '/contact',
  TERMS: '/terms',
  PRIVACY: '/privacy',
} as const;
