"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpirationTime = exports.ContextType = exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["FTUE"] = "FTUE";
    EventType["REFERRAL_INVITER"] = "REFERRAL_INVITER";
    EventType["REFERRAL_INVITEE"] = "REFERRAL_INVITEE";
})(EventType || (exports.EventType = EventType = {}));
var ContextType;
(function (ContextType) {
    ContextType["GAMEMODE"] = "GAMEMODE";
    ContextType["EVENT"] = " EVENT";
})(ContextType || (exports.ContextType = ContextType = {}));
exports.ExpirationTime = 30; // days
